import { AsideMenuTopBar } from '@single-platform/components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { LinkPopupContentContainer, LinkPopupHeaderStyles } from '../../linkPopup.style';
import { ParagraphStyles } from './linkPopupDescription.style';

export const LinkPopupDescription = ({ onClose, header, farmers }) => {
  const { t } = useTranslation();
  return (
    <>
      <LinkPopupHeaderStyles>
        <AsideMenuTopBar handleClose={onClose} />
        <div>{t(header)}</div>
      </LinkPopupHeaderStyles>
      <LinkPopupContentContainer>
        {!farmers && (
          <>
            <ParagraphStyles>{t('link_popup_description_example_header')}</ParagraphStyles>
            <ParagraphStyles>{t('link_popup_description_example_subheader')}</ParagraphStyles>
          </>
        )}
        {farmers && (
          <ParagraphStyles>{t('link_popup_description_example_farm_activity')}</ParagraphStyles>
        )}
      </LinkPopupContentContainer>
    </>
  );
};
