export const yesNoChipOptions = [
  { label: 'choices_yesno_yes', value: true },
  { label: 'choices_yesno_no', value: false }
];

export const identityDocumentTypeOptions = [
  { label: 'farmers_farmer_document_type_idCard', value: 'IdentityCard' },
  { label: 'farmers_farmer_document_type_passport', value: 'Passport' }
];

export const paymentAmountOptions = [
  { label: 'farmers_choices_payment_amount_0_to_300k_less_than_50k', value: 'LessThan50k' },
  { label: 'farmers_choices_payment_amount_0_to_300k_from_50k_to_300k', value: 'From50kTo300k' },
  { label: 'farmers_choices_payment_amount_0_to_300k_more_than_300k', value: 'MoreThan300k' }
];

export const numberOfPaymentsOptions = [
  { label: 'farmers_choices_number_of_payments_less_than_100', value: 'LessThan100' },
  { label: 'farmers_choices_number_of_payments_from_101_to_200', value: 'From101To200' },
  { label: 'farmers_choices_number_of_payments_more_than_200', value: 'MoreThan200' }
];
