import React, { useMemo } from 'react';
import { useFarmerFormValidation } from '../../../../../LegalEntityQuestionnaire/validation/useFarmerFormValidation';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import breakpointSizes from '../../../../../LegalEntityQuestionnaire/styled-theme-styles/global-style-variables.style';
import useWindowDimensions from '../../../../../LegalEntityQuestionnaire/Utils/window-size.service';
import { Input, Field, Loader } from '@single-platform/components';
import { FarmersQuestionnaireHeader } from '../../../../farmersQuestionnaire.style';
import {
  FormStyles,
  PageHeader
} from '../../../../../LegalEntityQuestionnaire/screens/renderPage/renderPage.style';
import { ButtonSection } from '../../../../../LegalEntityQuestionnaire/components';
import { useSelector } from 'react-redux';
import countries from '../../../../../../Auth/components/countriesDropdown/farmersCountryList.json';
import { ButtonSectionWrapperStyle } from '../../../formStyles';
import { DropdownInput } from '../../../../components/dropdownInput/dropdownInput';

const defaultValues = {
  country: null,
  city: null,
  address: null,
  postalCode: null
};

export const RepresentativeAddressPage = ({ onSubmit, isAtBottom }) => {
  const { t } = useTranslation();
  const { width } = useWindowDimensions();
  const { loading, farmerInformation } = useSelector((state) => state.FarmersQuestionnaireReducer);

  const validationSchema = useMemo(
    () =>
      Yup.object({
        country: Yup.string().required(t('validation_required_field')).nullable(),
        city: Yup.string().required(t('validation_required_field')).nullable(),
        address: Yup.string().required(t('validation_required_field')).nullable(),
        postalCode: Yup.string().required(t('validation_required_field')).nullable()
      }),
    [t]
  );

  const existingValues = farmerInformation?.farmRepresentativeSection?.representativeAddressPage;
  const initialValues = { ...defaultValues, ...existingValues };

  const { validationHelpers, getValues, setTouchedOnAll, handleSubmit } = useFarmerFormValidation({
    initialValues,
    validationSchema
  });

  const onHandleSubmit = async (e) => {
    e.preventDefault();
    await handleSubmit(
      async (data) => {
        await onSubmit?.(data, 'representativeAddressPage');
      },
      () => setTouchedOnAll()
    )();
  };

  if (loading) return <Loader />;

  const values = getValues();

  return (
    <FormStyles small={width < breakpointSizes.phone}>
      <FarmersQuestionnaireHeader>
        <PageHeader>{t('farmers_representative_address_header')}</PageHeader>
      </FarmersQuestionnaireHeader>
      <DropdownInput
        validationHelpers={validationHelpers}
        name="country"
        value={values.country}
        filterCountries={false}
        placeholder={t('farmers_farmer_country')}
        options={countries}
      />
      <Field
        Component={Input}
        name="city"
        value={values.city}
        placeholder={t('farmers_farmer_city')}
        validationHelpers={validationHelpers}
        required
        validateOnChange
      />
      <Field
        Component={Input}
        name="address"
        value={values.address}
        placeholder={t('farmers_farmer_address')}
        validationHelpers={validationHelpers}
        required
        validateOnChange
      />
      <Field
        Component={Input}
        name="postalCode"
        value={values.postalCode}
        placeholder={t('farmers_farmer_postalCode')}
        validationHelpers={validationHelpers}
        required
        validateOnChange
      />
      <ButtonSectionWrapperStyle isAtBottom={isAtBottom}>
        <ButtonSection
          secondButtonClick={onHandleSubmit}
          secondButtonPrimary={true}
          secondButtonText={t('button_save_and_continue')}
        />
      </ButtonSectionWrapperStyle>
    </FormStyles>
  );
};
