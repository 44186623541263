import { useCallback } from 'react';

export const useLegalLinks = (isBank) => {
  const getLanguage = useCallback(() => {
    return localStorage.getItem('language') || 'en-GB';
  }, []);

  const openTermsAndConditions = useCallback(() => {
    const language = getLanguage();
    if (isBank) {
      language === 'en-GB'
        ? window.open('https://smebank.lt/en/general-agreements/', '_blank', 'noopener')
        : window.open('https://smebank.lt/bendrosios-sutarciu-salygos/', '_blank', 'noopener');
    } else {
      language === 'en-GB'
        ? window.open('https://smego.com/terms-conditions/', '_blank', 'noopener')
        : window.open('https://smego.com/lt/terms-and-conditions/', '_blank', 'noopener');
    }
  }, [isBank, getLanguage]);

  const openPrivacyPolicy = useCallback(() => {
    const language = getLanguage();
    if (isBank) {
      language === 'en-GB'
        ? window.open('https://smebank.lt/en/privacy-policy/', '_blank', 'noopener')
        : window.open('https://smebank.lt/privatumo-politika/', '_blank', 'noopener');
    } else {
      language === 'en-GB'
        ? window.open('https://smego.com/privacy-policy/', '_blank', 'noopener')
        : window.open('https://smego.com/lt/privacy-policy/', '_blank', 'noopener');
    }
  }, [isBank, getLanguage]);

  return {
    openTermsAndConditions,
    openPrivacyPolicy
  };
};
