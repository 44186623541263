import React, { useEffect, useState } from 'react';
import { FormListStyles } from '../../../LegalEntityQuestionnaire/screens/renderRepeatableGroup/renderRepeatableGroup.style';
import { useTranslation } from 'react-i18next';
import { CompaniesDrawer, PartnersDrawer, SuppliersDrawer } from './drawers';
import { AsideMenuTopBar } from '@single-platform/components';
import {
  AsideDrawerHeaderStyles,
  ContentContainer,
  RemoveButtonStyles,
  RepeatableButtonSection
} from '../../../LegalEntityQuestionnaire/components/repeatableDrawer/repeatableDrawer.style';
import { Button } from '@single-platform/button';
import { Arrow, TrashcanLogo } from '../../../../../Assets/images/logos';
import company from '../../../../../Assets/images/logos/company.svg';
import { StyledAside } from './formList.style';

const drawerComponentsMap = {
  companies: CompaniesDrawer,
  suppliers: SuppliersDrawer,
  partners: PartnersDrawer
};

const iconMap = {
  companies: company,
  suppliers: company,
  partners: company
};

const formListButton = {
  companies: 'farmers_farm_clients_add_client_button',
  suppliers: 'farmers_farm_clients_add_supplier_button',
  partners: 'farmers_farm_add_partner_button'
};

export const FormList = ({ name, list, setList, drawerType }) => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [shouldValidate, setShouldValidate] = useState(false);
  const [shownListItem, setShownListItem] = useState(null);
  const [shownListItemIndex, setShownListItemIndex] = useState(null);
  const [drawerAction, setDrawerAction] = useState(null);
  const { t } = useTranslation();
  const DrawerComponent = drawerComponentsMap[drawerType] || null;

  const handleRepeatableCardClick = (index) => {
    setShownListItemIndex(index);
    setShownListItem(list[index]);
    setDrawerAction('Edit');
    setIsDrawerOpen(true);
  };

  const handleExtraActions = (e, index) => {
    e.preventDefault();
    handleRepeatableCardClick(index);
  };

  const addCardHandler = (e) => {
    e.preventDefault();

    setDrawerAction('Add');
    setIsDrawerOpen(true);
  };

  const handleSubmit = () => {
    setShouldValidate(true);
  };

  const deleteItem = async () => {
    const updatedList = [...list];
    updatedList.splice(shownListItemIndex, 1);
    setList(updatedList);

    closeDrawer();
  };

  const onDrawerAdd = (data) => {
    setList([...list, data]);

    closeDrawer();
  };

  const onDrawerEdit = (data) => {
    const newList = [...list];
    newList[shownListItemIndex] = data;
    setList(newList);

    closeDrawer();
  };

  const closeDrawer = () => {
    setShownListItem(null);
    setShownListItemIndex(null);
    setDrawerAction(null);
    setIsDrawerOpen(false);
  };

  const getCardList = () => {
    return list.map((item) => ({
      title: item.name,
      subtitle: item.country,
      icon: iconMap[drawerType],
      completed: item.isValid,
      id: item.id
    }));
  };

  const cardList = getCardList();

  return (
    <div>
      <FormListStyles
        data-hj-suppress
        name={name}
        addButtonLabel={t(formListButton[drawerType])}
        onAddCardClick={(e) => addCardHandler(e)}
        onItemClick={(index) => handleRepeatableCardClick(index)}
        renderExtraActions={(item, index) =>
          item.completed ? (
            <Arrow onClick={() => handleRepeatableCardClick(index)} />
          ) : (
            <Button size="S" onClick={(e) => handleExtraActions(e, index)}>
              {t('repeatable_group.button_review')}
            </Button>
          )
        }
        list={cardList}
      />
      {isDrawerOpen && DrawerComponent && (
        <StyledAside isOpen={isDrawerOpen && DrawerComponent} activeIndex={1}>
          <AsideDrawerHeaderStyles>
            <AsideMenuTopBar handleClose={() => setIsDrawerOpen(false)} />
            <div>
              {drawerAction === 'Edit' ? shownListItem?.name : t('repeatable_group.add_button')}
            </div>
          </AsideDrawerHeaderStyles>
          <ContentContainer>
            <DrawerComponent
              isOpen={isDrawerOpen}
              handleModal={() => setIsDrawerOpen(false)}
              shouldValidate={shouldValidate}
              setShouldValidate={setShouldValidate}
              shownListItem={shownListItem}
              onSubmit={(data) => {
                drawerAction === 'Edit' ? onDrawerEdit(data) : onDrawerAdd(data);
              }}
            />
            {list.length > 1 && drawerAction === 'Edit' && (
              <RemoveButtonStyles alignEnd={'true'} type="submit" onClick={() => deleteItem()}>
                {t('farmers_farm_repeatable_remove_button')}
                <TrashcanLogo />
              </RemoveButtonStyles>
            )}
            <RepeatableButtonSection>
              {drawerAction === 'Edit' ? (
                <Button onClick={(e) => handleSubmit()}>{t('repeatable_group.edit_button')}</Button>
              ) : (
                <Button onClick={(e) => handleSubmit()}>{t('repeatable_group.add_button')}</Button>
              )}
            </RepeatableButtonSection>
          </ContentContainer>
        </StyledAside>
      )}
    </div>
  );
};
