import React, { useEffect } from 'react';
import { Result } from 'antd';
import { useTranslation } from 'react-i18next';
import { Button } from '@single-platform/button';
import { history, routerPath } from '../../../../../Config';
import { useDispatch } from 'react-redux';
import { AuthActions } from '../../../../../Redux';
import { storageHandler } from '../../../../SSOLoginContainer/storageHandler';

export const Error = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const redirectPage = () => {
    history.push(routerPath.index);
  };

  useEffect(() => {
    const token = storageHandler.getSessionStorage('token');
    const company = storageHandler.getSessionStorage('company');

    if (token || company) {
      dispatch(AuthActions.reset());
    }
  }, []);

  return (
    <div className="w-100">
      <Result
        status="500"
        title={t('generic_error_message')}
        extra={
          <Button key="console" onClick={redirectPage}>
            {t('generic_error_button_message')}
          </Button>
        }
      />
    </div>
  );
};
