import React, { useMemo } from 'react';
import { useFarmerFormValidation } from '../../../../../LegalEntityQuestionnaire/validation/useFarmerFormValidation';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import breakpointSizes from '../../../../../LegalEntityQuestionnaire/styled-theme-styles/global-style-variables.style';
import useWindowDimensions from '../../../../../LegalEntityQuestionnaire/Utils/window-size.service';
import { Field, Loader } from '@single-platform/components';
import { FarmersQuestionnaireHeader, PageSubtitle } from '../../../../farmersQuestionnaire.style';
import {
  FormStyles,
  PageHeader
} from '../../../../../LegalEntityQuestionnaire/screens/renderPage/renderPage.style';
import { ButtonSection } from '../../../../../LegalEntityQuestionnaire/components';
import { useSelector } from 'react-redux';
import { ButtonSectionWrapperStyle } from '../../../formStyles';
import { Multiselect } from '../../../../components/multiselect/multiselectWrapper';
import farmCountries from '../../../../../../Auth/components/countriesDropdown/farmersCountryList.json';

const defaultValues = {
  partnerCountries: []
};

export const FarmPartnerCountryPage = ({ onSubmit, isAtBottom }) => {
  const { t } = useTranslation();
  const { width } = useWindowDimensions();
  const { loading, farmerInformation } = useSelector((state) => state.FarmersQuestionnaireReducer);

  const validationSchema = useMemo(
    () =>
      Yup.object({
        partnerCountries: Yup.array().min(1, t('validation_required_field'))
      }),
    [t]
  );

  const existingValues =
    farmerInformation?.farmPaymentsAndTransactionsSection?.farmPartnerCountryPage;
  const initialValues = { ...defaultValues, ...existingValues };

  const { validationHelpers, getValues, setTouchedOnAll, handleSubmit } = useFarmerFormValidation({
    initialValues,
    validationSchema
  });

  const onHandleSubmit = async (e) => {
    e.preventDefault();
    await handleSubmit(
      async (data) => {
        await onSubmit?.(data, 'farmPartnerCountryPage');
      },
      () => setTouchedOnAll()
    )();
  };

  if (loading) return <Loader />;

  const values = getValues();

  return (
    <FormStyles small={width < breakpointSizes.phone}>
      <FarmersQuestionnaireHeader noSubHeader>
        <PageHeader>{t('farmers_partner_country_header')}</PageHeader>
        <PageSubtitle>{t('farmers_partner_country_subtitle')}</PageSubtitle>
      </FarmersQuestionnaireHeader>

      <Field
        Component={Multiselect}
        name="partnerCountries"
        value={values.partnerCountries}
        placeholder={t('farmers_partner_select_countries')}
        validationHelpers={validationHelpers}
        options={farmCountries}
        required
        validateOnChange
        autoHeight={true}
        maxHeight={288}
        validateOnBlur
        maxLength={500}
        counter={true}
      />
      <ButtonSectionWrapperStyle isAtBottom={isAtBottom}>
        <ButtonSection
          secondButtonClick={onHandleSubmit}
          secondButtonPrimary
          secondButtonText={t('button_save_and_continue')}
        />
      </ButtonSectionWrapperStyle>
    </FormStyles>
  );
};
