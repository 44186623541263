import React from 'react';
import * as Yup from 'yup';
import i18next from 'i18next';
import { useSelector } from 'react-redux';
import { RadioChipOption } from '../../../../components';
import { useFarmerFormValidation } from '../../../../../LegalEntityQuestionnaire/validation/useFarmerFormValidation';
import { useTranslation } from 'react-i18next';
import { Field, Input, Loader } from '@single-platform/components';
import { FarmersQuestionnaireHeader, PageSubtitle } from '../../../../farmersQuestionnaire.style';
import useWindowDimensions from '../../../../../LegalEntityQuestionnaire/Utils/window-size.service';
import breakpointSizes from '../../../../../LegalEntityQuestionnaire/styled-theme-styles/global-style-variables.style';
import {
  FormStyles,
  PageHeader
} from '../../../../../LegalEntityQuestionnaire/screens/renderPage/renderPage.style';
import { ButtonSectionWrapperStyle } from '../../../formStyles';
import { ButtonSection } from '../../../../../LegalEntityQuestionnaire/components';
import { yesNoChipOptions } from '../../../../constants';

const defaultValues = {
  doesFarmHaveWebsite: null,
  website: null
};

export const FarmWebsitePage = ({ onSubmit, isAtBottom }) => {
  const { t } = useTranslation();
  const { width } = useWindowDimensions();
  const { loading, farmerInformation } = useSelector((state) => state.FarmersQuestionnaireReducer);

  const validationSchema = Yup.object({
    doesFarmHaveWebsite: Yup.boolean().required(t('validation_required_field')).nullable(),
    website: Yup.string().when('doesFarmHaveWebsite', {
      is: true,
      then: Yup.string().required(t('validation_required_field')).nullable(),
      otherwise: Yup.string().notRequired().nullable()
    })
  });

  const existingValues = farmerInformation?.farmActivitiesSection?.farmWebsitePage;
  const initialValues = { ...defaultValues, ...existingValues };

  const { validationHelpers, getValues, setTouchedOnAll, handleSubmit } = useFarmerFormValidation({
    initialValues,
    validationSchema
  });

  const onHandleSubmit = async (e) => {
    e.preventDefault();
    await handleSubmit(
      async (data) => {
        if (data.doesFarmHaveWebsite === false) {
          data.website = null;
        }

        await onSubmit?.(data, 'farmWebsitePage');
      },
      () => setTouchedOnAll()
    )();
  };

  const values = getValues();

  if (loading) return <Loader />;

  return (
    <FormStyles small={width < breakpointSizes.phone}>
      <FarmersQuestionnaireHeader>
        <PageHeader>{t('farmers_website_page_header')}</PageHeader>
        <PageSubtitle>{t('farmers_website_page_subtitle')}</PageSubtitle>
      </FarmersQuestionnaireHeader>
      <Field
        Component={RadioChipOption}
        options={yesNoChipOptions}
        value={values.doesFarmHaveWebsite}
        name="doesFarmHaveWebsite"
        validationHelpers={validationHelpers}
        validateOnChange
      />
      {values.doesFarmHaveWebsite && (
        <Field
          Component={Input}
          name="website"
          value={values.website}
          placeholder={t('farmers_website_name')}
          validationHelpers={validationHelpers}
          validateOnChange
          required
        />
      )}
      <ButtonSectionWrapperStyle isAtBottom={isAtBottom}>
        <ButtonSection
          secondButtonClick={onHandleSubmit}
          secondButtonPrimary
          secondButtonText={t('button_save_and_continue')}
        />
      </ButtonSectionWrapperStyle>
    </FormStyles>
  );
};
