import React from 'react';
import { ApplicationList } from './components/ApplicationList';
import { Logout } from '../../../Utilities';

export const RegistrationPage = () => {
  const onCancel = async () => {
    sessionStorage.clear();
    await Logout();
  };

  return <ApplicationList onCancel={onCancel} />;
};
