import { Checkbox } from '@single-platform/components';
import styled from 'styled-components';

export const CheckboxStyles = styled(Checkbox)`
  span {
    background-color: ${({ theme }) => theme.colors.base.primary[100]};

    &:hover {
      background-color: ${({ theme }) => theme.colors.base.primary[20]};
    }
  }
  span > svg {
    height: unset;
    width: unset;
  }
`;
