import { KYCConstants } from './constants';
import { checkJsonProp } from '@utilities';
import { STORAGE_ITEMS, LOGIN_TYPES } from '@config';
import { Logout } from '../../../Utilities';

export const getToken = (user) => {
  let access_token = checkJsonProp(user, 'token');
  return 'Bearer ' + access_token;
};

export const KYCReducers = function (state = {}, action) {
  switch (action.type) {
    case KYCConstants.POST_SEND_KYC_APPLICATION_REQUEST:
      return {
        ...state,
        send: true,
        sendKYC: true,
        applicationKYC: null
      };
    case KYCConstants.POST_SEND_KYC_APPLICATION_SUCCESS:
      return {
        ...state,
        send: true,
        sendKYC: false,
        applicationKYC: action.payload
      };
    case KYCConstants.POST_SEND_KYC_APPLICATION_ERROR:
      return {
        ...state,
        send: true,
        sendKYC: false,
        errors: action.error
      };
    case KYCConstants.POST_GET_LIST_KYC_REQUEST:
      return {
        ...state,
        send: true,
        sendKYCList: true,
        KYCList: null
      };
    case KYCConstants.POST_GET_LIST_KYC_SUCCESS:
      return {
        ...state,
        send: false,
        sendKYCList: false,
        KYCList: action.payload
      };
    case KYCConstants.POST_GET_LIST_KYC_ERROR:
      return {
        ...state,
        send: false,
        sendKYCList: false,
        errors: action.error
      };
    case KYCConstants.POST_MODIFY_KYC_APPLICATION_REQUEST:
      return {
        ...state,
        send: true,
        sendKYC: true,
        applicationKYC: null
      };
    case KYCConstants.POST_MODIFY_KYC_APPLICATION_SUCCESS:
      return {
        ...state,
        send: false,
        sendKYC: false,
        applicationKYC: action.payload
      };
    case KYCConstants.POST_MODIFY_KYC_APPLICATION_ERROR:
      return {
        ...state,
        send: false,
        sendKYC: false,
        errors: action.error
      };
    case KYCConstants.POST_APPROVE_KYC_APPLICATION_REQUEST:
      return {
        ...state,
        send: true,
        sendKYCApprove: true
      };
    case KYCConstants.POST_APPROVE_KYC_APPLICATION_SUCCESS:
      return {
        ...state,
        send: false,
        sendKYCApprove: false
      };
    case KYCConstants.POST_APPROVE_KYC_APPLICATION_ERROR:
      return {
        ...state,
        send: false,
        sendKYCApprove: false,
        errors: action.error
      };
    case KYCConstants.POST_DELETE_KYC_APPLICATION_REQUEST:
      return {
        ...state,
        send: true,
        sendKYCDelete: true
      };
    case KYCConstants.POST_DELETE_KYC_APPLICATION_SUCCESS:
      return {
        ...state,
        send: false,
        sendKYCDelete: false
      };
    case KYCConstants.POST_DELETE_KYC_APPLICATION_ERROR:
      return {
        ...state,
        send: false,
        sendKYCDelete: false,
        errors: action.error
      };
    case KYCConstants.CLEAR_KYC_APPLICATION:
      return {
        ...state,
        applicationKYC: null
      };
    case KYCConstants.START_KYC_APPLICATION_REQUEST:
      return {
        ...state,
        send: true,
        sendKYC: true,
        applicationKYC: null,
        applicationCompany: null,
        isApplicationLoading: true
      };
    case KYCConstants.START_KYC_APPLICATION_SUCCESS:
      return {
        ...state,
        send: false,
        sendKYC: false,
        applicationKYC: action.payload,
        applicationCompany: action.payload.company,
        uploadKYCPDF: false,
        isApplicationLoading: false
      };
    case KYCConstants.START_APPLICATION_SIGNED_UPLOAD_REQUEST:
      return {
        ...state,
        isAppSignedUploadLoading: true
      };
    case KYCConstants.START_APPLICATION_SIGNED_UPLOAD_SUCCESS:
      return {
        ...state,
        isAppSignedUploadLoading: false,
        applicationKYC: action.payload,
        timerInfo: action.payload.timerInfo
      };
    case KYCConstants.START_APPLICATION_SIGNED_UPLOAD_ERROR:
      return {
        ...state,
        isAppSignedUploadLoading: false,
        errors: action.error
      };
    case KYCConstants.START_KYC_APPLICATION_ERROR:
      Logout();
      return {
        ...state,
        send: false,
        sendKYC: false,
        applicationCompany: null,
        errors: action.error
      };
    case KYCConstants.UPLOAD_POWER_OF_ATTORNEY_REQUEST:
      return {
        ...state,
        send: true,
        sendKYC: true,
        applicationKYC: null
      };
    case KYCConstants.UPLOAD_POWER_OF_ATTORNEY_SUCCESS:
      return {
        ...state,
        send: false,
        sendKYC: false,
        applicationKYC: action.payload
      };
    case KYCConstants.UPLOAD_POWER_OF_ATTORNEY_ERROR:
      return {
        ...state,
        send: false,
        sendKYC: false,
        errors: action.error
      };
    case KYCConstants.UPLOAD_ORGANIZATIONAL_CHART_REQUEST:
      return {
        ...state,
        send: true,
        sendKYC: true,
        applicationKYC: null
      };
    case KYCConstants.UPLOAD_ORGANIZATIONAL_CHART_SUCCESS:
      return {
        ...state,
        send: false,
        sendKYC: false,
        applicationKYC: action.payload
      };
    case KYCConstants.UPLOAD_ORGANIZATIONAL_CHART_ERROR:
      return {
        ...state,
        send: false,
        sendKYC: false,
        errors: action.error
      };
    case KYCConstants.UPLOAD_ARTICLES_OF_ASSOCIATION_REQUEST:
      return {
        ...state,
        send: true,
        sendKYC: true,
        applicationKYC: null
      };
    case KYCConstants.UPLOAD_ARTICLES_OF_ASSOCIATION_SUCCESS:
      return {
        ...state,
        send: false,
        sendKYC: false,
        applicationKYC: action.payload
      };
    case KYCConstants.UPLOAD_ARTICLES_OF_ASSOCIATION_ERROR:
      return {
        ...state,
        send: false,
        sendKYC: false,
        errors: action.error
      };
    case KYCConstants.UPLOAD_BENEFECIARY_DOCUMENTS_REQUEST:
      return {
        ...state,
        send: true,
        sendKYC: true
      };
    case KYCConstants.UPLOAD_BENEFECIARY_DOCUMENTS_SUCCESS:
      return {
        ...state,
        send: false,
        sendKYC: false,
        applicationKYC: {
          ...state?.applicationKYC,
          applicationState: {
            ...state?.applicationKYC?.applicationState,
            ...action.payload
          }
        }
      };
    case KYCConstants.UPLOAD_BENEFECIARY_DOCUMENTS_ERROR:
      return {
        ...state,
        send: false,
        sendKYC: false,
        errors: action.error
      };
    case KYCConstants.UPLOAD_WOLFSBERG_QUESTIONNAIRE_REQUEST:
      return {
        ...state,
        send: true,
        sendKYC: true,
        applicationKYC: null
      };
    case KYCConstants.UPLOAD_WOLFSBERG_QUESTIONNAIRE_SUCCESS:
      return {
        ...state,
        send: false,
        sendKYC: false,
        applicationKYC: action.payload
      };
    case KYCConstants.UPLOAD_WOLFSBERG_QUESTIONNAIRE_ERROR:
      return {
        ...state,
        send: false,
        sendKYC: false,
        errors: action.error
      };
    case KYCConstants.UPLOAD_SIGNED_APPLICATION_REQUEST:
      return {
        ...state,
        isUploadSignedAppLoading: true
      };
    case KYCConstants.UPLOAD_SIGNED_APPLICATION_SUCCESS:
      return {
        ...state,
        isUploadSignedAppLoading: false,
        applicationKYC: {
          ...state?.applicationKYC,
          applicationState: {
            ...state?.applicationKYC?.applicationState,
            ...action.payload
          }
        }
      };
    case KYCConstants.UPLOAD_SIGNED_APPLICATION_ERROR:
      return {
        ...state,
        isUploadSignedAppLoading: false,
        errors: action.error
      };
    case KYCConstants.UPLOAD_APPLICATION_DOCUMENT_REQUEST:
      return {
        ...state,
        isUploadAppDocsLoading: true
      };
    case KYCConstants.UPLOAD_APPLICATION_DOCUMENT_SUCCESS:
      return {
        ...state,
        isUploadAppDocsLoading: false,
        applicationKYC: {
          ...state.applicationKYC,
          applicationState: {
            ...state.applicationKYC.applicationState,
            ...action.payload
          }
        }
      };
    case KYCConstants.UPLOAD_APPLICATION_DOCUMENT_ERROR:
      return {
        ...state,
        isUploadAppDocsLoading: false,
        errors: action.error
      };
    case KYCConstants.UPLOAD_SIGNED_AGREEMENT_REQUEST:
      return {
        ...state,
        isUploadAgreementLoading: true
      };
    case KYCConstants.UPLOAD_SIGNED_AGREEMENT_SUCCESS:
      return {
        ...state,
        isUploadAgreementLoading: false,
        applicationKYC: {
          ...state.applicationKYC,
          applicationState: {
            ...state.applicationKYC.applicationState,
            ...action.payload
          }
        }
      };
    case KYCConstants.UPLOAD_SIGNED_AGREEMENT_ERROR:
      return {
        ...state,
        isUploadAgreementLoading: false,
        errors: action.error
      };

    case KYCConstants.GET_COMPANY_CLASSIFICATION_REQUEST:
      return {
        ...state,
        isGetClassificationLoading: true
      };
    case KYCConstants.GET_COMPANY_CLASSIFICATION_SUCCESS: {
      return {
        ...state,
        isGetClassificationLoading: false,
        companyClassificationInfo: action.payload
      };
    }
    case KYCConstants.GET_COMPANY_CLASSIFICATION_ERROR:
      return {
        ...state,
        isGetClassificationLoading: false,
        errors: action.error
      };

    case KYCConstants.SUBMIT_APPLICATION_REQUEST:
      return {
        ...state,
        send: true,
        sendKYC: true
      };
    case KYCConstants.SUBMIT_APPLICATION_SUCCESS:
      return {
        ...state,
        send: false,
        sendKYC: false,
        applicationKYC: {
          ...state?.applicationKYC,
          applicationState: {
            // documentId: state?.applicationKYC?.applicationState.documentId,
            ...state?.applicationKYC?.applicationState,
            ...action.payload
          }
        }
      };
    case KYCConstants.SUBMIT_APPLICATION_ERROR:
      return {
        ...state,
        send: false,
        sendKYC: false,
        errors: action.error
      };
    case KYCConstants.START_APPLICATION_SIGNING_REQUEST:
      return {
        ...state,
        send: true,
        sendSigning: true,
        startSigning: false
      };
    case KYCConstants.START_APPLICATION_SIGNING_SUCCESS: {
      if (action.authType !== LOGIN_TYPES.BIOMETRICS) {
        localStorage.setItem(STORAGE_ITEMS.SIGNIN_TOKEN, action.payload.signingData.token);
      }
      return {
        ...state,
        send: false,
        sendSigning: false,
        startSigning: true,
        applicationKYC: {
          ...state?.applicationKYC,
          applicationState: {
            ...state?.applicationKYC?.applicationState,
            ...action.payload,
            state:
              action.authType !== LOGIN_TYPES.BIOMETRICS
                ? action.payload.state
                : state?.applicationKYC?.applicationState?.state
          }
        },
        timerInfo: action.payload?.signingData
      };
    }
    case KYCConstants.START_APPLICATION_SIGNING_ERROR:
      return {
        ...state,
        send: false,
        sendSigning: false,
        startSigning: false,
        errors: action.error
      };
    case KYCConstants.CHECK_SIGNING_SCA_REQUEST:
      return {
        ...state,
        checkSigningSca: true,
        errors: action.error
      };
    case KYCConstants.CHECK_SIGNING_SCA_SUCCESS:
      // }
      return {
        ...state,
        checkSigningSca: false,
        // startSigning: false,
        errors: action.error
      };
    case KYCConstants.CHECK_SIGNING_SCA_ERROR:
      return {
        ...state,
        checkSigningSca: false,
        startSigning: false,
        errors: action.error
      };
    case KYCConstants.CANCEL_SIGNING_REQUEST:
      return {
        ...state,
        cancelSigning: true,
        startSigning: false,
        errors: action.error
      };
    case KYCConstants.CANCEL_SIGNING_SUCCESS:
      return {
        ...state,
        cancelSigning: false,
        errors: action.error
      };
    case KYCConstants.CANCEL_SIGNING_ERROR:
      return {
        ...state,
        cancelSigning: false,
        startSigning: false,
        errors: action.error
      };

    case KYCConstants.GET_INSTITUTION_REQUEST:
      return {
        ...state,
        isLoadingInstitutions: true,
        errors: action.error
      };
    case KYCConstants.GET_INSTITUTION_SUCCESS: {
      return {
        ...state,
        isLoadingInstitutions: false,
        availableInstitutions: action.payload?.data || [],
        errors: action.error
      };
    }
    case KYCConstants.GET_INSTITUTION_ERROR:
      return {
        ...state,
        isLoadingInstitutions: false,
        errors: action.error
      };

    case KYCConstants.CONTINUE_APPLICATION_REQUEST:
      return {
        ...state,
        send: true,
        sendKYC: true
      };
    case KYCConstants.CONTINUE_APPLICATION_SUCCESS:
      return {
        ...state,
        send: false,
        sendKYC: false,
        applicationKYC: {
          ...state?.applicationKYC,
          applicationState: {
            ...state?.applicationKYC?.applicationState,
            ...action.payload
          }
        }
      };
    case KYCConstants.CONTINUE_APPLICATION_ERROR:
      return {
        ...state,
        send: false,
        sendKYC: false,
        errors: action.error
      };
    case KYCConstants.CHECK_STATE_REQUEST:
      return {
        ...state,
        send: true,
        sendKYC: true,
        applicationState: null
      };
    case KYCConstants.CHECK_STATE_SUCCESS:
      return {
        ...state,
        send: false,
        sendKYC: false,
        applicationState: action.payload
      };
    case KYCConstants.CHECK_STATE_ERROR:
      return {
        ...state,
        send: false,
        sendKYC: false,
        errors: action.error
      };
    case KYCConstants.START_AGREEMENT_SIGNING_REQUEST:
      return {
        ...state,
        send: true,
        sendSigning: true,
        startSigning: false
      };
    case KYCConstants.START_AGREEMENT_SIGNING_SUCCESS:
      return {
        ...state,
        send: false,
        sendSigning: false,
        startSigning: true,
        applicationKYC: {
          ...state?.applicationKYC,
          applicationState: {
            ...state?.applicationKYC?.applicationState,
            ...action.payload
          }
        }
      };
    case KYCConstants.START_AGREEMENT_SIGNING_ERROR:
      return {
        ...state,
        send: false,
        sendSigning: false,
        startSigning: false,
        errors: action.error
      };
    case KYCConstants.CANCEL_UPLOAD_DOCUMENT_REQUEST:
      return {
        ...state,
        isCancelUploadDocLoading: true
      };
    case KYCConstants.CANCEL_UPLOAD_DOCUMENT_SUCCESS:
      return {
        ...state,
        isCancelUploadDocLoading: false
      };
    case KYCConstants.CANCEL_UPLOAD_DOCUMENT_ERROR:
      return {
        ...state,
        isCancelUploadDocLoading: false,
        errors: action.error
      };
    case KYCConstants.UPLOAD_ADDITIONAL_DOCUMENTS_REQUEST:
      return {
        ...state,
        send: true,
        sendKYC: true,
        applicationKYC: null
      };
    case KYCConstants.UPLOAD_ADDITIONAL_DOCUMENTS_SUCCESS:
      return {
        ...state,
        send: false,
        sendKYC: false,
        applicationKYC: action.payload
      };
    case KYCConstants.UPLOAD_ADDITIONAL_DOCUMENTS_ERROR:
      return {
        ...state,
        send: false,
        sendKYC: false,
        errors: action.error
      };
    case KYCConstants.START_ONDATO_BENEFICIARY_IDENTIFICATION_REQUEST:
      return {
        ...state,
        send: true,
        sendKYC: true,
        applicationKYC: null
      };
    case KYCConstants.START_ONDATO_BENEFICIARY_IDENTIFICATION_SUCCESS:
      return {
        ...state,
        send: false,
        sendKYC: false,
        applicationKYC: action.payload
      };
    case KYCConstants.START_ONDATO_BENEFICIARY_IDENTIFICATION_ERROR:
      return {
        ...state,
        send: false,
        sendKYC: false,
        errors: action.error
      };
    case KYCConstants.RENDER_PDF_FROM_HTML_REQUEST:
      return {
        ...state,
        send: true,
        renderKYCPDF: true
      };
    case KYCConstants.RENDER_PDF_FROM_HTML_SUCCESS:
      return {
        ...state,
        send: false,
        renderKYCPDF: false
      };
    case KYCConstants.RENDER_PDF_FROM_HTML_ERROR:
      return {
        ...state,
        send: false,
        renderKYCPDF: false,
        errors: action.error
      };
    case KYCConstants.UPLOAD_KYC_PDF_REQUEST:
      return {
        ...state,
        send: true,
        uploadKYCPDF: true
      };
    case KYCConstants.UPLOAD_KYC_PDF_SUCCESS:
      return {
        ...state,
        send: false,
        uploadKYCPDF: false,
        applicationKYC: {
          ...state?.applicationKYC,
          applicationState: {
            ...state?.applicationKYC?.applicationState,
            documentId: action.payload.documentId
          }
        }
      };
    case KYCConstants.UPLOAD_KYC_PDF_ERROR:
      return {
        ...state,
        send: false,
        uploadKYCPDF: false,
        errors: action.error
      };
    case KYCConstants.SIGN_APPLICATION_REQUEST:
      return {
        ...state,
        isSigning: true
      };
    case KYCConstants.SIGN_APPLICATION_SUCCESS:
      return {
        ...state,
        isSigning: false
      };
    case KYCConstants.SIGN_APPLICATION_ERROR:
      return {
        ...state,
        isSigning: false,
        errors: action.error
      };
    case KYCConstants.GET_APPLICATION_DOCUMENT_REQUESTS_REQUEST:
      return {
        ...state,
        loading: true
      };
    case KYCConstants.GET_APPLICATION_DOCUMENT_REQUESTS_SUCCESS:
      return {
        ...state,
        loading: false,
        documents: action.payload
      };
    case KYCConstants.GET_APPLICATION_DOCUMENT_REQUESTS_ERROR:
      return {
        ...state,
        loading: false,
        errors: action.error
      };
    case KYCConstants.GET_APPLICATION_DOCUMENT_REQUESTS_TO_SIGN_REQUEST:
      return {
        ...state,
        loading: true
      };
    case KYCConstants.GET_APPLICATION_DOCUMENT_REQUESTS_TO_SIGN_SUCCESS:
      return {
        ...state,
        loading: false
      };
    case KYCConstants.GET_APPLICATION_DOCUMENT_REQUESTS_TO_SIGN_ERROR:
      return {
        ...state,
        loading: false,
        errors: action.error
      };
    case KYCConstants.CONFIRM_APPLICATION_DOCUMENTS_UPLOAD_REQUEST:
      return {
        ...state,
        loading: true
      };
    case KYCConstants.CONFIRM_APPLICATION_DOCUMENTS_UPLOAD_SUCCESS:
      return {
        ...state,
        loading: false
      };
    case KYCConstants.CONFIRM_APPLICATION_DOCUMENTS_UPLOAD_ERROR:
      return {
        ...state,
        loading: false,
        errors: action.error
      };

    case KYCConstants.SIGN_STATUS_REQUEST:
      return {
        ...state,
        loading: true
      };
    case KYCConstants.SIGN_STATUS_SUCCESS:
      return {
        ...state,
        loading: false,
        status: action.payload
      };
    case KYCConstants.SIGN_STATUS_ERROR:
      return {
        ...state,
        loading: false,
        errors: action.error
      };
    default:
      return state;
  }
};
