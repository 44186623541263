import React from 'react';
import { AuthActions } from '../../../../../../Redux';
import { useDispatch } from 'react-redux';
import { CreateEmptyCompanyButtonStyles } from './CreateEmptyCompanyButton.style';
import { useTranslation } from 'react-i18next';

const CreateEmptyCompanyButton = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const handleButtonClick = async () => {
    await dispatch(AuthActions.addEmptyIdentification());
    await dispatch(AuthActions.getAuthorizedCompanies());
  };
  return (
    <CreateEmptyCompanyButtonStyles onClick={handleButtonClick}>
      {t('add_new_application')}
    </CreateEmptyCompanyButtonStyles>
  );
};

export default CreateEmptyCompanyButton;
