import {
  aPIPaths,
  handleResponse,
  methods,
  requestQuestionnaire,
  requestJSON
} from '@redux/Config';

export const QuestionnaireServices = {
  addQuestionnaireEntry,
  removeQuestionnaireEntry,
  getQuestionnaire,
  validateQuestionnairePage,
  saveQuestionnaireAnswers,
  updateRepeatingInformationCheckbox,
  selectQuestionnaireFlow
};

function addQuestionnaireEntry(questionnaireId, repeatableGroupId, data) {
  return requestQuestionnaire(
    aPIPaths.add_repeatable_entry
      .replace('{questionnaireId}', questionnaireId)
      .replace('{repeatableGroupId}', repeatableGroupId),
    {
      method: methods.post,
      data: JSON.stringify({
        answers: data
      })
    }
  ).then(handleResponse);
}

function removeQuestionnaireEntry(questionnaireId, repeatableGroupId, groupId) {
  return requestQuestionnaire(
    aPIPaths.remove_repeatable_entry
      .replace('{questionnaireId}', questionnaireId)
      .replace('{repeatableGroupId}', repeatableGroupId)
      .replace('{groupId}', groupId),
    {
      method: methods.delete
    }
  ).then(handleResponse);
}

function getQuestionnaire(questionnaireId) {
  return requestQuestionnaire(
    aPIPaths.get_questionnaire.replace('{questionnaireId}', questionnaireId),
    {
      method: methods.get
    }
  ).then(handleResponse);
}

function validateQuestionnairePage(questionnaireId, pageTitle) {
  return requestQuestionnaire(
    aPIPaths.validate_questionnaire_page
      .replace('{questionnaireId}', questionnaireId)
      .replace('{pageTitle}', pageTitle),
    {
      method: methods.get
    }
  ).then(handleResponse);
}

function saveQuestionnaireAnswers(questionnaireId, data) {
  return requestQuestionnaire(
    aPIPaths.save_questionnaire_answers.replace('{questionnaireId}', questionnaireId),
    {
      method: methods.put,
      data: JSON.stringify({
        answers: data
      })
    }
  ).then(handleResponse);
}

function updateRepeatingInformationCheckbox(questionnaireId, data) {
  return requestQuestionnaire(
    aPIPaths.update_repeating_info_checkbox.replace('{questionnaireId}', questionnaireId),
    {
      method: methods.put,
      data: JSON.stringify(data)
    }
  ).then(handleResponse);
}

function selectQuestionnaireFlow(data) {
  return requestJSON(aPIPaths.flow_selection, {
    method: methods.post,
    data: JSON.stringify(data)
  }).then(handleResponse);
}
