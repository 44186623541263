export const QuestionnaireConstants = {
  ADD_QUESTIONNAIRE_ENTRY_REQUEST: 'ADD_QUESTIONNAIRE_ENTRY_REQUEST',
  ADD_QUESTIONNAIRE_ENTRY_SUCCESS: 'ADD_QUESTIONNAIRE_ENTRY_SUCCESS',
  ADD_QUESTIONNAIRE_ENTRY_ERROR: 'ADD_QUESTIONNAIRE_ENTRY_ERROR',

  REMOVE_QUESTIONNAIRE_ENTRY_REQUEST: 'REMOVE_QUESTIONNAIRE_ENTRY_REQUEST',
  REMOVE_QUESTIONNAIRE_ENTRY_SUCCESS: 'REMOVE_QUESTIONNAIRE_ENTRY_SUCCESS',
  REMOVE_QUESTIONNAIRE_ENTRY_ERROR: 'REMOVE_QUESTIONNAIRE_ENTRY_ERROR',

  GET_QUESTIONNAIRE_REQUEST: 'GET_QUESTIONNAIRE_REQUEST',
  GET_QUESTIONNAIRE_SUCCESS: 'GET_QUESTIONNAIRE_SUCCESS',
  GET_QUESTIONNAIRE_ERROR: 'GET_QUESTIONNAIRE_ERROR',

  GET_QUESTIONNAIRE_VALIDATE_PAGE_REQUEST: 'GET_QUESTIONNAIRE_VALIDATE_PAGE_REQUEST',
  GET_QUESTIONNAIRE_VALIDATE_PAGE_SUCCESS: 'GET_QUESTIONNAIRE_VALIDATE_PAGE_SUCCESS',
  GET_QUESTIONNAIRE_VALIDATE_PAGE_ERROR: 'GET_QUESTIONNAIRE_VALIDATE_PAGE_ERROR',

  SAVE_QUESTIONNAIRE_ANSWERS_REQUEST: 'SAVE_QUESTIONNAIRE_ANSWERS_REQUEST',
  SAVE_QUESTIONNAIRE_ANSWERS_SUCCESS: 'SAVE_QUESTIONNAIRE_ANSWERS_SUCCESS',
  SAVE_QUESTIONNAIRE_ANSWERS_ERROR: 'SAVE_QUESTIONNAIRE_ANSWERS_ERROR',

  UPDATE_REPEATING_INFO_CHECKBOX_REQUEST: 'UPDATE_REPEATING_INFO_CHECKBOX_REQUEST',
  UPDATE_REPEATING_INFO_CHECKBOX_SUCCESS: 'UPDATE_REPEATING_INFO_CHECKBOX_SUCCESS',
  UPDATE_REPEATING_INFO_CHECKBOX_ERROR: 'UPDATE_REPEATING_INFO_CHECKBOX_ERROR',

  SELECT_QUESTIONNAIRE_FLOW_REQUEST: 'SELECT_QUESTIONNAIRE_FLOW_REQUEST',
  SELECT_QUESTIONNAIRE_FLOW_SUCCESS: 'SELECT_QUESTIONNAIRE_FLOW_SUCCESS',
  SELECT_QUESTIONNAIRE_FLOW_ERROR: 'SELECT_QUESTIONNAIRE_FLOW_ERROR',

  RESET_QUESTIONNAIRE_STATE: 'RESET_QUESTIONNAIRE_STATE',

  SET_FORM_KEY_IN_EDIT: 'SET_FORM_KEY_IN_EDIT',

  IS_ALL_REPEATABLE_GROUPS_COMPLETED: 'IS_ALL_REPEATABLE_GROUPS_COMPLETED',
  TOTAL_REPEATABLE_GROUPS: 'TOTAL_REPEATABLE_GROUPS'
};
