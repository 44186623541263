import React, { useState } from 'react';
import {
  MainWrapper,
  TitleStyles,
  DescriptionStyles,
  TextStyles,
  BusinessRedirectCard,
  FarmRedirectCard,
  ArrowStyles
} from './typeOfAccount.styles';
import { ArrowLeft } from '../../../Assets/images/logos';
import { useTranslation } from 'react-i18next';
import { CompanyRepresentativeForm } from '../Modals/CompanyModal/CompanyDisplayContainer/CompanyRepresentativeForm/CompanyRepresentativeForm';
import { steps } from '../Modals/CompanyModal/CompanyDisplayContainer/CompanyDisplayContainer';

export const TypeOfAccountPage = ({
  handleStepChange,
  onSubmit,
  isLoading,
  companyExistsError
}) => {
  const [representativeModalOpen, setRepresentativeModalOpen] = useState(false);
  const { t } = useTranslation();

  const isBank = process.env.REACT_APP_TRADEMARK.includes('Bank');

  return (
    <MainWrapper>
      <ArrowStyles>
        <ArrowLeft onClick={() => handleStepChange(steps.select)} />
      </ArrowStyles>
      <TitleStyles>
        {isBank ? t('account_type_page_title') : t('account_type_page_title_smego')}
      </TitleStyles>
      <DescriptionStyles>
        {isBank ? t('account_type_page_subtitle') : t('account_type_page_subtitle_smego')}
      </DescriptionStyles>
      <BusinessRedirectCard onClick={() => setRepresentativeModalOpen(true)}>
        <TextStyles>{t('account_type_page_business_button')}</TextStyles>
      </BusinessRedirectCard>
      <FarmRedirectCard onClick={() => handleStepChange(steps.farm_details_form)}>
        <TextStyles>{t('account_type_page_farm_button')}</TextStyles>
      </FarmRedirectCard>
      {representativeModalOpen && (
        <CompanyRepresentativeForm
          onSubmit={onSubmit}
          backButtonHandler={() => setRepresentativeModalOpen(false)}
          isLoading={isLoading}
          companyExistsError={companyExistsError}
        />
      )}
    </MainWrapper>
  );
};
