import React, { useMemo } from 'react';
import * as Yup from 'yup';
import i18next from 'i18next';
import { useSelector } from 'react-redux';
import { merge } from 'lodash';
import { RadioChipOption } from '../../../../components';
import { useFarmerFormValidation } from '../../../../../LegalEntityQuestionnaire/validation/useFarmerFormValidation';
import { useTranslation } from 'react-i18next';
import { Field, Loader } from '@single-platform/components';
import { FarmersQuestionnaireHeader, PageSubtitle } from '../../../../farmersQuestionnaire.style';
import useWindowDimensions from '../../../../../LegalEntityQuestionnaire/Utils/window-size.service';
import breakpointSizes from '../../../../../LegalEntityQuestionnaire/styled-theme-styles/global-style-variables.style';
import {
  FormStyles,
  PageHeader
} from '../../../../../LegalEntityQuestionnaire/screens/renderPage/renderPage.style';
import { ButtonSectionWrapperStyle } from '../../../formStyles';
import { ButtonSection } from '../../../../../LegalEntityQuestionnaire/components';
import { yesNoChipOptions } from '../../../../constants';
import { LinkPopup } from '../../../../../LegalEntityQuestionnaire/components/linkPopup/linkPopup';
import { history } from '../../../../../../../Config';

const defaultValues = {
  isFarmConnectedToUsaForTaxPurposes: null
};

const linkPopupGroup = {
  text: 'link_popup_fatca_title',
  popupKey: 'link_popup_fatca'
};

export const FarmInternationalTaxPage = ({ onSubmit, isAtBottom }) => {
  const { t } = useTranslation();
  const { width } = useWindowDimensions();
  const { loading, farmerInformation } = useSelector((state) => state.FarmersQuestionnaireReducer);

  const validationSchema = useMemo(
    () =>
      Yup.object({
        isFarmConnectedToUsaForTaxPurposes: Yup.boolean()
          .required(t('validation_required_field'))
          .nullable()
      }),
    [t]
  );

  const existingValues = farmerInformation?.farmActivitiesSection?.farmInternationalTaxPage;
  const initialValues = { ...defaultValues, ...existingValues };

  const { validationHelpers, getValues, setTouchedOnAll, handleSubmit } = useFarmerFormValidation({
    initialValues,
    validationSchema
  });

  const onHandleSubmit = async (e) => {
    e.preventDefault();
    await handleSubmit(
      async (data) => {
        await onSubmit?.(data, 'farmInternationalTaxPage');
        history.push('/FarmersQuestionnaire?success=farmActivitiesSection');
      },
      () => setTouchedOnAll()
    )();
  };

  if (loading) return <Loader />;

  const values = getValues();

  return (
    <FormStyles small={width < breakpointSizes.phone}>
      <FarmersQuestionnaireHeader noSubHeader={true}>
        <PageHeader>{t('farmers_international_tax_page_header')}</PageHeader>
        <PageSubtitle>{t('header_fatca_description')}</PageSubtitle>
      </FarmersQuestionnaireHeader>
      <LinkPopup group={linkPopupGroup} />
      <Field
        Component={RadioChipOption}
        options={yesNoChipOptions}
        value={values.isFarmConnectedToUsaForTaxPurposes}
        name="isFarmConnectedToUsaForTaxPurposes"
        validationHelpers={validationHelpers}
        validateOnChange
      />
      <ButtonSectionWrapperStyle isAtBottom={isAtBottom}>
        <ButtonSection
          secondButtonClick={onHandleSubmit}
          secondButtonPrimary={true}
          secondButtonText={t('button_save_and_continue')}
        />
      </ButtonSectionWrapperStyle>
    </FormStyles>
  );
};
