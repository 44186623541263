import { Field } from '@single-platform/components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { DropdownStyled } from './dropdownInput.style';

export const DropdownInput = ({
  options,
  value,
  onChange,
  placeholder,
  name,
  validationHelpers
}) => {
  const { t } = useTranslation();
  return (
    <Field
      Component={DropdownStyled}
      name={name}
      options={options.map((option) => {
        return { ...option, label: t(option.label) };
      })}
      value={value}
      placeholder={placeholder}
      required
      validationHelpers={validationHelpers}
      validateOnChange
    />
  );
};
