import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useFarmerFormValidation } from '../../../../../LegalEntityQuestionnaire/validation/useFarmerFormValidation';
import * as Yup from 'yup';
import useWindowDimensions from '../../../../../LegalEntityQuestionnaire/Utils/window-size.service';
import { Loader, Toast } from '@single-platform/components';
import { FarmersQuestionnaireHeader } from '../../../../farmersQuestionnaire.style';
import {
  FormStyles,
  PageHeader
} from '../../../../../LegalEntityQuestionnaire/screens/renderPage/renderPage.style';
import { ButtonSection } from '../../../../../LegalEntityQuestionnaire/components';
import { useSelector } from 'react-redux';
import { ButtonSectionWrapperStyle } from '../../../formStyles';
import { FormList } from '../../../../components/formList/formList';
import breakpointSizes from '../../../../../LegalEntityQuestionnaire/styled-theme-styles/global-style-variables.style';
import { history } from '../../../../../../../Config';

const defaultValues = {
  farmSuppliers: []
};

export const FarmSupplierPage = ({ onSubmit, isAtBottom }) => {
  const { t } = useTranslation();
  const { width } = useWindowDimensions();
  const { loading, farmerInformation } = useSelector((state) => state.FarmersQuestionnaireReducer);

  const validationSchema = useMemo(
    () =>
      Yup.object({
        farmSuppliers: Yup.array()
          .min(1, t('farmers_suppliers_less_than_one_error'))
          .required(t('validation_required_field'))
      }),
    [t]
  );

  const existingValues = farmerInformation?.farmClientsAndSuppliersSection?.farmSupplierPage;
  const initialValues = { ...defaultValues, ...existingValues };

  const {
    validationHelpers,
    errors,
    getValues,
    setTouchedOnAll,
    handleSubmit
  } = useFarmerFormValidation({
    initialValues,
    validationSchema
  });

  const onHandleSubmit = async (e) => {
    e.preventDefault();
    await handleSubmit(
      async (data) => {
        await onSubmit(data, 'farmSupplierPage');
        history.push('/FarmersQuestionnaire?success=farmClientsAndSuppliersSection');
      },
      () => setTouchedOnAll()
    )();
  };

  if (loading) return <Loader />;

  const values = getValues();

  return (
    <FormStyles small={width < breakpointSizes.phone}>
      <FarmersQuestionnaireHeader>
        <PageHeader>{t('farmers_farm_supplier_header')}</PageHeader>
      </FarmersQuestionnaireHeader>
      {errors.farmSuppliers && (
        <Toast
          isVisible={!!errors.farmSuppliers}
          animate
          variant="error"
          header={t(errors.farmSuppliers.message)}
        />
      )}
      <Toast isVisible animate variant="info" header={t('farmers_farm_suppliers_info_message')} />
      <FormList
        name="farmSuppliers"
        list={values.farmSuppliers}
        drawerType="suppliers"
        setList={(updatedList) => validationHelpers.setValue('farmSuppliers', updatedList)}
      />
      <ButtonSectionWrapperStyle isAtBottom={isAtBottom}>
        <ButtonSection
          secondButtonClick={onHandleSubmit}
          secondButtonPrimary
          secondButtonText={t('button_save_and_continue')}
        />
      </ButtonSectionWrapperStyle>
    </FormStyles>
  );
};
