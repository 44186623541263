import styled from 'styled-components';

export const CompanyContainerStyles = styled.div`
  padding: 8px;
  border-radius: 16px;
  width: 100%;
  text-align: left;
  display: flex;
  border: ${(props) => (props.isSelected ? 'none' : '1px #e6e6e6 solid')};
  background-color: ${({ theme, isSelected }) =>
    isSelected ? theme.colors.base.primary.shades[10] : '#FFFFFF'};
  cursor: pointer;
`;

export const CompanyNameStyles = styled.div`
  display: flex;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0em;
`;

export const CompanyCodeStyles = styled.div`
  padding: 0px;
  min-height: 1.25rem;
`;

export const CompanyNameCodeWrapper = styled.div`
  margin-left: -5px;
`;

export const RadioSelectWrapper = styled.div`
  padding-left: 4px;
  padding-top: 4px;
  label {
    span:not(:last-child) {
      width: 1.2rem;
      height: 1.2rem;
      &:after {
        background-color: ${({ theme }) => theme.colors.base.primary.shades[100]};
      }
      border: 2px solid ${({ theme }) => theme.colors.base.primary.shades[100]}; !important
    }
  }
`;
