import React from 'react';
import { ChipOption, ErrorMessage } from '@single-platform/components';
import { ChipOptionsWrapper } from '../../questionnaireSections/formStyles';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

const CustomChipOption = styled(ChipOption)`
  padding: ${({ chipPadding }) => chipPadding ?? undefined};
`;

export const RadioChipOption = ({
  options,
  onChange,
  value,
  fieldMeta,
  name,
  isInModal,
  validationHelpers,
  chipPadding
}) => {
  const { t } = useTranslation();
  const { isTouched, error, showValidationMessage, message } = fieldMeta || {};
  const hasValidationMessages = error?.message && isTouched;

  return (
    <>
      <ChipOptionsWrapper>
        {options.map((option) => (
          <CustomChipOption
            name={name}
            key={option.value}
            id={option.value}
            checked={value === option.value}
            onChange={() => {
              onChange(option.value);
            }}
            validationHelpers={validationHelpers}
            on={isInModal ? 'light' : 'dark'}
            chipPadding={chipPadding}
          >
            {t(option.label)}
          </CustomChipOption>
        ))}
      </ChipOptionsWrapper>
      <ErrorMessage
        style={{ marginLeft: '1rem' }}
        hidden={!hasValidationMessages || !showValidationMessage}
      >
        {message}
      </ErrorMessage>
    </>
  );
};
