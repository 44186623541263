import React from 'react';
import { Route, Router, Switch } from 'react-router-dom';
import { connect, useSelector } from 'react-redux';

import { history, routerPath, questionnairePaths, farmersQuestionnairePaths } from '../Config';
import { IndexContainer, LoginContainer } from '../Containers';
import { PartContent, PartTop } from '../PageParts';

import { LegalEntityQuestionnaire } from '../Containers/ApplicationKYC/LegalEntityQuestionnaire/LegalEntityQuestionnaire';
import { Error } from '../Containers/ApplicationKYC/LegalEntityQuestionnaire/screens/error/error';
import { AgreementSigning } from '../Containers/ApplicationKYC/LegalEntityQuestionnaire/screens/agreementSigning/agreementSigning';
import { Footer } from '../Components/Footer';
import { MainContainer } from './App.styles';
import { StatusPages } from '../Containers/ApplicationKYC/LegalEntityQuestionnaire/screens/statusPages/statusPages';
import { UploadRequestedAppDocs } from '../Containers/ApplicationKYC/Components/UploadRequestedAppDocs';
import { SSOLoginContainer } from '../Containers/SSOLoginContainer';
import { RepresentativeChange } from '../Containers/UserIdentification/Screens/RepresentativeChange/RepresentativeChange';
import { ExternalPersons } from '../Containers/UserIdentification/Screens/ExternalPersons/ExternalPersons';
import { OndatoError } from '../Containers/OndatoError/OndatoError';
import { FarmersQuestionnaire } from '../Containers/ApplicationKYC/FarmersQuestionnaire/farmersQuestionnaire';
import { RegistrationPage } from '../Containers/Auth/Registration/registration';
import {
  FarmClientAndSupplierSection,
  FarmerSection,
  FarmRepresentativeSection,
  FarmSection,
  FarmPaymentsAndTransactionsSection
} from '../Containers/ApplicationKYC/FarmersQuestionnaire/questionnaireSections';
import { FarmPartnerSection } from '../Containers/ApplicationKYC/FarmersQuestionnaire/questionnaireSections/farmPartnerSection/farmPartnerSection';
import { FarmActivitiesSection } from '../Containers/ApplicationKYC/FarmersQuestionnaire/questionnaireSections/farmActivitiesSection/farmActivitiesSection';

const App = () => {
  const initiatedSSOLogin = useSelector((state) => state.AuthReducers.initiatedSSOLogin);

  const containerWithCard = (Component) => (
    <PartContent>
      <Component />
    </PartContent>
  );

  return (
    <MainContainer>
      <PartTop />
      <Router history={history}>
        <Switch>
          <Route
            path={questionnairePaths.questionnaire}
            exact
            component={LegalEntityQuestionnaire}
          />
          <Route
            path={farmersQuestionnairePaths.farmersQuestionnaire}
            exact
            component={FarmersQuestionnaire}
          />
          <Route exact path={routerPath.login} component={LoginContainer} />
          <Route path={routerPath.redirectEIdEasy} component={LoginContainer} />

          <Route path={routerPath.status} exact component={() => containerWithCard(StatusPages)} />
          <Route path={questionnairePaths.agreementSigning} exact component={AgreementSigning} />
          <Route exact path={routerPath.errorPage} component={Error} />
          <Route path={routerPath.representativeChange} component={RepresentativeChange} />
          <Route path={routerPath.companyIdentification} component={ExternalPersons} />
          <Route
            path={questionnairePaths.documentsUpload}
            exact
            component={() => containerWithCard(UploadRequestedAppDocs)}
          />
          <Route path={routerPath.smego} exact component={SSOLoginContainer} />
          <Route
            path={routerPath.index}
            exact
            component={() => containerWithCard(IndexContainer)}
          />
          <Route path={routerPath.registration} exact component={RegistrationPage} />
          <Route path={routerPath.ondatoError} exact component={OndatoError} />
          <Route
            path={farmersQuestionnairePaths.farmersQuestionnaireFarmSection}
            component={FarmSection}
          />
          <Route
            path={farmersQuestionnairePaths.farmersQuestionnaireFarmerSection}
            component={FarmerSection}
          />
          <Route
            path={farmersQuestionnairePaths.farmersQuestionnaireRepresentativeSection}
            component={FarmRepresentativeSection}
          />
          <Route
            path={farmersQuestionnairePaths.farmersQuestionnaireClientsAndSupliersSection}
            component={FarmClientAndSupplierSection}
          />
          <Route
            path={farmersQuestionnairePaths.farmersQuestionnaireFarmPartnersSection}
            component={FarmPartnerSection}
          />
          <Route
            path={farmersQuestionnairePaths.farmersQuestionnairePaymentsAndTransactionsSection}
            component={FarmPaymentsAndTransactionsSection}
          />
          <Route
            path={farmersQuestionnairePaths.farmersQuestionnaireFarmActivitiesSection}
            component={FarmActivitiesSection}
          />
        </Switch>
      </Router>

      {!initiatedSSOLogin && <Footer />}
    </MainContainer>
  );
};

function mapStateToProps(state) {
  const { language } = state.languageReducer;
  const { KYC } = state.KYCReducers;

  return {
    language,
    KYC
  };
}

const connectedApp = connect(mapStateToProps)(App);
export { connectedApp as App };
