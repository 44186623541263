import React, { useMemo } from 'react';
import { useFarmerFormValidation } from '../../../../../LegalEntityQuestionnaire/validation/useFarmerFormValidation';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import breakpointSizes from '../../../../../LegalEntityQuestionnaire/styled-theme-styles/global-style-variables.style';
import useWindowDimensions from '../../../../../LegalEntityQuestionnaire/Utils/window-size.service';
import { Input, Field, DatePicker, Loader } from '@single-platform/components';
import { FarmersQuestionnaireHeader } from '../../../../farmersQuestionnaire.style';
import {
  FormStyles,
  PageHeader
} from '../../../../../LegalEntityQuestionnaire/screens/renderPage/renderPage.style';
import { ButtonSection } from '../../../../../LegalEntityQuestionnaire/components';
import { useSelector } from 'react-redux';
import { formatLocalDate } from '../../../../utilities/formatLocalDate';
import { ButtonSectionWrapperStyle } from '../../../formStyles';

const defaultValues = {
  code: null,
  registrationDate: null,
  individualActivityNumber: null,
  certificateNumber: null,
  certificateIssuanceDate: null
};

export const FarmInfoPage = ({ onSubmit, isAtBottom }) => {
  const { t } = useTranslation();
  const { width } = useWindowDimensions();
  const { loading, farmerInformation } = useSelector((state) => state.FarmersQuestionnaireReducer);

  const validationSchema = useMemo(
    () =>
      Yup.object({
        code: Yup.string().nullable().required(t('validation_required_field')),
        registrationDate: Yup.date()
          .required(t('validation_required_field'))
          .max(new Date(), t('validation_date_entered_must_be_no_further_than_today'))
          .typeError(t('validation_date_is_not_valid'))
          .nullable(),
        individualActivityNumber: Yup.string().nullable().required(t('validation_required_field')),
        certificateNumber: Yup.string().nullable().required(t('validation_required_field')),
        certificateIssuanceDate: Yup.date()
          .required(t('validation_required_field'))
          .max(new Date(), t('validation_date_entered_must_be_no_further_than_today'))
          .typeError(t('validation_date_is_not_valid'))
          .nullable()
      }),
    [t]
  );

  const existingValues = farmerInformation?.farmSection?.farmInfoPage;
  const initialValues = { ...defaultValues, ...existingValues };

  const { validationHelpers, getValues, setTouchedOnAll, handleSubmit } = useFarmerFormValidation({
    initialValues,
    validationSchema
  });

  const onHandleSubmit = async (e) => {
    e.preventDefault();
    await handleSubmit(
      async (data) => {
        const modifiedData = {
          ...data,
          registrationDate: formatLocalDate(data.registrationDate),
          certificateIssuanceDate: formatLocalDate(data.certificateIssuanceDate)
        };
        await onSubmit?.(modifiedData, 'farmInfoPage');
      },
      () => setTouchedOnAll()
    )();
  };

  if (loading) return <Loader />;

  const values = getValues();

  return (
    <FormStyles small={width < breakpointSizes.phone}>
      <FarmersQuestionnaireHeader>
        <PageHeader>{t('farmers_farm_info_header')}</PageHeader>
      </FarmersQuestionnaireHeader>
      <Field
        Component={Input}
        name="code"
        value={values.code}
        placeholder={t('farmers_farm_info_code')}
        validationHelpers={validationHelpers}
        required
        validateOnChange
        disabled
      />
      <Field
        Component={DatePicker}
        name="registrationDate"
        required
        showDisabledMonthNavigation
        value={values.registrationDate}
        placeholder={t('farmers_farm_info_registration_date')}
        validateOnBlur
        validateOnChange
        showYearDropdown
        yearDropdownItemNumber={99}
        scrollableYearDropdown
        validationHelpers={validationHelpers}
      />
      <Field
        Component={Input}
        name="individualActivityNumber"
        value={values.individualActivityNumber}
        placeholder={t('farmers_farm_info_activity_code')}
        validationHelpers={validationHelpers}
        required
        validateOnChange
      />
      <Field
        Component={Input}
        name="certificateNumber"
        value={values.certificateNumber}
        placeholder={t('farmers_farm_info_certificate_number')}
        validationHelpers={validationHelpers}
        required
        validateOnChange
      />
      <Field
        Component={DatePicker}
        name="certificateIssuanceDate"
        required
        showDisabledMonthNavigation
        value={values.certificateIssuanceDate}
        placeholder={t('farmers_farm_info_certificate_issue_date')}
        validateOnBlur
        validateOnChange
        showYearDropdown
        yearDropdownItemNumber={99}
        scrollableYearDropdown
        validationHelpers={validationHelpers}
      />
      <ButtonSectionWrapperStyle isAtBottom={isAtBottom}>
        <ButtonSection
          secondButtonClick={onHandleSubmit}
          secondButtonPrimary
          secondButtonText={t('button_save_and_continue')}
        />
      </ButtonSectionWrapperStyle>
    </FormStyles>
  );
};
