import { aPIPaths, handleResponse, methods, requestQuestionnaire } from '@redux/Config';

export const FarmerQuestionnaireServices = {
  getFarmerInformation,
  patchFarmerInformation,
  patchFarmInformation,
  patchRepresentativeInformation,
  patchFarmClientAndSupplierSection,
  deleteFarmGroupEntry,
  updateFarmerInformation
};

function getFarmerInformation(questionnaireId) {
  return requestQuestionnaire(
    aPIPaths.getFarmerInformation.replace('{questionnaireId}', questionnaireId),
    {
      method: methods.get,
      data: JSON.stringify({ questionnaireId: questionnaireId })
    }
  ).then(handleResponse);
}

function updateFarmerInformation(questionnaireId, data) {
  return requestQuestionnaire(
    aPIPaths.updateFarmerInformation.replace('{questionnaireId}', questionnaireId),
    {
      method: methods.put,
      data: JSON.stringify(data)
    }
  ).then(handleResponse);
}

function patchFarmerInformation(questionnaireId, data) {
  return requestQuestionnaire(
    aPIPaths.patchFarmerInformation.replace('{questionnaireId}', questionnaireId),
    {
      method: methods.patch,
      data: JSON.stringify(data)
    }
  ).then(handleResponse);
}
function patchRepresentativeInformation(questionnaireId, data) {
  return requestQuestionnaire(
    aPIPaths.patchRepresentativeInformation.replace('{questionnaireId}', questionnaireId),
    {
      method: methods.patch,
      data: JSON.stringify(data)
    }
  ).then(handleResponse);
}
function patchFarmClientAndSupplierSection(questionnaireId, data) {
  return requestQuestionnaire(
    aPIPaths.patchFarmClientAndSupplierSection.replace('{questionnaireId}', questionnaireId),
    {
      method: methods.patch,
      data: JSON.stringify(data)
    }
  ).then(handleResponse);
}

function patchFarmInformation(questionnaireId, data) {
  return requestQuestionnaire(
    aPIPaths.patchFarmInformation.replace('{questionnaireId}', questionnaireId),
    {
      method: methods.patch,
      data: JSON.stringify(data)
    }
  ).then(handleResponse);
}

function deleteFarmGroupEntry(farmQuestionnaireGroupType, questionnaireId, entryId) {
  return requestQuestionnaire(
    aPIPaths.deleteFarmGroupEntry
      .replace('{farmQuestionnaireGroupType}', farmQuestionnaireGroupType)
      .replace('{questionnaireId}', questionnaireId)
      .replace('{entryId}', entryId),
    {
      method: methods.delete
    }
  ).then(handleResponse);
}
