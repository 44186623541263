import { FarmerQuestionnaireConstants } from './constants';

export const FarmersQuestionnaireReducer = function (
  state = {
    formKeyInEdit: 0,
    allRepeatableGroupsCompleted: true,
    isFarmerQuestionnaireSession: false,
    isCompletedModalVisible: true
  },
  action
) {
  switch (action.type) {
    case FarmerQuestionnaireConstants.GET_FARMER_INFORMATION_REQUEST:
    case FarmerQuestionnaireConstants.UPDATE_FARMER_INFORMATION_REQUEST:
      return {
        ...state,
        loading: true
      };
    case FarmerQuestionnaireConstants.GET_FARMER_INFORMATION_SUCCESS:
    case FarmerQuestionnaireConstants.UPDATE_FARMER_INFORMATION_SUCCESS:
      return {
        ...state,
        loading: false,
        farmerInformation: action.payload
      };
    case FarmerQuestionnaireConstants.GET_FARMER_INFORMATION_ERROR:
    case FarmerQuestionnaireConstants.UPDATE_FARMER_INFORMATION_ERROR:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case FarmerQuestionnaireConstants.SET_FARMER_QUESTIONNAIRE_SESSION:
      return {
        ...state,
        isFarmerQuestionnaireSession: action.payload
      };

    case FarmerQuestionnaireConstants.SET_STEP_BACK_QUESTIONNAIRE_HANDLER:
      return {
        ...state,
        stepBackQuestionnaireHandler: action.payload
      };

    case FarmerQuestionnaireConstants.SET_QUESTIONNAIRE_ACTIVE_STEP:
      return {
        ...state,
        activeStep: action.payload
      };

    case FarmerQuestionnaireConstants.RESET:
      return {
        activeStep: 0,
        isFarmerQuestionnaireSession: false,
        loading: false,
        error: null,
        farmerInformation: null
      };

    case FarmerQuestionnaireConstants.SET_IS_COMPLETED_MODAL_VISIBLE:
      return {
        ...state,
        isCompletedModalVisible: action.payload
      };

    default:
      return state;
  }
};
