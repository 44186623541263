import { checkJsonProp } from '@utilities';
import { Notification } from '@components';
import { OnboardingFlowHandlerActions } from '../../Services/OnboardingFlowHandler/actions';
import { history, routerPath } from '../../../Config';

export const serverErrorsMiddleware = (store) => (next) => (action) => {
  if (!action) return;
  const { type } = action,
    matches = /(.*)_(ERROR)/.exec(type);

  let result = next(action),
    test = store.getState();
  if (!matches) {
    return result;
  }

  if (result?.error?.response?.status === 401) {
    sessionStorage.clear();
    store.dispatch(
      OnboardingFlowHandlerActions.setTraceId(result?.error?.response?.headers['x-traceid'])
    );

    Notification({
      type: 'error',
      message: 'AuthErrors',
      description: result.error.response.headers['x-traceid']
    });

    history.push(routerPath.login);

    return;
  }

  for (let key in test) {
    let error = checkJsonProp(test[key], 'errors');
    if (error !== null && error !== '') {
      let errorValues = checkJsonProp(error, 'response.data.message'),
        errorCode = checkJsonProp(error, 'response.data.errorCode');
      if (errorValues === null) errorValues = error.message;
      if (errorCode === null) errorCode = 'Unknown error';
      store.dispatch(
        OnboardingFlowHandlerActions.setTraceId(result?.error?.response?.headers['x-traceid'])
      );

      Notification({
        type: 'error',
        message: errorCode,
        description: result?.error?.response?.headers['x-traceid'],
        noTranslateDescription: true
      });
    }
  }

  return result;
};
