import React, { useMemo } from 'react';
import { useFarmerFormValidation } from '../../../../../LegalEntityQuestionnaire/validation/useFarmerFormValidation';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import useWindowDimensions from '../../../../../LegalEntityQuestionnaire/Utils/window-size.service';
import { Field, Loader, Toast } from '@single-platform/components';
import { FarmersQuestionnaireHeader, PageSubtitle } from '../../../../farmersQuestionnaire.style';
import {
  FormStyles,
  PageHeader
} from '../../../../../LegalEntityQuestionnaire/screens/renderPage/renderPage.style';
import { ButtonSection } from '../../../../../LegalEntityQuestionnaire/components';
import { useSelector } from 'react-redux';
import { ButtonSectionWrapperStyle } from '../../../formStyles';
import { RadioChipOption } from '../../../../components';
import { FormList } from '../../../../components/formList/formList';
import breakpointSizes from '../../../../../LegalEntityQuestionnaire/styled-theme-styles/global-style-variables.style';

const defaultValues = {
  farmClientType: null,
  farmClients: []
};

const chipOptions = [
  { label: 'farmers_farmer_client_type_companies', value: 'Company' },
  { label: 'farmers_farmer_client_type_individuals', value: 'PrivateIndividual' }
];

export const FarmClientPage = ({ onSubmit, isAtBottom }) => {
  const { t } = useTranslation();
  const { width } = useWindowDimensions();
  const { loading, farmerInformation } = useSelector((state) => state.FarmersQuestionnaireReducer);

  const validationSchema = useMemo(
    () =>
      Yup.object({
        farmClientType: Yup.string().required(t('validation_required_field')).nullable(),
        farmClients: Yup.array().when('farmClientType', {
          is: 'Company',
          then: Yup.array()
            .min(1, t('farmers_clients_less_than_one_error'))
            .required(t('validation_required_field'))
        })
      }),
    [t]
  );

  const existingValues = farmerInformation?.farmClientsAndSuppliersSection?.farmClientPage;
  const initialValues = { ...defaultValues, ...existingValues };

  const {
    validationHelpers,
    errors,
    getValues,
    setTouchedOnAll,
    handleSubmit
  } = useFarmerFormValidation({
    initialValues,
    validationSchema
  });

  const onHandleSubmit = async (e) => {
    e.preventDefault();
    await handleSubmit(
      async (data) => {
        await onSubmit(data, 'farmClientPage');
      },
      () => setTouchedOnAll()
    )();
  };

  if (loading) return <Loader />;

  const values = getValues();

  return (
    <FormStyles small={width < breakpointSizes.phone}>
      <FarmersQuestionnaireHeader>
        <PageHeader>{t('farmers_farm_clients_header')}</PageHeader>
        <PageSubtitle>{t('farmers_farm_clients_subheader')}</PageSubtitle>
      </FarmersQuestionnaireHeader>
      {errors.farmClients && (
        <Toast
          isVisible={!!errors.farmClients}
          animate={true}
          variant="error"
          header={t(errors.farmClients.message)}
        />
      )}
      <Field
        Component={RadioChipOption}
        options={chipOptions}
        value={values.farmClientType}
        name="farmClientType"
        validationHelpers={validationHelpers}
        validateOnChange
      />
      {values.farmClientType === 'Company' && (
        <>
          <Toast isVisible animate variant="info" header={t('farmers_farm_clients_info_message')} />
          <FormList
            name="farmClients"
            list={values.farmClients}
            drawerType="companies"
            setList={(updatedList) => validationHelpers.setValue('farmClients', updatedList)}
          />
        </>
      )}
      <ButtonSectionWrapperStyle isAtBottom={isAtBottom}>
        <ButtonSection
          secondButtonClick={onHandleSubmit}
          secondButtonPrimary
          secondButtonText={t('button_save_and_continue')}
        />
      </ButtonSectionWrapperStyle>
    </FormStyles>
  );
};
