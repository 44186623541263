import { Button } from '@single-platform/button';
import { responsiveBreakPoints } from '@single-platform/components/dist/design/designVariables';
import styled from 'styled-components';

export const MainWrapper = styled.div`
  flex-direction: column;
  display: flex;
  align-items: center;
  max-width: 33.75rem;
  min-width: 33.75rem;
  justify-content: space-between;
  height: 80vh;
  margin: 0 auto;

  @media (${responsiveBreakPoints.maxPhone}) {
    width: 100%;
    min-width: 100%;
    padding: 0 1rem;
  }
`;

export const Wrapper = styled.div`
  flex-direction: column;
  width: 100%;
  display: flex;
`;

export const TitleStyles = styled.div`
  text-align: left;
  font-size: 1.5rem;
  font-weight: 37.5rem;
  line-height: 2rem;
  margin-bottom: 0.5rem;
  margin-top: 2rem;
`;
export const DescriptionStyles = styled.div`
  text-align: left;
  font-size: 0.875rem;
  font-weight: 25rem;
  line-height: 1.375rem;
  margin-bottom: 2rem;
  color: ${({ theme }) => theme.colors.base.black.tints[60]};
`;

export const FarmersRedirectCard = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 5.75rem;
  border-radius: 6.25rem;
  background-color: ${({ theme }) => theme.colors.base.white.functional};
  margin-bottom: 1rem;

  @media screen and (max-width: 430px) {
    padding: 0 1rem;
  }
`;

export const TextStyles = styled.div`
  font-size: 1rem;
  font-weight: 25rem;
  line-height: 1.5rem;
`;

export const ExplanationStyles = styled.div`
  font-size: 0.75rem;
  font-weight: 25rem;
  line-height: 1rem;
  letter-spacing: 0.008rem;
  text-align: center;
`;

export const RepresentativeRedirectCard = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 5.75rem;
  border-radius: 6.25rem;
  background-color: ${({ theme }) => theme.colors.base.white.functional};
  margin-bottom: 1rem;

  @media screen and (max-width: 430px) {
    padding: 0 1rem;
  }
`;

export const BottomContainer = styled.div`
  flex-direction: column;
  margin-bottom: 0 auto;
  width: 100%;
`;

export const StyledButton = styled(Button)`
  width: 100%;
`;

export const TextWrapper = styled.div`
  height: 3.75rem;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
`;
