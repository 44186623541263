import { colors } from '@single-platform/components/dist/design/designVariables';
import { Button } from '@single-platform/button';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  height: 100%;
  width: 100%;
  gap: 1rem;
  max-width: 540px;
`;

export const PageSubtitle = styled.h2`
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: ${() => colors.base.black.shades[60]};
  margin-bottom: 0;
  text-align: center;
`;

export const StepperContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 1rem;
`;

export const StepperCard = styled.div`
  background-color: ${({ isActive }) => (isActive ? '#ffffff' : '#F9F9FA')};
  border-radius: 24px;
  padding: 1rem 1.5rem 1rem 1rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const RepresentativeContent = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 1rem;
`;

export const RepresentativeLogo = styled.div`
  border-radius: 50%;
  background-color: ${({ isActive }) => (isActive ? 'rgba(113, 84, 214, 0.1)' : '#F4F4F5')};
  padding: 0.75rem;
  width: 48px;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    z-index: 100;
    opacity: ${({ isActive }) => (isActive ? 1 : 0.5)};
  }
`;

const StatusChip = styled.div`
  border-radius: 40px;
  padding: 2px 12px;
  font-size: 12px;
`;

export const StatusChipSuccess = styled(StatusChip)`
  background-color: #eaf9eb;
  color: #1e731f;
`;

export const StatusChipPending = styled(StatusChip)`
  background-color: #eaeffe;
  color: #2b64f5;
`;

export const RepresentativeTitle = styled.h4`
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  margin: 0;
  flex: 1;
  color: ${({ isActive }) => (isActive ? '#000000' : '#808080')};
`;

export const ButtonStyles = styled(Button)`
  width: 100%;
  border-radius: 30px;
`;

export const LoadingContainer = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 10000;
  top: 0;
  background-color: #f4f4f5;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 2.5rem;
`;

export const BackButtonStyle = styled.div`
  position: absolute;
  top: 0.75rem;
  right: 0.75rem;
  button {
    background-color: #e6e6e6;
    color: black;
    border: none;
    width: 40px;
    padding: 0;

    &:hover {
      background-color: #d9d9d9;
      color: black;
      border: none;
    }
  }
`;

export const LoaderStyles = styled.div`
  & > div:first-child {
    top: unset;
    bottom: unset;
    left: unset;
    right: unset;
    background-color: #f4f4f5;
    position: relative;
  }
  & > div > div {
    margin-left: 0rem;
  }
  & > div > div > div {
    background-color: ${({ theme }) => theme.colors.base.primary.shades[100]};
  }
`;

export const RedirectHeader = styled.h3`
  font-weight: 500;
  font-size: 18px;
`;

export const RedirectSubheader = styled.p`
  font-weight: 400;
  font-size: 14px;
  color: ${({ theme }) => theme.colors.base.black.tints[70]};
  margin: 0;
`;

export const RedirectContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;
  justify-content: center;
`;

export const RedirectLinkStyles = styled.p`
  margin: 0;
`;

export const AllCompletedText = styled.p`
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: ${({ theme }) => theme.colors.base.black.shades[50]};
  margin-bottom: 0;
  text-align: center;
`;
