import styled from 'styled-components';
import { ButtonSectionStyle } from '../../LegalEntityQuestionnaire/components/buttonSection/buttonSection.styles';

export const ButtonSectionWrapperStyle = styled(ButtonSectionStyle)`
  flex: 1;
  position: fixed;
  bottom: 0;
  left: 0;
  background-color: #f4f4f5;
  flex-direction: column;
  width: 100%;
  z-index: 1000;
  border-top: ${(props) => (props.isAtBottom ? 'none' : '1px solid #e0e0e0')};
  height: auto;

  div {
    max-width: 540px;
    width: 100%;
    margin: 0 auto;
    padding: 1rem 0;
    gap: 1.5rem;

    button:last-child {
      flex: 1;
    }

    &:not(:only-child):not(:last-child) {
      padding: 0;
      padding-top: 1rem;
      margin-bottom: 0.5rem;
    }

    @media screen and (max-width: 768px) {
      padding: 1rem;
      height: auto;
      gap: 0.5rem;
      justify-content: flex-start;
      button:last-child {
        flex: 1;
      }
    }

    @media screen and (max-width: 470px) {
      button:last-child {
        flex: unset;
      }
    }
  }
`;

export const ChipOptionsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;

  label {
    flex: 1;
  }
`;

export const EmphasizedBrandColorText = styled('span')`
  color: ${(props) => (props.isBank ? '#7154D6' : '#2B64F5')};
`;
