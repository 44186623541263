import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { AuthActions } from '../../../../Redux';
import { Loader } from '@single-platform/components';
import { allLoginStatus, history } from '../../../../Config';
import { Logout } from '../../../../Utilities';
import { OtpForm } from '../otp/otpForm';
import { UnauthorizedCompanyModal } from '../../../../Components/Signing/UnauthorizedCompanyModal';
import { storageHandler } from '../../../SSOLoginContainer/storageHandler';

const getLoginMethod = (country) => {
  switch (country) {
    case 'NL':
      return 'idin-login';
    case 'FI':
      return 'ftn-login';
    case 'LV':
      return 'lv-eparaksts-mobile-login';
    default:
      return '';
  }
};

export const EIDeasy = ({ personalData, setSubmitStatus, step }) => {
  const dispatch = useDispatch();
  const [, setIsRedirecting] = useState(false);
  const [requiresOtp, setRequiresOtp] = useState(false);
  const [redirectUrl, setRedirectUrl] = useState('');
  const [isUnauthorizedCompany, setIsUnauthorizedCompany] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const loginMethod = getLoginMethod(personalData.country);

  const initiateRedirect = async () => {
    try {
      const data = {
        language: '',
        email: personalData.email,
        country: personalData.country,
        loginMethod: loginMethod,
        personalCode: '',
        phoneNumber: ''
      };

      setIsRedirecting(true);

      const result = await dispatch(AuthActions.eideasyInit(data));
      if (result.payload.redirectUrl) {
        setRedirectUrl(result.payload.redirectUrl);
        localStorage.setItem(
          'eidEasy',
          JSON.stringify({
            sessionId: result.payload.sessionId,
            accessToken: result.payload.token,
            step: 'eideasy'
          })
        );
        sessionStorage.setItem('loginSessionId', result.payload.sessionId);
      }
    } catch {
      setIsRedirecting(false);
      setRedirectUrl('');
      onFailHandle();
    }
  };

  const initiateLogin = async () => {
    setIsLoading(true);
    try {
      let redirectParams = JSON.parse(localStorage.getItem('redirectParams'));
      const mappedParams = {
        code: redirectParams.code,
        sessionId: redirectParams.state
      };
      const result = await dispatch(AuthActions.authorizationStatusEIdEasy(mappedParams));
      storageHandler.setSessionStorage(
        'token',
        JSON.stringify({
          accessToken: result.payload.token,
          expiresIn: result.payload.expiresIn,
          refreshToken: result.payload.refreshToken
        })
      );
      storageHandler.setSessionStorage('authType', 'eidEasy');

      const { sessionId } = JSON.parse(localStorage.getItem('eidEasy'));
      sessionStorage.setItem('loginSessionId', sessionId);

      if (result?.payload?.emailOtpRequired) {
        setIsLoading(false);
        setRequiresOtp(true);
      } else if (result) {
        setIsLoading(false);
        await authorizedCompanies();
      }
    } catch (e) {
      setIsLoading(false);
      localStorage.removeItem('eidEasy');
      localStorage.removeItem('redirectParams');
      onFailHandle();
    } finally {
      setIsLoading(false);
      localStorage.removeItem('eidEasy');
      localStorage.removeItem('redirectParams');
    }
  };

  const onFailHandle = async () => {
    sessionStorage.clear();
    setIsUnauthorizedCompany(false);
    await Logout();
    setSubmitStatus({
      isSubmitting: false,
      type: null
    });
  };

  const authorizedCompanies = async () => {
    const result = await dispatch(AuthActions.getAuthorizedCompanies());
    if (result) {
      switch (result.payload.status) {
        case allLoginStatus.noAuthorizeCompany:
          setIsUnauthorizedCompany(true);
          break;
        case allLoginStatus.selectCompany:
          history.push('/registration');
          break;
        default:
          break;
      }
    } else this.resetAll();
  };

  useEffect(() => {
    step === 'eideasy' ? initiateLogin() : initiateRedirect();
  }, []);

  return (
    <>
      {isLoading && <Loader />}
      {redirectUrl ? <meta http-equiv="Refresh" content={`0; url='${redirectUrl}'`} /> : null}
      {isUnauthorizedCompany && <UnauthorizedCompanyModal onCancel={onFailHandle} />}
      {requiresOtp && <OtpForm otpRequest={authorizedCompanies}></OtpForm>}
    </>
  );
};
