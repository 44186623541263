export const KYCConstants = {
  POST_SEND_KYC_APPLICATION_REQUEST: 'POST_SEND_KYC_APPLICATION_REQUEST',
  POST_SEND_KYC_APPLICATION_SUCCESS: 'POST_SEND_KYC_APPLICATION_SUCCESS',
  POST_SEND_KYC_APPLICATION_ERROR: 'POST_SEND_KYC_APPLICATION_ERROR',

  POST_GET_LIST_KYC_REQUEST: 'POST_GET_LIST_KYC_REQUEST',
  POST_GET_LIST_KYC_SUCCESS: 'POST_GET_LIST_KYC_SUCCESS',
  POST_GET_LIST_KYC_ERROR: 'POST_GET_LIST_KYC_ERROR',

  POST_MODIFY_KYC_APPLICATION_REQUEST: 'POST_MODIFY_KYC_APPLICATION_REQUEST',
  POST_MODIFY_KYC_APPLICATION_SUCCESS: 'POST_MODIFY_KYC_APPLICATION_SUCCESS',
  POST_MODIFY_KYC_APPLICATION_ERROR: 'POST_MODIFY_KYC_APPLICATION_ERROR',

  POST_APPROVE_KYC_APPLICATION_REQUEST: 'POST_APPROVE_KYC_APPLICATION_REQUEST',
  POST_APPROVE_KYC_APPLICATION_SUCCESS: 'POST_APPROVE_KYC_APPLICATION_SUCCESS',
  POST_APPROVE_KYC_APPLICATION_ERROR: 'POST_APPROVE_KYC_APPLICATION_ERROR',

  POST_DELETE_KYC_APPLICATION_REQUEST: 'POST_DELETE_KYC_APPLICATION_REQUEST',
  POST_DELETE_KYC_APPLICATION_SUCCESS: 'POST_DELETE_KYC_APPLICATION_SUCCESS',
  POST_DELETE_KYC_APPLICATION_ERROR: 'POST_DELETE_KYC_APPLICATION_ERROR',

  CLEAR_KYC_APPLICATION: 'CLEAR_KYC_APPLICATION',

  SIGN_APPLICATION_REQUEST: 'SIGN_APPLICATION_REQUEST',
  SIGN_APPLICATION_SUCCESS: 'SIGN_APPLICATION_SUCCESS',
  SIGN_APPLICATION_ERROR: 'SIGN_APPLICATION_ERROR',

  START_KYC_APPLICATION_REQUEST: 'START_KYC_APPLICATION_REQUEST',
  START_KYC_APPLICATION_SUCCESS: 'START_KYC_APPLICATION_SUCCESS',
  START_KYC_APPLICATION_ERROR: 'START_KYC_APPLICATION_ERROR',

  CREATE_APPLICATION_REQUEST: 'CREATE_APPLICATION_REQUEST',
  CREATE_APPLICATION_SUCCESS: 'CREATE_APPLICATION_SUCCESS',
  CREATE_APPLICATION_ERROR: 'CREATE_APPLICATION_ERROR',

  UPLOAD_POWER_OF_ATTORNEY_REQUEST: 'UPLOAD_POWER_OF_ATTORNEY_REQUEST',
  UPLOAD_POWER_OF_ATTORNEY_SUCCESS: 'UPLOAD_POWER_OF_ATTORNEY_SUCCESS',
  UPLOAD_POWER_OF_ATTORNEY_ERROR: 'UPLOAD_POWER_OF_ATTORNEY_ERROR',

  UPLOAD_ORGANIZATIONAL_CHART_REQUEST: 'UPLOAD_ORGANIZATIONAL_CHART_REQUEST',
  UPLOAD_ORGANIZATIONAL_CHART_SUCCESS: 'UPLOAD_ORGANIZATIONAL_CHART_SUCCESS',
  UPLOAD_ORGANIZATIONAL_CHART_ERROR: 'UPLOAD_ORGANIZATIONAL_CHART_ERROR',

  UPLOAD_ARTICLES_OF_ASSOCIATION_REQUEST: 'UPLOAD_ARTICLES_OF_ASSOCIATION_REQUEST',
  UPLOAD_ARTICLES_OF_ASSOCIATION_SUCCESS: 'UPLOAD_ARTICLES_OF_ASSOCIATION_SUCCESS',
  UPLOAD_ARTICLES_OF_ASSOCIATION_ERROR: 'UPLOAD_ARTICLES_OF_ASSOCIATION_ERROR',

  UPLOAD_BENEFECIARY_DOCUMENTS_REQUEST: 'UPLOAD_BENEFECIARY_DOCUMENTS_REQUEST',
  UPLOAD_BENEFECIARY_DOCUMENTS_SUCCESS: 'UPLOAD_BENEFECIARY_DOCUMENTS_SUCCESS',
  UPLOAD_BENEFECIARY_DOCUMENTS_ERROR: 'UPLOAD_BENEFECIARY_DOCUMENTS_ERROR',

  UPLOAD_WOLFSBERG_QUESTIONNAIRE_REQUEST: 'UPLOAD_WOLFSBERG_QUESTIONNAIRE_REQUEST',
  UPLOAD_WOLFSBERG_QUESTIONNAIRE_SUCCESS: 'UPLOAD_WOLFSBERG_QUESTIONNAIRE_SUCCESS',
  UPLOAD_WOLFSBERG_QUESTIONNAIRE_ERROR: 'UPLOAD_WOLFSBERG_QUESTIONNAIRE_ERROR',

  SUBMIT_APPLICATION_REQUEST: 'SUBMIT_APPLICATION_REQUEST',
  SUBMIT_APPLICATION_SUCCESS: 'SUBMIT_APPLICATION_SUCCESS',
  SUBMIT_APPLICATION_ERROR: 'SUBMIT_APPLICATION_ERROR',

  START_APPLICATION_SIGNING_REQUEST: 'START_APPLICATION_SIGNING_REQUEST',
  START_APPLICATION_SIGNING_SUCCESS: 'START_APPLICATION_SIGNING_SUCCESS',
  START_APPLICATION_SIGNING_ERROR: 'START_APPLICATION_SIGNING_ERROR',

  CHECK_SIGNING_SCA_REQUEST: 'CHECK_SIGNING_SCA_REQUEST',
  CHECK_SIGNING_SCA_SUCCESS: 'CHECK_SIGNING_SCA_SUCCESS',
  CHECK_SIGNING_SCA_ERROR: 'CHECK_SIGNING_SCA_ERROR',

  CANCEL_SIGNING_REQUEST: 'CANCEL_SIGNING_REQUEST',
  CANCEL_SIGNING_SUCCESS: 'CANCEL_SIGNING_SUCCESS',
  CANCEL_SIGNING_ERROR: 'CANCEL_SIGNING_ERROR',

  CONTINUE_APPLICATION_REQUEST: 'CONTINUE_APPLICATION_REQUEST',
  CONTINUE_APPLICATION_SUCCESS: 'CONTINUE_APPLICATION_SUCCESS',
  CONTINUE_APPLICATION_ERROR: 'CONTINUE_APPLICATION_ERROR',

  CHECK_STATE_REQUEST: 'CHECK_STATE_REQUEST',
  CHECK_STATE_SUCCESS: 'CHECK_STATE_SUCCESS',
  CHECK_STATE_ERROR: 'CHECK_STATE_ERROR',

  START_AGREEMENT_SIGNING_REQUEST: 'START_AGREEMENT_SIGNING_REQUEST',
  START_AGREEMENT_SIGNING_SUCCESS: 'START_AGREEMENT_SIGNING_SUCCESS',
  START_AGREEMENT_SIGNING_ERROR: 'START_AGREEMENT_SIGNING_ERROR',

  UPLOAD_ADDITIONAL_DOCUMENTS_REQUEST: 'UPLOAD_ADDITIONAL_DOCUMENTS_REQUEST',
  UPLOAD_ADDITIONAL_DOCUMENTS_SUCCESS: 'UPLOAD_ADDITIONAL_DOCUMENTS_SUCCESS',
  UPLOAD_ADDITIONAL_DOCUMENTS_ERROR: 'UPLOAD_ADDITIONAL_DOCUMENTS_ERROR',

  START_ONDATO_BENEFICIARY_IDENTIFICATION_REQUEST:
    'START_ONDATO_BENEFICIARY_IDENTIFICATION_REQUEST',
  START_ONDATO_BENEFICIARY_IDENTIFICATION_SUCCESS:
    'START_ONDATO_BENEFICIARY_IDENTIFICATION_SUCCESS',
  START_ONDATO_BENEFICIARY_IDENTIFICATION_ERROR: 'START_ONDATO_BENEFICIARY_IDENTIFICATION_ERROR',

  UPLOAD_APPLICATION_DOCUMENT_REQUEST: 'UPLOAD_APPLICATION_DOCUMENT_REQUEST',
  UPLOAD_APPLICATION_DOCUMENT_SUCCESS: 'UPLOAD_APPLICATION_DOCUMENT_SUCCESS',
  UPLOAD_APPLICATION_DOCUMENT_ERROR: 'UPLOAD_APPLICATION_DOCUMENT_ERROR',

  DELETE_APPLICATION_DOCUMENT_FILE_REQUEST: 'DELETE_APPLICATION_DOCUMENT_FILE_REQUEST',
  DELETE_APPLICATION_DOCUMENT_FILE_SUCCESS: 'DELETE_APPLICATION_DOCUMENT_FILE_SUCCESS',
  DELETE_APPLICATION_DOCUMENT_FILE_ERROR: 'DELETE_APPLICATION_DOCUMENT_FILE_ERROR',

  START_APPLICATION_SIGNED_UPLOAD_REQUEST: 'START_APPLICATION_SIGNED_UPLOAD_REQUEST',
  START_APPLICATION_SIGNED_UPLOAD_SUCCESS: 'START_APPLICATION_SIGNED_UPLOAD_SUCCESS',
  START_APPLICATION_SIGNED_UPLOAD_ERROR: 'START_APPLICATION_SIGNED_UPLOAD_ERROR',

  UPLOAD_SIGNED_APPLICATION_REQUEST: 'UPLOAD_SIGNED_APPLICATION_REQUEST',
  UPLOAD_SIGNED_APPLICATION_SUCCESS: 'UPLOAD_SIGNED_APPLICATION_SUCCESS',
  UPLOAD_SIGNED_APPLICATION_ERROR: 'UPLOAD_SIGNED_APPLICATION_ERROR',

  CANCEL_UPLOAD_DOCUMENT_REQUEST: 'CANCEL_UPLOAD_DOCUMENT_REQUEST',
  CANCEL_UPLOAD_DOCUMENT_SUCCESS: 'CANCEL_UPLOAD_DOCUMENT_SUCCESS',
  CANCEL_UPLOAD_DOCUMENT_ERROR: 'CANCEL_UPLOAD_DOCUMENT_ERROR',

  UPLOAD_SIGNED_AGREEMENT_REQUEST: 'UPLOAD_SIGNED_AGREEMENT_REQUEST',
  UPLOAD_SIGNED_AGREEMENT_SUCCESS: 'UPLOAD_SIGNED_AGREEMENT_SUCCESS',
  UPLOAD_SIGNED_AGREEMENT_ERROR: 'UPLOAD_SIGNED_AGREEMENT_ERROR',

  RENDER_PDF_FROM_HTML_REQUEST: 'RENDER_PDF_FROM_HTML_REQUEST',
  RENDER_PDF_FROM_HTML_SUCCESS: 'RENDER_PDF_FROM_HTML_SUCCESS',
  RENDER_PDF_FROM_HTML_ERROR: 'RENDER_PDF_FROM_HTML_ERROR',

  UPLOAD_KYC_PDF_REQUEST: 'UPLOAD_KYC_PDF_REQUEST',
  UPLOAD_KYC_PDF_SUCCESS: 'UPLOAD_KYC_PDF_SUCCESS',
  UPLOAD_KYC_PDF_ERROR: 'UPLOAD_KYC_PDF_ERROR',

  GET_INSTITUTION_REQUEST: 'GET_INSTITUTION_REQUEST',
  GET_INSTITUTION_SUCCESS: 'GET_INSTITUTION_SUCCESS',
  GET_INSTITUTION_ERROR: 'GET_INSTITUTION_ERROR',

  GET_COMPANY_CLASSIFICATION_REQUEST: 'GET_COMPANY_CLASSIFICATION_REQUEST',
  GET_COMPANY_CLASSIFICATION_SUCCESS: 'GET_COMPANY_CLASSIFICATION_SUCCESS',
  GET_COMPANY_CLASSIFICATION_ERROR: 'GET_COMPANY_CLASSIFICATION_ERROR',

  RETURN_TO_USER_FILLING_APPLICATION: 'RETURN_TO_USER_FILLING_APPLICATION',

  GET_APPLICATION_DOCUMENT_REQUESTS_REQUEST: 'GET_APPLICATION_DOCUMENT_REQUESTS_REQUEST',
  GET_APPLICATION_DOCUMENT_REQUESTS_SUCCESS: 'GET_APPLICATION_DOCUMENT_REQUESTS_SUCCESS',
  GET_APPLICATION_DOCUMENT_REQUESTS_ERROR: 'GET_APPLICATION_DOCUMENT_REQUESTS_ERROR',

  GET_APPLICATION_DOCUMENT_REQUESTS_TO_SIGN_REQUEST:
    'GET_APPLICATION_DOCUMENT_REQUESTS_TO_SIGN_REQUEST',
  GET_APPLICATION_DOCUMENT_REQUESTS_TO_SIGN_SUCCESS:
    'GET_APPLICATION_DOCUMENT_REQUESTS_TO_SIGN_SUCCESS',
  GET_APPLICATION_DOCUMENT_REQUESTS_TO_SIGN_ERROR:
    'GET_APPLICATION_DOCUMENT_REQUESTS_TO_SIGN_ERROR',

  CONFIRM_APPLICATION_DOCUMENTS_UPLOAD_REQUEST: 'CONFIRM_APPLICATION_DOCUMENTS_UPLOAD_REQUEST',
  CONFIRM_APPLICATION_DOCUMENTS_UPLOAD_SUCCESS: 'CONFIRM_APPLICATION_DOCUMENTS_UPLOAD_SUCCESS',
  CONFIRM_APPLICATION_DOCUMENTS_UPLOAD_ERROR: 'CONFIRM_APPLICATION_DOCUMENTS_UPLOAD_ERROR',

  SIGN_STATUS_REQUEST: 'SIGN_STATUS_REQUEST',
  SIGN_STATUS_SUCCESS: 'SIGN_STATUS_SUCCESS',
  SIGN_STATUS_ERROR: 'SIGN_STATUS_ERROR',

  CREATE_FARM_APPLICATION_REQUEST: 'CREATE_FARM_APPLICATION_REQUEST',
  CREATE_FARM_APPLICATION_SUCCESS: 'CREATE_FARM_APPLICATION_SUCCESS',
  CREATE_FARM_APPLICATION_ERROR: 'CREATE_FARM_APPLICATION_ERROR'
};
