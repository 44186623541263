import { QuestionnaireServices } from './services';
import { QuestionnaireConstants } from './constants';
import { Logout } from '../../../Utilities';

export const QuestionnaireActions = {
  addQuestionnaireEntry,
  removeQuestionnaireEntry,
  getQuestionnaire,
  validateQuestionnairePage,
  saveQuestionnaireAnswers,
  updateRepeatingInformationCheckbox,
  selectQuestionnaireFlow,
  setFormKeyInEdit,
  allRepeatableGroupsCompleted,
  totalRepeatableGroups
};

function addQuestionnaireEntry(questionnaireId, repeatableGroupId, data) {
  return (dispatch) => {
    dispatch({ type: QuestionnaireConstants.ADD_QUESTIONNAIRE_ENTRY_REQUEST });
    return QuestionnaireServices.addQuestionnaireEntry(
      questionnaireId,
      repeatableGroupId,
      data
    ).then(
      (data) =>
        dispatch({ type: QuestionnaireConstants.ADD_QUESTIONNAIRE_ENTRY_SUCCESS, payload: data }),
      (error) => {
        dispatch({ type: QuestionnaireConstants.ADD_QUESTIONNAIRE_ENTRY_ERROR, error });
        throw error;
      }
    );
  };
}

function removeQuestionnaireEntry(questionnaireId, repeatableGroupId, groupId) {
  return (dispatch) => {
    dispatch({ type: QuestionnaireConstants.REMOVE_QUESTIONNAIRE_ENTRY_REQUEST });
    return QuestionnaireServices.removeQuestionnaireEntry(
      questionnaireId,
      repeatableGroupId,
      groupId
    ).then(
      (data) =>
        dispatch({
          type: QuestionnaireConstants.REMOVE_QUESTIONNAIRE_ENTRY_SUCCESS,
          payload: data
        }),
      (error) => {
        dispatch({ type: QuestionnaireConstants.REMOVE_QUESTIONNAIRE_ENTRY_ERROR, error });
        throw error;
      }
    );
  };
}

function getQuestionnaire(questionnaireId) {
  return (dispatch) => {
    dispatch({ type: QuestionnaireConstants.GET_QUESTIONNAIRE_REQUEST });
    return QuestionnaireServices.getQuestionnaire(questionnaireId).then(
      (data) => dispatch({ type: QuestionnaireConstants.GET_QUESTIONNAIRE_SUCCESS, payload: data }),
      (error) => {
        dispatch({ type: QuestionnaireConstants.GET_QUESTIONNAIRE_ERROR, error });
        Logout();
        throw error;
      }
    );
  };
}

function validateQuestionnairePage(questionnaireId, pageTitle) {
  return (dispatch) => {
    dispatch({ type: QuestionnaireConstants.GET_QUESTIONNAIRE_VALIDATE_PAGE_REQUEST });
    return QuestionnaireServices.validateQuestionnairePage(questionnaireId, pageTitle).then(
      (data) =>
        dispatch({
          type: QuestionnaireConstants.GET_QUESTIONNAIRE_VALIDATE_PAGE_SUCCESS,
          payload: data
        }),
      (error) => {
        dispatch({ type: QuestionnaireConstants.GET_QUESTIONNAIRE_VALIDATE_PAGE_ERROR, error });
        Logout();
        throw error;
      }
    );
  };
}

function saveQuestionnaireAnswers(questionnaireId, data) {
  return (dispatch) => {
    dispatch({ type: QuestionnaireConstants.SAVE_QUESTIONNAIRE_ANSWERS_REQUEST });
    return QuestionnaireServices.saveQuestionnaireAnswers(questionnaireId, data).then(
      (data) =>
        dispatch({
          type: QuestionnaireConstants.SAVE_QUESTIONNAIRE_ANSWERS_SUCCESS,
          payload: data
        }),
      (error) => {
        dispatch({ type: QuestionnaireConstants.SAVE_QUESTIONNAIRE_ANSWERS_ERROR, error });
        throw error;
      }
    );
  };
}

function updateRepeatingInformationCheckbox(questionnaireId, data) {
  return (dispatch) => {
    dispatch({ type: QuestionnaireConstants.UPDATE_REPEATING_INFO_CHECKBOX_REQUEST });
    return QuestionnaireServices.updateRepeatingInformationCheckbox(questionnaireId, data).then(
      (data) =>
        dispatch({
          type: QuestionnaireConstants.UPDATE_REPEATING_INFO_CHECKBOX_SUCCESS,
          payload: data
        }),
      (error) => {
        dispatch({ type: QuestionnaireConstants.UPDATE_REPEATING_INFO_CHECKBOX_ERROR, error });
        throw error;
      }
    );
  };
}

function selectQuestionnaireFlow(data) {
  return (dispatch) => {
    dispatch({ type: QuestionnaireConstants.SELECT_QUESTIONNAIRE_FLOW_REQUEST });
    return QuestionnaireServices.selectQuestionnaireFlow(data).then(
      (data) => {
        dispatch({
          type: QuestionnaireConstants.SELECT_QUESTIONNAIRE_FLOW_SUCCESS,
          payload: data.questionnaireId
        });
      },
      (error) => {
        dispatch({ type: QuestionnaireConstants.SELECT_QUESTIONNAIRE_FLOW_ERROR, error });
        throw error;
      }
    );
  };
}

function setFormKeyInEdit(editNumber) {
  return (dispatch) => {
    dispatch({ type: QuestionnaireConstants.SET_FORM_KEY_IN_EDIT, payload: editNumber });
  };
}

function allRepeatableGroupsCompleted(bool) {
  return (dispatch) => {
    dispatch({ type: QuestionnaireConstants.IS_ALL_REPEATABLE_GROUPS_COMPLETED, payload: bool });
  };
}

function totalRepeatableGroups(number) {
  return (dispatch) => {
    dispatch({ type: QuestionnaireConstants.TOTAL_REPEATABLE_GROUPS, payload: number });
  };
}
