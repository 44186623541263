import React, { memo, useEffect, useMemo, useRef, useState } from 'react';
import {
  AppConfigActions,
  AuthActions,
  KYCActions,
  selectGetAuthorizedCompaniesAuthorizations
} from '../../../../../Redux';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { CompanyContainer } from './CompanyContainer/CompanyContainer';
import {
  CompaniesContainerStyles,
  CompanyDisplayContainerStyles,
  CompanyDisplayContainerWrapper,
  TitleStyles,
  SubtitleStyles
} from './CompanyDisplayContainer.style';

import { NOTIFICATION_TYPES, Notification } from '../../../../../Components';
import { DataExchangeModal } from '../../DataExchange/DataExchange';
import { languageSelector } from '../../../../../Redux/Selectors/questionnaireFlowHandle';
import {
  ButtonSection,
  LoaderWithText
} from '../../../../ApplicationKYC/LegalEntityQuestionnaire/components';
import CreateEmptyCompanyButton from './CreateEmptyCompanyButton/CreateEmptyCompanyButton';
import { checkJsonProp } from '../../../../../Utilities';
import { TypeOfAccountPage } from '../../../TypeOfAccount/typeOfAccount';
import { FarmDetailsForm } from '../../../Farm/FarmDetailsForm';
import { WhoRepresentingFarm } from '../../../Farm/WhoRepresentingFarm';
import { useCompanyRefresh } from '../utils/useCompanyRefresh';
import { storageHandler } from '../../../../SSOLoginContainer/storageHandler';

export const steps = {
  select: 'Select',
  data_exchange: 'DataExchange',
  type_of_account: 'TypeOfAccount',
  register: 'Register',
  farm_details_form: 'FarmDetailsForm',
  who_representing_farm: 'WhoRepresentingFarm'
};

export const CompanyDisplayContainer = memo(({ clickBackHandler, handleCompanySelect }) => {
  const { t } = useTranslation();
  const [selectedCompanyIdentificationId, setSelectedCompanyIdentificationId] = useState('');
  const [data, setData] = useState('');
  const [farmDetailsData, setFarmDetailsData] = useState({});
  const [step, setStep] = useState(steps.select);
  const [company, setCompany] = useState('');
  const [buttonBack, setButtonBack] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isInitialLoad, setIsInitialLoad] = useState(false);
  const [companyExistsError, setCompanyExistsError] = useState('');
  const availableCompanies = useSelector(selectGetAuthorizedCompaniesAuthorizations);
  const traceId = useSelector((state) => state.OnboardingFlowReducer.traceId);
  const dispatch = useDispatch();
  const language = useSelector(languageSelector);
  const previousCompanyIdRef = useRef('');
  const { isSelectingCompany } = useSelector((state) => state?.AuthReducers);
  useCompanyRefresh(isSelectingCompany, setIsInitialLoad);

  const selectedCompany = useMemo(() => {
    return availableCompanies?.find(
      (company) => company.identificationId === selectedCompanyIdentificationId
    );
  }, [availableCompanies, selectedCompanyIdentificationId]);

  const isCompanyEmpty = useMemo(() => {
    return !(selectedCompany?.companyName && selectedCompany?.companyCode);
  }, [selectedCompany]);

  useEffect(() => {
    const fetchAuthorizedCompanies = async () => {
      const token = storageHandler.getSessionStorage('token');
      try {
        if (!availableCompanies && token) {
          await dispatch(AuthActions.getAuthorizedCompanies());
          setIsInitialLoad(false);
        }
      } catch (e) {
        Notification({
          type: NOTIFICATION_TYPES.ERROR,
          message: t('generic_error_message'),
          description: traceId
        });
        clickBackHandler();
      }
    };
    fetchAuthorizedCompanies();
  }, [isInitialLoad]);

  useEffect(() => {
    if (selectedCompany) {
      sessionStorage.setItem('selectedCompanyIdentificationId', selectedCompanyIdentificationId);
      if (previousCompanyIdRef.current !== selectedCompanyIdentificationId) {
        previousCompanyIdRef.current = selectedCompanyIdentificationId;
      }
    }
  }, [selectedCompany, selectedCompanyIdentificationId]);

  // skip the select page if single valid Ondato. Go to registration. TALE
  useEffect(() => {
    if (singleValidOndatoNoRegisteredCompany(availableCompanies)) {
      setStep(steps.register);
      setSelectedCompanyIdentificationId(availableCompanies[0].identificationId);
    } else if (isRegisteredCompany(availableCompanies)) {
      handleCompanySelect(availableCompanies[0]);
    } else {
      setStep(steps.select);
    }
  }, []);

  const sortedCompanies = useMemo(() => {
    return availableCompanies?.sort((a, b) => {
      if (!a.companyName || !a.companyCode) {
        return -1;
      }
      if (!b.companyName || !b.companyCode) {
        return 1;
      }
      return 0;
    });
  }, [availableCompanies]);

  const handleBackClick = () => {
    clickBackHandler();
  };

  const onChangeHandler = (companyIdentificationId) => {
    setSelectedCompanyIdentificationId(companyIdentificationId);
  };

  useEffect(() => {
    dispatch(AuthActions.getAuthorizedCompanies());
  }, [isInitialLoad]);

  const handleCompanySelectHandler = () => {
    handleCompanySelect(selectedCompany);
  };

  const handleCompanyRepresentativeHandler = () => {
    setStep(steps.type_of_account);
  };

  const createCompany = async (data) => {
    try {
      setIsLoading(true);
      const referalKey = localStorage.getItem('referalKey');
      const response = await dispatch(
        KYCActions.createApplication(data, selectedCompanyIdentificationId, referalKey)
      );
      await dispatch(
        AppConfigActions.updateApplicationSettings({
          applicationId: response.payload,
          language: language || 'lt-LT'
        })
      );
      handleCompanySelect(selectedCompany);
      setIsLoading(false);
    } catch (e) {
      const value = checkJsonProp(e, 'response.data.errorCode');
      if (value === 'error_codes_company_can_not_register') {
        setIsLoading(false);
        setStep(steps.register);
        setCompanyExistsError(value);
      } else {
        Notification({
          type: NOTIFICATION_TYPES.ERROR,
          message: t('generic_error_message'),
          description: traceId
        });
        clickBackHandler();
      }
    }
  };

  const handleCompanyRepresentativeRegistrationHandler = async (data) => {
    setData(data);
    setIsLoading(true);
    const dataExchangeCheck = await dispatch(
      AuthActions.dataExchangeCheck(data.companyCode, data.country)
    );
    const { agreeToSellDataToOtherCompany } = dataExchangeCheck.payload;
    if (agreeToSellDataToOtherCompany) {
      setStep(steps.data_exchange);
      setIsLoading(false);
    } else {
      setIsLoading(true);
      createCompany(data);
    }
  };

  const singleValidOndatoNoRegisteredCompany = (availableCompanies) => {
    if (!Array.isArray(availableCompanies) || availableCompanies.length !== 1) {
      return false;
    }
    const company = availableCompanies[0];
    return !company.companyName && !company.companyCode;
  };

  const isRegisteredCompany = (availableCompanies) => {
    if (!Array.isArray(availableCompanies) || availableCompanies.length !== 1) {
      return false;
    }
    const company = availableCompanies[0];
    return company.companyName && company.companyCode;
  };

  // skip the select page if single valid Ondato. Go to registration. TALE
  useEffect(() => {
    if (singleValidOndatoNoRegisteredCompany(availableCompanies)) {
      setStep('Register');
      setSelectedCompanyIdentificationId(availableCompanies[0].identificationId);
    } else if (isRegisteredCompany(availableCompanies)) {
      handleCompanySelect(availableCompanies[0]);
    } else {
      setStep('Select');
    }
  }, []);

  const onContinueHandler = () => {
    const newData = {
      ...data,
      FetchOtherCompanyDataConsent: true
    };
    createCompany(newData);
  };

  const onCancelHandler = () => {
    createCompany(data);
  };

  const handleStepChange = (step) => {
    setStep(step);
  };

  const handleModalSteps = (step) => {
    if (step === steps.select) {
      return (
        <CompanyDisplayContainerStyles>
          {(process.env.REACT_APP_ENV === 'TEST' || process.env.REACT_APP_ENV === 'STAGE') && (
            <CreateEmptyCompanyButton />
          )}
          <CompaniesContainerStyles>
            {sortedCompanies?.map((company) => (
              <CompanyContainer
                key={company.identificationId}
                company={company}
                onChangeHandler={onChangeHandler}
                isSelected={company.identificationId === selectedCompanyIdentificationId}
              />
            ))}
          </CompaniesContainerStyles>
          <ButtonSection
            alignEnd={true}
            firstButtonClick={handleBackClick}
            secondButtonClick={
              !selectedCompanyIdentificationId || !isCompanyEmpty
                ? () => handleCompanySelectHandler()
                : handleCompanyRepresentativeHandler
            }
            secondButtonPrimary={true}
            firstButtonText={
              step === steps.select ? t('repeatable_group.cancel_button') : t('back')
            }
            secondButtonText={!isCompanyEmpty ? t('login_button') : t('next_button')}
            secondButtonDisabled={
              (!selectedCompanyIdentificationId && step === steps.select) || isLoading
            }
          />
        </CompanyDisplayContainerStyles>
      );
    }
    if (step === steps.data_exchange) {
      return (
        <DataExchangeModal
          isOpen={true}
          onContinue={onContinueHandler}
          onCancel={onCancelHandler}
        />
      );
    }
    if (step === steps.type_of_account || step === steps.register) {
      return (
        <TypeOfAccountPage
          handleStepChange={handleStepChange}
          onSubmit={handleCompanyRepresentativeRegistrationHandler}
          isLoading={isLoading}
          companyExistsError={companyExistsError}
        />
      );
    }
    if (step === steps.farm_details_form) {
      return <FarmDetailsForm handleStepChange={handleStepChange} setData={setFarmDetailsData} />;
    }
    if (step === steps.who_representing_farm) {
      return (
        <WhoRepresentingFarm
          handleStepChange={handleStepChange}
          farmDetailsData={farmDetailsData}
          selectedCompanyIdentificationId={selectedCompanyIdentificationId}
          selectedCompany={selectedCompany}
          handleCompanySelectHandler={handleCompanySelectHandler}
        />
      );
    }
  };

  const getLabel = (step) => {
    if (step === steps.select && !isLoading) {
      return (
        <>
          <TitleStyles>{t('select_your_company')}</TitleStyles>
          <SubtitleStyles>{t('select_your_company_description')}</SubtitleStyles>
        </>
      );
    }
    return '';
  };

  useEffect(() => {
    window.addEventListener('load', () => {
      document.body.style.overflow = 'hidden';
    });
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, []);

  return (
    <CompanyDisplayContainerWrapper>
      {isLoading ? (
        <LoaderWithText small={true} text={t('loader_preparing_questionnaire_text')} />
      ) : (
        <>
          {getLabel(step)}
          <div>{handleModalSteps(step)}</div>
        </>
      )}
    </CompanyDisplayContainerWrapper>
  );
});
