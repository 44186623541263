import React, { useEffect, useMemo } from 'react';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { Input, Field, Loader, Dropdown } from '@single-platform/components';
import { useSelector } from 'react-redux';
import useWindowDimensions from '../../../../../LegalEntityQuestionnaire/Utils/window-size.service';
import { FormStyles } from '../../../../../LegalEntityQuestionnaire/screens/renderPage/renderPage.style';
import breakpointSizes from '../../../../../LegalEntityQuestionnaire/styled-theme-styles/global-style-variables.style';
import { useFarmerFormValidation } from '../../../../../LegalEntityQuestionnaire/validation/useFarmerFormValidation';
import { DropdownInput } from '../../../dropdownInput/dropdownInput';
import countries from '../../../../../../Auth/components/countriesDropdown/farmersCountryList.json';
import activityChoices from '../utils/activityChoices.json';

const initialValues = {
  name: null,
  country: null,
  mainActivityClass: null
};

export const CompaniesDrawer = ({ onSubmit, shouldValidate, setShouldValidate, shownListItem }) => {
  const { t } = useTranslation();
  const { width } = useWindowDimensions();

  const validationSchema = useMemo(
    () =>
      Yup.object({
        name: Yup.string().required(t('validation_required_field')).nullable(),
        country: Yup.string().required('validation_required_field').nullable(),
        mainActivityClass: Yup.string().required(t('validation_required_field')).nullable()
      }),
    [t]
  );

  const { validationHelpers, setTouchedOnAll, handleSubmit } = useFarmerFormValidation({
    initialValues,
    validationSchema
  });
  const { loading } = useSelector((state) => state.FarmersQuestionnaireReducer);

  const onHandleSubmit = async () => {
    await handleSubmit(
      async (data) => {
        await onSubmit?.({ ...data, isValid: true });
      },
      // on invalid case
      () => setTouchedOnAll()
    )();
  };

  useEffect(() => {
    if (shouldValidate) {
      onHandleSubmit();
      setShouldValidate(false);
    }
  }, [shouldValidate]);

  return (
    <FormStyles small={width < breakpointSizes.phone} key={JSON.stringify(shownListItem)}>
      {loading ? (
        <Loader />
      ) : (
        <>
          <Field
            Component={Input}
            name="name"
            value={shownListItem?.name || ''}
            placeholder={t('farmers_farmer_company_name')}
            validationHelpers={validationHelpers}
            required
            validateOnChange
          />
          <DropdownInput
            validationHelpers={validationHelpers}
            name="country"
            value={shownListItem?.country || ''}
            filterCountries={false}
            placeholder={t('farmers_farmer_country')}
            options={countries}
          />
          <Field
            Component={Dropdown}
            placeholder={t('farmers_farm_activity_nace')}
            options={activityChoices.map((option) => {
              return { ...option, label: t(option.label) };
            })}
            value={shownListItem?.mainActivityClass || ''}
            name="mainActivityClass"
            validationHelpers={validationHelpers}
            required
            validateOnChange
            validateOnBlur
          />
        </>
      )}
    </FormStyles>
  );
};
