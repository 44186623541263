import styled from 'styled-components';
import { Button } from '@single-platform/button';

export const CreateEmptyCompanyButtonStyles = styled(Button)`
  width: 33.75rem;
  margin-bottom: 1rem;

  @media screen and (max-width: 430px) {
    padding: 0 1rem;
    width: calc(100% - 2rem);
  }
`;
