import { colors } from '@single-platform/components/dist/design/designVariables';
import styled from 'styled-components';

export const FarmersQuestionnaireContainer = styled.div`
  max-width: 33.75rem;
  width: 100%;
  margin: 0 auto;
  margin-bottom: 2rem;
  @media ${({ theme }) => theme.media.maxPhone} {
    padding: 0 1rem;
  }
`;

export const PageHeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: ${({ noSubHeader }) => (noSubHeader ? '0.5rem' : '1.5rem')};
  &:not(:only-child) {
    gap: 0.5rem;
  }
`;

export const PopupContainer = styled.div`
  margin-bottom: 1rem;
`;

export const PageSubtitle = styled.h2`
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.375rem;
  color: ${() => colors.base.black.shades[60]};
  margin-bottom: 0;
`;

export const SuccessModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  align-items: center;
  justify-content: center;
`;

export const SuccessModalButtonContainer = styled.div`
  display: flex;
  gap: 1rem;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;

  button {
    width: 80%;
  }
`;

export const SuccessModalDescription = styled.p`
  text-align: center;
  font-size: 1.25rem;
  font-weight: 500;
`;

export const FarmersQuestionnaireHeader = styled.div`
  display: flex;
  flex-direction: column;
  &:not(:only-child) {
    gap: 0.5rem;
  }
`;

export const PageSecondaryTextStyles = styled.h2`
  font-weight: 500;
  font-size: 1rem;
`;
