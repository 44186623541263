import { QuestionnaireConstants } from './constants';

export const QuestionnaireReducer = function (
  state = { formKeyInEdit: 0, allRepeatableGroupsCompleted: true },
  action
) {
  switch (action.type) {
    case QuestionnaireConstants.ADD_QUESTIONNAIRE_ENTRY_REQUEST:
      return {
        ...state,
        loading: true
      };
    case QuestionnaireConstants.ADD_QUESTIONNAIRE_ENTRY_SUCCESS:
      return {
        ...state,
        loading: false,
        questionnaire: action.payload.questionnaire
      };
    case QuestionnaireConstants.ADD_QUESTIONNAIRE_ENTRY_ERROR:
      return {
        ...state,
        errors: action.error,
        loading: false
      };

    case QuestionnaireConstants.REMOVE_QUESTIONNAIRE_ENTRY_REQUEST:
      return {
        ...state,
        loading: true
      };
    case QuestionnaireConstants.REMOVE_QUESTIONNAIRE_ENTRY_SUCCESS:
      return {
        ...state,
        loading: false,
        questionnaire: action.payload
      };
    case QuestionnaireConstants.REMOVE_QUESTIONNAIRE_ENTRY_ERROR:
      return {
        ...state,
        errors: action.error,
        loading: false
      };

    case QuestionnaireConstants.GET_QUESTIONNAIRE_REQUEST:
      return {
        ...state,
        loading: true
      };
    case QuestionnaireConstants.GET_QUESTIONNAIRE_SUCCESS:
      return {
        ...state,
        loading: false,
        questionnaire: action.payload,
        questionnaireId: action.payload.id,
        choices: action.payload.choices,
        version: action.payload.version,
        logged: true
      };
    case QuestionnaireConstants.GET_QUESTIONNAIRE_ERROR:
      return {
        ...state,
        errors: action.error,
        loading: false
      };

    case QuestionnaireConstants.GET_QUESTIONNAIRE_VALIDATE_PAGE_REQUEST:
      return {
        ...state,
        loading: true
      };
    case QuestionnaireConstants.GET_QUESTIONNAIRE_VALIDATE_PAGE_SUCCESS:
      return {
        ...state,
        loading: false,
        logged: true
      };
    case QuestionnaireConstants.GET_QUESTIONNAIRE_VALIDATE_PAGE_ERROR:
      return {
        ...state,
        errors: action.error,
        loading: false
      };

    case QuestionnaireConstants.SAVE_QUESTIONNAIRE_ANSWERS_REQUEST:
      return {
        ...state,
        loading: true
      };
    case QuestionnaireConstants.SAVE_QUESTIONNAIRE_ANSWERS_SUCCESS:
      return {
        ...state,
        loading: false,
        questionnaire: action.payload
      };
    case QuestionnaireConstants.SAVE_QUESTIONNAIRE_ANSWERS_ERROR:
      return {
        ...state,
        loading: false,
        errors: action.error
      };

    case QuestionnaireConstants.UPDATE_REPEATING_INFO_CHECKBOX_REQUEST:
      return {
        ...state,
        loading: true
      };
    case QuestionnaireConstants.UPDATE_REPEATING_INFO_CHECKBOX_SUCCESS:
      return {
        ...state,
        loading: false,
        questionnaire: action.payload
      };
    case QuestionnaireConstants.UPDATE_REPEATING_INFO_CHECKBOX_ERROR:
      return {
        ...state,
        loading: false,
        errors: action.error
      };

    case QuestionnaireConstants.SELECT_QUESTIONNAIRE_FLOW_REQUEST:
      return {
        ...state
      };
    case QuestionnaireConstants.SELECT_QUESTIONNAIRE_FLOW_SUCCESS:
      return {
        ...state,
        questionnaireId: action.payload
      };
    case QuestionnaireConstants.SELECT_QUESTIONNAIRE_FLOW_ERROR:
      return {
        ...state,
        errors: action.error
      };

    case QuestionnaireConstants.RESET_QUESTIONNAIRE_STATE:
      return {
        questionnaireId: null,
        loading: false,
        questionnaire: null,
        logged: false
      };

    case QuestionnaireConstants.SET_FORM_KEY_IN_EDIT:
      return {
        ...state,
        formKeyInEdit: action.payload
      };

    case QuestionnaireConstants.GET_AML_QUESTIONNAIRE_REQUEST:
      return {
        ...state,
        loading: true,
        isAmlOfficer: false
      };
    case QuestionnaireConstants.GET_AML_QUESTIONNAIRE_SUCCESS:
      return {
        ...state,
        loading: false,
        questionnaire: action.payload,
        questionnaireId: action.payload.id,
        choices: action.payload.choices,
        version: action.payload.version,
        logged: true,
        isAmlOfficer: true,
        isEditMode: false
      };
    case QuestionnaireConstants.GET_AML_QUESTIONNAIRE_ERROR:
      return {
        ...state,
        errors: action.error,
        loading: false,
        isAmlOfficer: false
      };
    case QuestionnaireConstants.SET_EDIT_MODE:
      return {
        ...state,
        isEditMode: action.payload
      };
    case QuestionnaireConstants.IS_ALL_REPEATABLE_GROUPS_COMPLETED:
      return {
        ...state,
        allRepeatableGroupsCompleted: action.payload
      };
    case QuestionnaireConstants.TOTAL_REPEATABLE_GROUPS:
      return {
        ...state,
        totalRepeatableGroups: action.payload
      };

    default:
      return state;
  }
};
