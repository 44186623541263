import styled from 'styled-components';

export const RemoveButtonStyles = styled.button`
  border: none;
  background: transparent;
  color: rgb(227, 33, 67);
  margin-top: 1rem;
  text-align: right;
`;

export const TopRemoveButtonStyles = styled.button`
  border: none;
  background: transparent;
  color: rgb(227, 33, 67);
  margin-bottom: 1rem;
  text-align: right;
`;

export const AsideDrawerStyles = styled.div`
  aside:first-child {
    z-index: 7400;
    position: fixed !important;
    max-width: 27.5rem !important;
    width: 27.5rem;
    overflow: hidden !important;

    div[id='scrollable-content'] {
      width: 27.5rem !important;
    }

    @media (max-width: 1200px) {
      max-height: 100dvh;
      border-radius: unset;
      height: 100%;

      div[id='scrollable-content'] {
        max-height: 100dvh;
      }
    }

    @media (max-width: 540px) {
      width: 100% !important;
      max-width: 100% !important;
      max-height: calc(100vh - 1.5rem);
      border-top-right-radius: 1.5rem;
      border-top-left-radius: 1.5rem;
      height: auto;

      div[id='scrollable-content'] {
        width: 100% !important;
      }
    }
  }
`;

export const ContentContainer = styled.div`
  padding: 1.5rem;
  display: flex;
  flex-direction: column;
  margin-bottom: 4rem;
`;

export const AsideDrawerHeaderStyles = styled.div`
  position: sticky;
  top: 0;
  background-color: #fff;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 1rem;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  z-index: 10000;
  border-bottom: ${(props) => (props.isAtTop ? 'none' : '1px solid #e0e0e0')};

  div:first-child {
    width: unset;
    padding: 0;
    gap: unset;
  }

  div:last-child {
    margin: 0 auto;
  }
`;

export const RepeatableButtonSection = styled.div`
  border-top: ${(props) => (props.isAtBottom ? 'none' : '1px solid #e0e0e0')};
  padding: 1rem;
  position: fixed;
  width: 100%;
  left: 0;
  bottom: 0;
  margin-top: 1rem;
  background-color: #fff;
  z-index: 10000;
  button {
    width: 100%;
  }
`;
