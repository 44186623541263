import { KYCServices } from './services';
import { KYCConstants } from './constants';
import { requestWithToken } from '../..';
import { Logout } from '../../../Utilities';
import { OnboardingFlowHandlerActions } from '../OnboardingFlowHandler/actions';

export const KYCActions = {
  sendKYCApplication,
  getListKYC,
  modifyKYCApplication,
  approveKYCApplication,
  deleteKYCApplication,
  clearKYCApplication,
  getApplication,
  uploadPowerOfAttorney,
  uploadOrganizationalChart,
  uploadArticlesOfAssociation,
  uploadBeneficiaryDocuments,
  uploadWolfsbergQuestionnaire,
  submitApplication,
  startApplicationSigning,
  checkSigningSca,
  cancelSigning,
  continueApplication,
  checkState,
  startAgreementSigning,
  uploadAdditionalDocuments,
  startOndatoBeneficiaryIdentification,
  uploadApplicationDocument,
  deleteApplicationDocumentFile,
  startApplicationSignedUpload,
  uploadSignedApplication,
  cancelUploadDocument,
  uploadSignedAgreement,
  renderPdfFromHtml,
  uploadKycPdf,
  getCalculatedInstitution,
  getCompanyClassification,
  returnToUserFillingApplication,
  createApplication,
  signApplication,
  getApplicationDocumentRequests,
  getApplicationDocumentRequestsToSign,
  confirmApplicationDocumentsUpload,
  signStatus,
  createFarmApplication
};

function cancelUploadDocument() {
  return (dispatch) => {
    dispatch({ type: KYCConstants.CANCEL_UPLOAD_DOCUMENT_REQUEST });

    return KYCServices.cancelUploadDocument().then(
      (data) => dispatch({ type: KYCConstants.CANCEL_UPLOAD_DOCUMENT_SUCCESS, payload: data }),
      (error) => {
        dispatch({ type: KYCConstants.CANCEL_UPLOAD_DOCUMENT_ERROR, error });
        throw error;
      }
    );
  };
}

function sendKYCApplication(data) {
  return (dispatch) => {
    dispatch({ type: KYCConstants.POST_SEND_KYC_APPLICATION_REQUEST });

    return KYCServices.sendKYCApplication(data).then(
      (data) => dispatch({ type: KYCConstants.POST_SEND_KYC_APPLICATION_SUCCESS, payload: data }),
      (error) => dispatch({ type: KYCConstants.POST_SEND_KYC_APPLICATION_ERROR, error })
    );
  };
}

function getListKYC(data) {
  return (dispatch) => {
    dispatch({ type: KYCConstants.POST_GET_LIST_KYC_REQUEST });

    return KYCServices.getListKYC(data).then(
      (data) => dispatch({ type: KYCConstants.POST_GET_LIST_KYC_SUCCESS, payload: data }),
      (error) => dispatch({ type: KYCConstants.POST_GET_LIST_KYC_ERROR, error })
    );
  };
}

function modifyKYCApplication(data) {
  return (dispatch) => {
    dispatch({ type: KYCConstants.POST_MODIFY_KYC_APPLICATION_REQUEST });

    return KYCServices.modifyKYCApplication(data).then(
      (data) => dispatch({ type: KYCConstants.POST_MODIFY_KYC_APPLICATION_SUCCESS, payload: data }),
      (error) => dispatch({ type: KYCConstants.POST_MODIFY_KYC_APPLICATION_ERROR, error })
    );
  };
}

function approveKYCApplication(kycId) {
  return (dispatch) => {
    dispatch({ type: KYCConstants.POST_APPROVE_KYC_APPLICATION_REQUEST });

    return KYCServices.approveKYCApplication(kycId).then(
      (data) =>
        dispatch({ type: KYCConstants.POST_APPROVE_KYC_APPLICATION_SUCCESS, payload: data }),
      (error) => dispatch({ type: KYCConstants.POST_APPROVE_KYC_APPLICATION_ERROR, error })
    );
  };
}

function deleteKYCApplication(kycId) {
  return (dispatch) => {
    dispatch({ type: KYCConstants.POST_DELETE_KYC_APPLICATION_REQUEST });

    return KYCServices.deleteKYCApplication(kycId).then(
      (data) => dispatch({ type: KYCConstants.POST_DELETE_KYC_APPLICATION_SUCCESS, payload: data }),
      (error) => dispatch({ type: KYCConstants.POST_DELETE_KYC_APPLICATION_ERROR, error })
    );
  };
}

function clearKYCApplication() {
  return (dispatch) => {
    dispatch({ type: KYCConstants.CLEAR_KYC_APPLICATION });
  };
}

function getApplication() {
  return async (dispatch) => {
    try {
      dispatch({ type: KYCConstants.START_KYC_APPLICATION_REQUEST });
      return KYCServices.getApplication().then(
        (data) => {
          dispatch(OnboardingFlowHandlerActions.isLoggedIn());
          return dispatch({ type: KYCConstants.START_KYC_APPLICATION_SUCCESS, payload: data });
        },
        async (error) => {
          dispatch({ type: KYCConstants.START_KYC_APPLICATION_ERROR, error });
          throw error;
        }
      );
    } catch (e) {
      await Logout('error');
    }
  };
}

function createApplication(data, selectedCompany, referalKey) {
  return async (dispatch) => {
    const sessionToken = JSON.parse(sessionStorage.getItem('token')) || {};
    if (selectedCompany) {
      requestWithToken.defaults.headers['Authorization'] = `Bearer ${sessionToken.accessToken}`;
      requestWithToken.defaults.headers.common['X-IdentificationId'] = selectedCompany;
      dispatch({ type: KYCConstants.CREATE_APPLICATION_REQUEST });
    } else {
      await Logout();
    }
    const newData = { ...data, referalKey };
    return KYCServices.createApplication(newData).then(
      (data) => {
        return dispatch({ type: KYCConstants.CREATE_APPLICATION_SUCCESS, payload: data });
      },
      async (error) => {
        if (error.toString().includes('401')) {
          await Logout();
        }
        dispatch({ type: KYCConstants.CREATE_APPLICATION_ERROR, error });
        throw error;
      }
    );
  };
}

function createFarmApplication(farmData, selectedFarmIdentification) {
  return async (dispatch) => {
    dispatch({ type: KYCConstants.CREATE_FARM_APPLICATION_REQUEST });

    return KYCServices.createFarmApplication(farmData, selectedFarmIdentification).then(
      (farmData) =>
        dispatch({ type: KYCConstants.CREATE_FARM_APPLICATION_SUCCESS, payload: farmData }),
      (error) => dispatch({ type: KYCConstants.CREATE_FARM_APPLICATION_ERROR, error })
    );
  };
}

function uploadPowerOfAttorney(data) {
  return (dispatch) => {
    dispatch({ type: KYCConstants.UPLOAD_POWER_OF_ATTORNEY_REQUEST });

    return KYCServices.uploadPowerOfAttorney(data).then(
      (data) => dispatch({ type: KYCConstants.UPLOAD_POWER_OF_ATTORNEY_SUCCESS, payload: data }),
      (error) => dispatch({ type: KYCConstants.UPLOAD_POWER_OF_ATTORNEY_ERROR, error })
    );
  };
}

function uploadOrganizationalChart(data) {
  return (dispatch) => {
    dispatch({ type: KYCConstants.UPLOAD_ORGANIZATIONAL_CHART_REQUEST });

    return KYCServices.uploadOrganizationalChart(data).then(
      (data) => dispatch({ type: KYCConstants.UPLOAD_ORGANIZATIONAL_CHART_SUCCESS, payload: data }),
      (error) => dispatch({ type: KYCConstants.UPLOAD_ORGANIZATIONAL_CHART_ERROR, error })
    );
  };
}

function uploadSignedApplication(data, documentId) {
  return (dispatch) => {
    dispatch({ type: KYCConstants.UPLOAD_SIGNED_APPLICATION_REQUEST });

    return KYCServices.uploadSignedApplication(data, documentId).then(
      (data) => dispatch({ type: KYCConstants.UPLOAD_SIGNED_APPLICATION_SUCCESS, payload: data }),
      (error) => dispatch({ type: KYCConstants.UPLOAD_SIGNED_APPLICATION_ERROR, error })
    );
  };
}

function uploadApplicationDocument(data, applicationId, documentRequestId) {
  return (dispatch) => {
    dispatch({ type: KYCConstants.UPLOAD_APPLICATION_DOCUMENT_REQUEST });

    return KYCServices.uploadApplicationDocument(data, applicationId, documentRequestId).then(
      (data) => dispatch({ type: KYCConstants.UPLOAD_APPLICATION_DOCUMENT_SUCCESS, payload: data }),
      (error) => dispatch({ type: KYCConstants.UPLOAD_APPLICATION_DOCUMENT_ERROR, error })
    );
  };
}

function deleteApplicationDocumentFile(applicationId, documentRequestId, fileId) {
  return (dispatch) => {
    dispatch({ type: KYCConstants.DELETE_APPLICATION_DOCUMENT_FILE_REQUEST });

    return KYCServices.deleteApplicationDocumentFile(applicationId, documentRequestId, fileId).then(
      (data) =>
        dispatch({ type: KYCConstants.DELETE_APPLICATION_DOCUMENT_FILE_SUCCESS, payload: data }),
      (error) => dispatch({ type: KYCConstants.DELETE_APPLICATION_DOCUMENT_FILE_ERROR, error })
    );
  };
}

function getCalculatedInstitution(legalForm, nace) {
  return (dispatch) => {
    dispatch({ type: KYCConstants.GET_INSTITUTION_REQUEST });

    return KYCServices.getInstitution(legalForm, nace).then(
      (data) => dispatch({ type: KYCConstants.GET_INSTITUTION_SUCCESS, payload: data }),
      (error) => dispatch({ type: KYCConstants.GET_INSTITUTION_ERROR, error })
    );
  };
}

function uploadSignedAgreement(data) {
  return (dispatch) => {
    dispatch({ type: KYCConstants.UPLOAD_SIGNED_AGREEMENT_REQUEST });

    return KYCServices.uploadSignedAgreement(data).then(
      (data) => dispatch({ type: KYCConstants.UPLOAD_SIGNED_AGREEMENT_SUCCESS, payload: data }),
      (error) => {
        dispatch({ type: KYCConstants.UPLOAD_SIGNED_AGREEMENT_ERROR, error });
        throw error;
      }
    );
  };
}

function getCompanyClassification(data) {
  return (dispatch) => {
    dispatch({ type: KYCConstants.GET_COMPANY_CLASSIFICATION_REQUEST });

    return KYCServices.getCompanyClassification(data).then(
      (data) => dispatch({ type: KYCConstants.GET_COMPANY_CLASSIFICATION_SUCCESS, payload: data }),
      (error) => {
        dispatch({ type: KYCConstants.GET_COMPANY_CLASSIFICATION_ERROR, error });
        throw error;
      }
    );
  };
}

function uploadArticlesOfAssociation(data) {
  return (dispatch) => {
    dispatch({ type: KYCConstants.UPLOAD_ARTICLES_OF_ASSOCIATION_REQUEST });

    return KYCServices.uploadArticlesOfAssociation(data).then(
      (data) =>
        dispatch({ type: KYCConstants.UPLOAD_ARTICLES_OF_ASSOCIATION_SUCCESS, payload: data }),
      (error) => dispatch({ type: KYCConstants.UPLOAD_ARTICLES_OF_ASSOCIATION_ERROR, error })
    );
  };
}

function uploadBeneficiaryDocuments(data) {
  return (dispatch) => {
    dispatch({ type: KYCConstants.UPLOAD_BENEFECIARY_DOCUMENTS_REQUEST });

    return KYCServices.uploadBeneficiaryDocuments(data).then(
      (data) =>
        dispatch({ type: KYCConstants.UPLOAD_BENEFECIARY_DOCUMENTS_SUCCESS, payload: data }),
      (error) => dispatch({ type: KYCConstants.UPLOAD_BENEFECIARY_DOCUMENTS_ERROR, error })
    );
  };
}

function uploadWolfsbergQuestionnaire(data) {
  return (dispatch) => {
    dispatch({ type: KYCConstants.UPLOAD_WOLFSBERG_QUESTIONNAIRE_REQUEST });

    return KYCServices.uploadWolfsbergQuestionnaire(data).then(
      (data) =>
        dispatch({ type: KYCConstants.UPLOAD_WOLFSBERG_QUESTIONNAIRE_SUCCESS, payload: data }),
      (error) => dispatch({ type: KYCConstants.UPLOAD_WOLFSBERG_QUESTIONNAIRE_ERROR, error })
    );
  };
}

function submitApplication(applicationId) {
  return (dispatch) => {
    dispatch({ type: KYCConstants.SUBMIT_APPLICATION_REQUEST });
    return KYCServices.submitApplication(applicationId).then(
      (data) => dispatch({ type: KYCConstants.SUBMIT_APPLICATION_SUCCESS, payload: data }),
      (error) => {
        dispatch({ type: KYCConstants.SUBMIT_APPLICATION_ERROR, error });
        throw error;
      }
    );
  };
}

function startApplicationSigning(authType) {
  return (dispatch) => {
    dispatch({ type: KYCConstants.START_APPLICATION_SIGNING_REQUEST });

    return KYCServices.startApplicationSigning(authType).then(
      (data) =>
        dispatch({ type: KYCConstants.START_APPLICATION_SIGNING_SUCCESS, payload: data, authType }),
      (error) => {
        dispatch({ type: KYCConstants.START_APPLICATION_SIGNING_ERROR, error });
        throw error;
      }
    );
  };
}

function startApplicationSignedUpload(data) {
  return (dispatch) => {
    dispatch({ type: KYCConstants.START_APPLICATION_SIGNED_UPLOAD_REQUEST });

    return KYCServices.startApplicationSignedUpload(data).then(
      (data) =>
        dispatch({ type: KYCConstants.START_APPLICATION_SIGNED_UPLOAD_SUCCESS, payload: data }),
      (error) => {
        dispatch({ type: KYCConstants.START_APPLICATION_SIGNED_UPLOAD_ERROR, error });
        throw error;
      }
    );
  };
}

function checkSigningSca(token, documentId) {
  return (dispatch) => {
    dispatch({ type: KYCConstants.CHECK_SIGNING_SCA_REQUEST });

    return KYCServices.checkSigningSca(token, documentId).then(
      (data) => dispatch({ type: KYCConstants.CHECK_SIGNING_SCA_SUCCESS, payload: data }),
      (error) => {
        dispatch({ type: KYCConstants.CHECK_SIGNING_SCA_ERROR, error });
        throw error;
      }
    );
  };
}

function cancelSigning(documentId) {
  return (dispatch) => {
    dispatch({ type: KYCConstants.CANCEL_SIGNING_REQUEST });

    return KYCServices.cancelSigning(documentId).then(
      (data) => dispatch({ type: KYCConstants.CANCEL_SIGNING_SUCCESS, payload: data }),
      (error) => dispatch({ type: KYCConstants.CANCEL_SIGNING_ERROR, error })
    );
  };
}

function continueApplication() {
  return (dispatch) => {
    dispatch({ type: KYCConstants.CONTINUE_APPLICATION_REQUEST });

    return KYCServices.continueApplication().then(
      (data) => dispatch({ type: KYCConstants.CONTINUE_APPLICATION_SUCCESS, payload: data }),
      (error) => dispatch({ type: KYCConstants.CONTINUE_APPLICATION_ERROR, error })
    );
  };
}

function checkState() {
  return (dispatch) => {
    dispatch({ type: KYCConstants.CHECK_STATE_REQUEST });

    return KYCServices.checkState().then(
      (data) => dispatch({ type: KYCConstants.CHECK_STATE_SUCCESS, payload: data }),
      (error) => dispatch({ type: KYCConstants.CHECK_STATE_ERROR, error })
    );
  };
}

function startAgreementSigning(authType) {
  return (dispatch) => {
    dispatch({ type: KYCConstants.START_AGREEMENT_SIGNING_REQUEST });

    return KYCServices.startAgreementSigning(authType).then(
      (data) =>
        dispatch({ type: KYCConstants.START_AGREEMENT_SIGNING_SUCCESS, payload: data, authType }),
      (error) => dispatch({ type: KYCConstants.START_AGREEMENT_SIGNING_ERROR, error })
    );
  };
}

function signApplication(applicationId, consent) {
  return (dispatch) => {
    dispatch({ type: KYCConstants.SIGN_APPLICATION_REQUEST });

    return KYCServices.signApplication(applicationId, consent).then(
      (data) => dispatch({ type: KYCConstants.SIGN_APPLICATION_SUCCESS, payload: data }),
      (error) => dispatch({ type: KYCConstants.SIGN_APPLICATION_ERROR, error })
    );
  };
}

function uploadAdditionalDocuments(data) {
  return (dispatch) => {
    dispatch({ type: KYCConstants.UPLOAD_ADDITIONAL_DOCUMENTS_REQUEST });

    return KYCServices.uploadAdditionalDocuments(data).then(
      (data) => dispatch({ type: KYCConstants.UPLOAD_ADDITIONAL_DOCUMENTS_SUCCESS, payload: data }),
      (error) => dispatch({ type: KYCConstants.UPLOAD_ADDITIONAL_DOCUMENTS_ERROR, error })
    );
  };
}

function startOndatoBeneficiaryIdentification() {
  return (dispatch) => {
    dispatch({ type: KYCConstants.START_ONDATO_BENEFICIARY_IDENTIFICATION_REQUEST });

    return KYCServices.startOndatoBeneficiaryIdentification().then(
      (data) =>
        dispatch({
          type: KYCConstants.START_ONDATO_BENEFICIARY_IDENTIFICATION_SUCCESS,
          payload: data
        }),
      (error) =>
        dispatch({ type: KYCConstants.START_ONDATO_BENEFICIARY_IDENTIFICATION_ERROR, error })
    );
  };
}

function renderPdfFromHtml(htmlStr) {
  return (dispatch) => {
    dispatch({ type: KYCConstants.RENDER_PDF_FROM_HTML_REQUEST });

    return KYCServices.renderPdfFromHtml(htmlStr).then(
      (data) => dispatch({ type: KYCConstants.RENDER_PDF_FROM_HTML_SUCCESS, payload: data }),
      (error) => dispatch({ type: KYCConstants.RENDER_PDF_FROM_HTML_ERROR, error })
    );
  };
}

function uploadKycPdf(filesForm) {
  return (dispatch) => {
    dispatch({ type: KYCConstants.UPLOAD_KYC_PDF_REQUEST });

    return KYCServices.uploadKycPdf(filesForm).then(
      (data) => dispatch({ type: KYCConstants.UPLOAD_KYC_PDF_SUCCESS, payload: data }),
      (error) => dispatch({ type: KYCConstants.UPLOAD_KYC_PDF_ERROR, error })
    );
  };
}

function returnToUserFillingApplication(applicationId) {
  return (dispatch) => {
    dispatch({ type: KYCConstants.RETURN_TO_USER_FILLING_APPLICATION });
    return KYCServices.returnToUserFillingApplication(applicationId);
  };
}

function getApplicationDocumentRequests(applicationId) {
  return (dispatch) => {
    dispatch({ type: KYCConstants.GET_APPLICATION_DOCUMENT_REQUESTS_REQUEST });

    return KYCServices.getApplicationDocumentRequests(applicationId).then(
      (data) =>
        dispatch({ type: KYCConstants.GET_APPLICATION_DOCUMENT_REQUESTS_SUCCESS, payload: data }),
      (error) => dispatch({ type: KYCConstants.GET_APPLICATION_DOCUMENT_REQUESTS_ERROR, error })
    );
  };
}

function getApplicationDocumentRequestsToSign(applicationId) {
  return (dispatch) => {
    dispatch({ type: KYCConstants.GET_APPLICATION_DOCUMENT_REQUESTS_TO_SIGN_REQUEST });

    return KYCServices.getApplicationDocumentRequestsToSign(applicationId).then(
      (data) =>
        dispatch({
          type: KYCConstants.GET_APPLICATION_DOCUMENT_REQUESTS_TO_SIGN_SUCCESS,
          payload: data
        }),
      (error) =>
        dispatch({ type: KYCConstants.GET_APPLICATION_DOCUMENT_REQUESTS_TO_SIGN_ERROR, error })
    );
  };
}

function confirmApplicationDocumentsUpload(applicationId) {
  return (dispatch) => {
    dispatch({ type: KYCConstants.CONFIRM_APPLICATION_DOCUMENTS_UPLOAD_REQUEST });

    return KYCServices.confirmApplicationDocumentsUpload(applicationId).then(
      (data) =>
        dispatch({
          type: KYCConstants.CONFIRM_APPLICATION_DOCUMENTS_UPLOAD_SUCCESS,
          payload: data
        }),
      (error) => dispatch({ type: KYCConstants.CONFIRM_APPLICATION_DOCUMENTS_UPLOAD_ERROR, error })
    );
  };
}

function signStatus(applicationId, documentId) {
  return (dispatch) => {
    dispatch({ type: KYCConstants.SIGN_STATUS_REQUEST });
    return KYCServices.signStatus(applicationId, documentId).then(
      (data) => dispatch({ type: KYCConstants.SIGN_STATUS_SUCCESS, payload: data }),
      (error) => {
        dispatch({ type: KYCConstants.SIGN_STATUS_ERROR, error });
        throw error;
      }
    );
  };
}
