import styled from 'styled-components';

export const MainWrapper = styled.div`
  margin: 0 auto;
  flex-direction: column;
  align-items: center;
  max-width: 33.75rem;
  min-width: 33.75rem;

  @media screen and (max-width: 430px) {
    width: 100%;
    min-width: 100%;
    padding: 0 1rem;
  }
`;

export const TitleStyles = styled.div`
  text-align: left;
  font-size: 1.5rem;
  font-weight: 37.5rem;
  line-height: 2rem;
  margin-bottom: 0.5rem;
  margin-top: 2rem;
`;
export const DescriptionStyles = styled.div`
  text-align: left;
  font-size: 0.875rem;
  font-weight: 25rem;
  line-height: 1.375rem;
  margin-bottom: 2rem;
  color: ${({ theme }) => theme.colors.base.black.tints[60]};
`;

export const BusinessRedirectCard = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 4.5rem;
  border-radius: 6.25rem;
  background-color: ${({ theme }) => theme.colors.base.white.functional};
  margin-bottom: 1rem;

  @media screen and (max-width: 430px) {
    padding: 0 1rem;
  }

  &:hover {
    cursor: pointer;
    border: 1px solid ${({ theme }) => theme.colors.base.black.tints[20]};
  }
`;

export const TextStyles = styled.div`
  font-size: 1rem;
  font-weight: 25rem;
  line-height: 1.5rem;
`;
export const FarmRedirectCard = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 4.5rem;
  border-radius: 6.25rem;
  background-color: ${({ theme }) => theme.colors.base.white.functional};
  margin-bottom: 1rem;

  @media screen and (max-width: 430px) {
    padding: 0 1rem;
  }

  &:hover {
    cursor: pointer;
    border: 1px solid ${({ theme }) => theme.colors.base.black.tints[20]};
  }
`;

export const ArrowStyles = styled.div`
  cursor: pointer;
  width: fit-content;
`;
