import { ErrorMessage } from '@single-platform/components';
import React from 'react';
import { CheckboxStyles } from './checkbox.style';

export const Checkbox = ({
  label,
  checked,
  name,
  validationHelpers,
  required,
  onChange,
  fieldMeta
}) => {
  const { isTouched, error, showValidationMessage, message } = fieldMeta || {};
  const hasValidationMessages = error?.message && isTouched;
  return (
    <>
      <CheckboxStyles
        name={name}
        label={label}
        onChange={onChange}
        required={required}
        checked={checked}
        validationHelpers={validationHelpers}
      />
      <ErrorMessage
        style={{ marginLeft: '1rem' }}
        hidden={!hasValidationMessages || !showValidationMessage}
      >
        {message}
      </ErrorMessage>
    </>
  );
};
