import { colors } from '@single-platform/components/dist/design/designVariables';
import styled from 'styled-components';

export const StepItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
  color: ${() => colors.base.black.shades[90]};
  padding-bottom: 1rem;
  font-weight: 400;
  border-radius: 0.75rem;
  cursor: pointer;

  &:last-child {
    padding-bottom: 0;
  }

  svg:last-child {
    path {
      fill: ${() => colors.base.black.shades[50]};
      transition: fill 0.3s ease-out;
    }
  }

  &:hover {
    span {
      font-weight: 500;
    }
    svg:last-child {
      path {
        fill: ${() => colors.base.black.shades[90]};
      }
    }
  }
`;

export const LogosContainer = styled.div`
  display: flex;
  gap: 1rem;
  justify-content: center;
  align-items: center;
`;
