import { formatLocalDate } from './formatLocalDate';

export const formatAndCleanPepData = (data) => {
  let modifiedData = { ...data };

  if (modifiedData.isPep === false) {
    modifiedData = {
      isPep: false // delete all other data
    };
  } else {
    if (modifiedData.isPersonPep === true) {
      if (modifiedData.endOfPepStatus) {
        modifiedData.endOfPepStatus = formatLocalDate(data.endOfPepStatus);
      }
    } else {
      modifiedData = clearSelfPepInformation(modifiedData);
    }

    if (modifiedData.isFamilyOrAssociatePep === true) {
      modifiedData = {
        ...modifiedData,
        familyOrAssociateBirthDate: formatLocalDate(data.familyOrAssociateBirthDate)
      };

      if (modifiedData.familyOrAssociateEndOfPepStatus) {
        modifiedData.familyOrAssociateEndOfPepStatus = formatLocalDate(
          data.familyOrAssociateEndOfPepStatus
        );
      }
    } else {
      modifiedData = clearFamilyOrAssociatePepInformation(modifiedData);
    }
  }

  return modifiedData;
};

export const clearSelfPepInformation = (data) => {
  const modifiedData = { ...data };

  modifiedData.personPepCountry = null;
  modifiedData.personInstitution = null;
  modifiedData.personPosition = null;
  modifiedData.endOfPepStatus = null;

  return modifiedData;
};

export const clearFamilyOrAssociatePepInformation = (data) => {
  const modifiedData = { ...data };

  modifiedData.familyOrAssociateName = null;
  modifiedData.familyOrAssociateSurname = null;
  modifiedData.familyOrAssociateBirthDate = null;
  modifiedData.familyOrAssociateCode = null;
  modifiedData.familyOrAssociateCountry = null;
  modifiedData.familyOrAssociateInstitution = null;
  modifiedData.familyOrAssociatePosition = null;
  modifiedData.familyOrAssociateEndOfPepStatus = null;

  return modifiedData;
};
