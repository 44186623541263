import React, { useMemo } from 'react';
import { useFarmerFormValidation } from '../../../../../LegalEntityQuestionnaire/validation/useFarmerFormValidation';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import breakpointSizes from '../../../../../LegalEntityQuestionnaire/styled-theme-styles/global-style-variables.style';
import useWindowDimensions from '../../../../../LegalEntityQuestionnaire/Utils/window-size.service';
import { Field, Toast, TextArea, Dropdown, Loader } from '@single-platform/components';
import { FarmersQuestionnaireHeader, PopupContainer } from '../../../../farmersQuestionnaire.style';
import {
  FormStyles,
  PageHeader
} from '../../../../../LegalEntityQuestionnaire/screens/renderPage/renderPage.style';
import { ButtonSection } from '../../../../../LegalEntityQuestionnaire/components';
import { useSelector } from 'react-redux';
import { LinkPopup } from '../../../../../LegalEntityQuestionnaire/components/linkPopup/linkPopup';
import activityChoices from './activityChoices.json';
import { history } from '../../../../../../../Config';
import { ButtonSectionWrapperStyle } from '../../../formStyles';

const defaultValues = {
  description: null,
  naceCode: null
};

const linkPopupGroup = {
  text: 'link_popup_activity_description_title',
  popupKey: 'link_popup_activity'
};

export const FarmActivityPage = ({ onSubmit, isAtBottom }) => {
  const { t } = useTranslation();
  const { width } = useWindowDimensions();
  const { loading, farmerInformation } = useSelector((state) => state.FarmersQuestionnaireReducer);

  const validationSchema = useMemo(
    () =>
      Yup.object({
        description: Yup.string().required(t('validation_required_field')).nullable(),
        naceCode: Yup.string().required(t('validation_required_field')).nullable()
      }),
    [t]
  );

  const initialValues = { ...defaultValues, ...farmerInformation?.farmSection?.farmActivityPage };

  const { validationHelpers, getValues, setTouchedOnAll, handleSubmit } = useFarmerFormValidation({
    initialValues,
    validationSchema
  });

  const onHandleSubmit = async (e) => {
    e.preventDefault();
    await handleSubmit(
      async (data) => {
        await onSubmit?.(data, 'farmActivityPage');
        history.push('/FarmersQuestionnaire?success=farmSection');
      },
      () => setTouchedOnAll()
    )();
  };

  if (loading) return <Loader />;

  const values = getValues();

  return (
    <FormStyles small={width < breakpointSizes.phone}>
      <FarmersQuestionnaireHeader noSubHeader>
        <PageHeader>{t('farmers_farm_activity_header')}</PageHeader>
      </FarmersQuestionnaireHeader>
      <PopupContainer>
        <LinkPopup key={JSON.stringify(linkPopupGroup)} group={linkPopupGroup} farmers />
      </PopupContainer>
      <Toast
        isVisible
        animate={false}
        variant="info"
        header={t('farmers_farm_activity_informational_header')}
      />
      <Field
        Component={TextArea}
        name="description"
        value={values.description}
        placeholder={t('farmers_farm_activity_description')}
        validationHelpers={validationHelpers}
        required
        validateOnChange
        autoHeight
        maxHeight={288}
        validateOnBlur
        maxLength={500}
        counter
      />
      <Field
        Component={Dropdown}
        placeholder={t('farmers_farm_activity_nace')}
        options={activityChoices.map((option) => ({
          ...option,
          label: t(option.label)
        }))}
        value={values.naceCode}
        name="naceCode"
        validationHelpers={validationHelpers}
        validateOnChange
        validateOnBlur
        required
      />
      <ButtonSectionWrapperStyle isAtBottom={isAtBottom}>
        <ButtonSection
          secondButtonClick={onHandleSubmit}
          secondButtonPrimary
          secondButtonText={t('button_save_and_continue')}
        />
      </ButtonSectionWrapperStyle>
    </FormStyles>
  );
};
