import React, { useEffect, useState, useSyncExternalStore } from 'react';
import { useDispatch } from 'react-redux';
import { AuthActions, OnboardingFlowHandlerActions } from '../../Redux';
import { LoaderWithText } from '../ApplicationKYC/LegalEntityQuestionnaire/components';
import { InitiatedSSOLoginContainer } from './SSOLoginContainer.styles';
import { PartContent, redirectToSmeGO } from '../../PageParts';
import { allowedOrigins } from '../IndexContainer/allowedOrigins';
import { storageHandler } from './storageHandler';
import { useTranslation } from 'react-i18next';
import { routerPath } from '../../Config';

function inIframe() {
  try {
    return window.self !== window.top;
  } catch (e) {
    return true;
  }
}

const store = {
  getSnapshot: () => storageHandler.getSessionStorage('smeGoSession'),
  subscribe: (listener) => {
    window.addEventListener('storage', listener);
    return () => void window.removeEventListener('storage', listener);
  }
};

function getCookiesMap() {
  return Object.fromEntries(new URLSearchParams(document.cookie.replace(/; /g, '&')));
}

export const SSOLoginContainer = () => {
  const isInIframe = inIframe();

  const [SSOData, setSSOData] = useState(null);
  const [isMobileApp, setIsMobileApp] = useState(false);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const setSingleFlowId = (flowId) => {
    window.hj?.('event', `onboarding_single_flow_id_${flowId}`);
  };

  if (!isInIframe) {
    const SSOData = storageHandler.getSessionStorage('token') || '';
    if (SSOData) {
      setSingleFlowId(SSOData.anonymousId);
    }
  }

  useEffect(() => {
    window.addEventListener(
      'message',
      (event) => {
        storageHandler.setSessionStorage('origin', event.origin);
        storageHandler.setSessionStorage('message', event.data.message);
        if (!allowedOrigins.includes(event.origin)) return;
        if (event.data.message === 'redirect') {
          const SSOData = event.data.data;

          storageHandler.setSessionStorage('smeGoSession', true);
          storageHandler.setSessionStorage('token', SSOData);
          storageHandler.setSessionStorage('logInfo', event.origin);

          if (event.data.source === 'mobileapp') {
            storageHandler.setSessionStorage('mobileapp', true);
            setIsMobileApp(true);
          }
          setSSOData(SSOData || '');
        }
      },
      false
    );
  }, []);

  const sessionStorageIntegrationHandler = useSyncExternalStore(store.subscribe, store.getSnapshot);

  useEffect(() => {
    (async () => {
      if (!SSOData) setSSOData(storageHandler.getSessionStorage('token'));
      if (SSOData && sessionStorageIntegrationHandler) {
        const kyc = SSOData?.kycInfo ?? JSON.parse(SSOData.redirect ?? '{}');

        if ((!isInIframe || isMobileApp) && kyc) {
          await dispatch(OnboardingFlowHandlerActions.handleOnboardingFlow(kyc));
        }
      }
    })();
  }, [isInIframe, isMobileApp, SSOData, sessionStorageIntegrationHandler]);

  useEffect(() => {
    if (!SSOData) {
      /**

       * Init sso session from cookie if Safari browser

       */

      const cookies = getCookiesMap();

      if (cookies) {
        if (cookies.redirect && cookies.token) {
          const payload = JSON.parse(cookies.redirect);

          storageHandler.setSessionStorage('smeGoSession', true);
          storageHandler.setSessionStorage('token', { ...payload, accessToken: cookies.token });

          setSSOData(cookies);
        } else {
          return;
        }
      } else {
        return;
      }

      const isSmeGoSession = storageHandler.getSessionStorage('smeGoSession');

      if (SSOData) {
        dispatch(AuthActions.initiatedSSOLogin());
      }

      if (!isSmeGoSession) {
        redirectToSmeGO();
      }
    }
  }, [SSOData]);

  return (
    <InitiatedSSOLoginContainer>
      <PartContent>
        <LoaderWithText text={t('sso_login_loader_text')}></LoaderWithText>
      </PartContent>
    </InitiatedSSOLoginContainer>
  );
};
