import { LinkButton } from '@single-platform/components';
import styled from 'styled-components';

export const ButtonContainer = styled.div`
  display: flex;
  gap: 1rem;
  justify-content: flex-end;
`;

export const LinkButtonStyles = styled(LinkButton)`
  color: black;
  text-decoration-line: underline;
  font-weight: 600;
  margin-bottom: 1.5rem;
`;

export const WhyUsTextContainer = styled.div`
  display: flex;
  margin-bottom: 1.5rem;
`;

export const BoldedText = styled.span`
  font-weight: 600;
`;

export const Container = styled.div`
  max-width: 33.75rem;
  margin: 0 auto;
`;
