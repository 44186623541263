import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FarmerQuestionnaireActions } from '../../../../../Redux';
import breakpointSizes from '../../../LegalEntityQuestionnaire/styled-theme-styles/global-style-variables.style';
import useWindowDimensions from '../../../LegalEntityQuestionnaire/Utils/window-size.service';
import {
  Container,
  QuestionnaireWrapper
} from '../../../LegalEntityQuestionnaire/screens/renderPage/renderPage.style';
import { Loader } from '@single-platform/components';
import { RefreshToken } from '../../../../../Utilities/refreshToken';
import { farmSubmitHandler } from '../../farmersQuestionnaire';
import { storageHandler } from '../../../../SSOLoginContainer/storageHandler';
import { FarmPartnerPage } from './pages/farmPartnerPage/farmPartnerPage';

const FarmerInitialStepOptions = [
  {
    label: 'farmPartnerPage',
    completionIndex: 0,
    previousActiveStep: 0,
    isCompleted: false,
    isActive: false
  }
];

export const FarmPartnerSection = ({ handleSuccessCallback }) => {
  const { width } = useWindowDimensions();
  const { farmerInformation, activeStep: prevActiveStep, loading } = useSelector(
    (state) => state.FarmersQuestionnaireReducer
  );
  const [isAtBottom, setIsAtBottom] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [steps, setSteps] = useState(FarmerInitialStepOptions);
  const questionnaireId = storageHandler.getSessionStorage('questionnaireId');
  const pageToShow = storageHandler.getSessionStorage('pageToShow');
  const dispatch = useDispatch();

  const handleHeight = () => {
    setIsAtBottom(window.innerHeight + window.scrollY >= document.body.offsetHeight - 20);
  };

  const markStepAsCompleted = (stepIndex) => {
    setSteps((prevSteps) =>
      prevSteps.map((step, index) => (index === stepIndex ? { ...step, isCompleted: true } : step))
    );
  };

  const handleStepChange = async () => {
    await dispatch(FarmerQuestionnaireActions.getFarmerInformation(questionnaireId));
    const nextStep = activeStep + 1;
    if (nextStep < steps.length) {
      markStepAsCompleted(activeStep);
      setActiveStep(nextStep);
    }
  };

  const handleStepBack = async (step) => {
    const previousStep = step - 1;
    if (previousStep >= 0) {
      setActiveStep(previousStep);
    }
  };

  const handleSubmit = async (data, page) => {
    farmerInformation.farmPartnersSection[page] = data;

    await farmSubmitHandler(
      farmerInformation,
      farmerInformation.farmPartnersSection,
      dispatch,
      questionnaireId,
      'farmPartnersSection'
    );
    handleStepChange();
  };

  useEffect(() => {
    handleHeight();
    dispatch(FarmerQuestionnaireActions.setFarmerQuestionnaireSession(true));
    dispatch(RefreshToken());
    if (!farmerInformation && questionnaireId)
      dispatch(FarmerQuestionnaireActions.getFarmerInformation(questionnaireId));
    dispatch(FarmerQuestionnaireActions.setStepBackQuestionnaireHandler(handleStepBack));
    document.addEventListener('scroll', handleHeight);
    return () => document.removeEventListener('scroll', handleHeight);
  }, []);

  useEffect(() => {
    if (questionnaireId) dispatch(FarmerQuestionnaireActions.getFarmerInformation(questionnaireId));
  }, [questionnaireId]);

  useEffect(() => {
    setSteps((prevSteps) => {
      const updatedSteps = prevSteps.map((step) => {
        if (farmerInformation?.farmClientsAndSupplierSection?.[step.label]?.isValid) {
          return { ...step, isCompleted: true };
        }
        return step;
      });

      if (updatedSteps.every((step) => step.isCompleted && !prevActiveStep)) {
        setActiveStep(0);
      } else {
        const firstIncompleteIndex = updatedSteps.findIndex((step) => !step.isCompleted);
        setActiveStep(firstIncompleteIndex === -1 ? prevActiveStep : firstIncompleteIndex);
      }

      if (pageToShow !== undefined && pageToShow !== null) {
        setActiveStep(pageToShow);
        storageHandler.setSessionStorage('pageToShow', null);
      }

      return updatedSteps;
    });
  }, [farmerInformation]);

  useEffect(() => {
    dispatch(FarmerQuestionnaireActions.setQuestionnaireActiveStep(activeStep));
    setSteps((prevSteps) => {
      const updatedSteps = prevSteps.map((step, index) => {
        return { ...step, isActive: index === activeStep, previousActiveStep: activeStep - 1 };
      });
      return updatedSteps;
    });
  }, [activeStep]);

  if (loading || !farmerInformation) {
    return <Loader />;
  }

  return (
    <Container
      small={width < breakpointSizes.phone}
      medium={width < breakpointSizes.smallDesktop}
      isLastPage={false}
    >
      <QuestionnaireWrapper
        small={width < breakpointSizes.phone}
        medium={width < breakpointSizes.smallDesktop}
      >
        <FarmPartnerPage
          onSubmit={handleSubmit}
          isAtBottom={isAtBottom}
          setActiveStep={setActiveStep}
        />
      </QuestionnaireWrapper>
    </Container>
  );
};
