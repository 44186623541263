import React, { useEffect, useState } from 'react';
import useWindowDimensions from '../../../LegalEntityQuestionnaire/Utils/window-size.service';
import breakpointSizes from '../../../LegalEntityQuestionnaire/styled-theme-styles/global-style-variables.style';
import { useTranslation } from 'react-i18next';
import { bankColors } from '../../../LegalEntityQuestionnaire/styled-theme-styles/styled-components.d';
import { colors } from '@single-platform/components/dist/design/designVariables';
import Select from 'react-select';
import { HintFieldContainer } from '../../../LegalEntityQuestionnaire/components/hintModal/hintModal.styles';
import { PlaceholderValue } from '../../../LegalEntityQuestionnaire/components/placeholderWrapper/placeholderWrapper';
import { ErrorMessage } from '@single-platform/components';

export const Multiselect = ({
  placeholder,
  fieldMeta,
  required,
  options,
  type,
  onBlur,
  onChange,
  disabled,
  name,
  value,
  valuesCallback
}) => {
  const [selectedValues, setSelectedValues] = useState(
    value.map((v) => options.find((option) => option.value === v))
  );
  const { isTouched, error, showValidationMessage, message } = fieldMeta || {};
  const { width } = useWindowDimensions();
  const isBank = process.env.REACT_APP_TRADEMARK.includes('Bank');
  const designColor = isBank ? bankColors : colors;

  const { t } = useTranslation();
  const hasValidationMessages = error?.message && isTouched;

  const customStyles = {
    valueContainer: (provided, state) => ({
      ...provided,
      paddingTop: state.hasValue ? '1rem' : '0',
      border: 'none',
      borderRadius: '0.5rem',
      fontSize: '14px',
      minHeight: '48px',
      borderColor: designColor.base.primary.shades[100]
    }),
    indicatorSeparator: (provided, state) => ({
      ...provided,
      backgroundColor: 'none'
    }),

    dropdownIndicator: (provided, state) => ({
      ...provided,
      color: designColor.base.black.shades[50],
      transform: !state.selectProps.menuIsOpen ? 'rotate(0deg)' : 'rotate(180deg)',
      borderColor: hasValidationMessages
        ? designColor.base.red.shades[120]
        : designColor.base.primary.shades[100],
      //marginRight: question.hint && '20px',
      zIndex: '1'
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
      padding: '0 8px'
    }),
    menu: (provided, state) => ({
      ...provided,
      zIndex: 100000,
      marginTop: '0px',
      borderRadius: '0.5rem',
      position: 'relative',
      borderColor: designColor.base.primary.shades[100]
    }),
    menuList: (provided, state) => ({
      ...provided,
      padding: '0px',
      borderRadius: '0.5rem',
      maxHeight: '150px',
      position: 'absolute',
      width: '100%',
      backgroundColor: '#fff',
      boxShadow: '0 0 0 1px hsla(0, 0%, 0%, 0.1), 0 4px 11px hsla(0, 0%, 0%, 0.1)'
    }),
    clearIndicator: (provided, state) => ({
      ...provided,
      display: 'none'
    }),
    control: (provided, state) => ({
      ...provided,
      borderColor: hasValidationMessages
        ? designColor.base.red.shades[120]
        : designColor.base.black.shades[10],
      borderRadius: '0.5rem',
      minWidth: '100px'
    }),
    placeholder: (provided, state) => ({
      ...provided,
      display: 'block',
      color: `${designColor.primary.inputPlaceholderColor}`,
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis'
    }),
    multiValue: (provided, state) => ({
      ...provided,
      marginTop: '5px',
      display: 'flex',
      alignItems: 'center',
      padding: '4px',
      borderRadius: '0.5rem',
      backgroundColor: designColor.base.black.shades[5]
    }),
    singleValue: (provided, state) => ({
      ...provided,
      marginTop: '5px',
      color: 'unset',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      maxWidth: '70vw'
    })
  };

  const onBlurHandler = (e) => {
    onBlur(e.value);
  };

  const onChangeHandler = (event) => {
    const value = event.map((value) => {
      return value.value;
    });
    setSelectedValues(event);
    onChange(value);
  };

  return (
    <HintFieldContainer>
      <PlaceholderValue
        hasValue={selectedValues.length > 0 || selectedValues.value}
        placeholder={placeholder}
        required={required}
      >
        <Select
          small={width < breakpointSizes.phone}
          options={options?.map((option) => ({ label: t(option.label), value: option.value }))}
          onBlur={onBlurHandler}
          isMulti={true}
          isFocused={false}
          name={name}
          value={selectedValues.map((option) => ({
            label: t(option.label),
            value: option.value
          }))}
          styles={customStyles}
          placeholder={t(placeholder)}
          onChange={onChangeHandler}
          noOptionsMessage={() => t('dropdown_no_option_message')}
          isDisabled={disabled}
        />
        <ErrorMessage
          style={{ paddingTop: '0.5rem', marginLeft: '1rem' }}
          hidden={!hasValidationMessages || !showValidationMessage}
        >
          {message}
        </ErrorMessage>
      </PlaceholderValue>
    </HintFieldContainer>
  );
};
