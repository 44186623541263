import React, { Fragment, useEffect, useState } from 'react';
import { ChipOption, ErrorMessage } from '@single-platform/components';
import {
  GroupItems,
  GroupsContainer
} from '../../../LegalEntityQuestionnaire/screens/renderGroup/renderGroup.style';
import { useTranslation } from 'react-i18next';
import { CheckMarkLogo, SmeBankCheckmark } from '../../../../../Assets/images/logos';

const CheckboxGroup = ({
  id,
  options,
  onChange,
  fieldMeta,
  name,
  value,
  validationHelpers,
  disabled,
  isInModal
}) => {
  const [allOptions, setAllOptions] = useState([]);
  const [isFirst, setIsFirst] = useState(true);
  const isBank = process.env.REACT_APP_TRADEMARK.includes('Bank');
  const { t } = useTranslation();
  const { isTouched, error, showValidationMessage, message } = fieldMeta || {};
  const hasValidationMessages = error?.message && isTouched;

  const onChangeHandler = (event, label) => {
    const updatedOptions = [...allOptions];
    const optionToUpdate = updatedOptions.find((value) => value.optionLabel === label);
    optionToUpdate.value = event.target.checked;

    setAllOptions(updatedOptions);

    onChange(
      updatedOptions
        .filter((option) => option.value && option.optionLabel)
        .map((option) => option.optionValue)
    );
  };

  useEffect(() => {
    if (isFirst) {
      setAllOptions(
        options?.map((option) => {
          return {
            value: value?.includes(option.value),
            optionLabel: option.label,
            optionValue: option.value
          };
        })
      );
      setIsFirst(false);
    }
  }, [allOptions]);

  return (
    <GroupsContainer isCheckboxGroup={false}>
      <GroupItems isGroup={false}>
        {allOptions.map((option) => (
          <Fragment key={option.optionLabel}>
            <ChipOption
              variant="checkbox"
              name={name}
              key={option.optionLabel}
              id={id}
              onChange={(e) => onChangeHandler(e, option.optionLabel)}
              checked={option.value}
              on={isInModal ? 'light' : 'dark'}
              validateOnChange
              validationHelpers={validationHelpers}
              disabled={disabled}
            >
              {option.value && (isBank ? <SmeBankCheckmark /> : <CheckMarkLogo />)}
              {t(option.optionLabel)}
            </ChipOption>
          </Fragment>
        ))}
        <ErrorMessage
          style={{ paddingTop: '0.5rem', marginLeft: '1rem' }}
          hidden={!hasValidationMessages || !showValidationMessage}
        >
          {message}
        </ErrorMessage>
      </GroupItems>
    </GroupsContainer>
  );
};

export default CheckboxGroup;
