import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FarmerQuestionnaireActions } from '../../../../../Redux';
import breakpointSizes from '../../../LegalEntityQuestionnaire/styled-theme-styles/global-style-variables.style';
import useWindowDimensions from '../../../LegalEntityQuestionnaire/Utils/window-size.service';
import {
  Container,
  QuestionnaireWrapper,
  StepperWrapper
} from '../../../LegalEntityQuestionnaire/screens/renderPage/renderPage.style';
import { Loader, Stepper } from '@single-platform/components';
import { RefreshToken } from '../../../../../Utilities/refreshToken';
import {
  RepresentativePage,
  RepresentativeAddressPage,
  RepresentativeDocumentPage,
  RepresentativeDualCitizenshipPage,
  RepresentativesPepPage
} from './pages';
import { farmSubmitHandler } from '../../farmersQuestionnaire';
import { storageHandler } from '../../../../SSOLoginContainer/storageHandler';

const FarmerInitialStepOptions = [
  {
    label: 'representativePage',
    completionIndex: 0,
    previousActiveStep: 0,
    isCompleted: false,
    isActive: false
  },
  {
    label: 'representativeAddressPage',
    completionIndex: 1,
    previousActiveStep: 0,
    isCompleted: false,
    isActive: false
  },
  {
    label: 'representativeDocumentPage',
    completionIndex: 2,
    previousActiveStep: 0,
    isCompleted: false,
    isActive: false
  },
  {
    label: 'representativeDualCitizenshipPage',
    completionIndex: 3,
    previousActiveStep: 0,
    isCompleted: false,
    isActive: false
  },
  {
    label: 'representativesPepPage',
    completionIndex: 4,
    previousActiveStep: 0,
    isCompleted: false,
    isActive: false
  }
];

export const FarmRepresentativeSection = ({ handleSuccessCallback }) => {
  const { width } = useWindowDimensions();
  const { farmerInformation, activeStep: prevActiveStep, loading } = useSelector(
    (state) => state.FarmersQuestionnaireReducer
  );
  const [isAtBottom, setIsAtBottom] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [steps, setSteps] = useState(FarmerInitialStepOptions);
  const questionnaireId = storageHandler.getSessionStorage('questionnaireId');
  const dispatch = useDispatch();

  const handleHeight = () => {
    setIsAtBottom(window.innerHeight + window.scrollY >= document.body.offsetHeight - 20);
  };

  const markStepAsCompleted = (stepIndex) => {
    setSteps((prevSteps) =>
      prevSteps.map((step, index) => (index === stepIndex ? { ...step, isCompleted: true } : step))
    );
  };

  const handleStepChange = async () => {
    const nextStep = activeStep + 1;
    if (nextStep < steps.length) {
      markStepAsCompleted(activeStep);
      setActiveStep(nextStep);
      await dispatch(FarmerQuestionnaireActions.getFarmerInformation(questionnaireId));
    }
  };

  const handleStepBack = async (step) => {
    const previousStep = step - 1;
    if (previousStep >= 0) {
      setActiveStep(previousStep);
    }
  };

  const handleSubmit = async (data, page) => {
    farmerInformation.farmRepresentativeSection[page] = data;
    await farmSubmitHandler(
      farmerInformation,
      farmerInformation.farmRepresentativeSection,
      dispatch,
      questionnaireId,
      'farmerRepresentativeSection'
    );
    handleStepChange();
  };

  useEffect(() => {
    handleHeight();
    dispatch(FarmerQuestionnaireActions.setFarmerQuestionnaireSession(true));
    dispatch(RefreshToken());
    if (!farmerInformation && questionnaireId)
      dispatch(FarmerQuestionnaireActions.getFarmerInformation(questionnaireId));
    dispatch(FarmerQuestionnaireActions.setStepBackQuestionnaireHandler(handleStepBack));
    document.addEventListener('scroll', handleHeight);
    return () => document.removeEventListener('scroll', handleHeight);
  }, []);

  useEffect(() => {
    if (questionnaireId) dispatch(FarmerQuestionnaireActions.getFarmerInformation(questionnaireId));
  }, [questionnaireId]);

  useEffect(() => {
    setSteps((prevSteps) => {
      const updatedSteps = prevSteps.map((step) => {
        if (farmerInformation?.farmRepresentativeSection?.[step.label]?.isValid) {
          return { ...step, isCompleted: true };
        }
        return step;
      });

      if (updatedSteps.every((step) => step.isCompleted && !prevActiveStep)) {
        setActiveStep(0);
      } else {
        const firstIncompleteIndex = updatedSteps.findIndex((step) => !step.isCompleted);
        setActiveStep(firstIncompleteIndex === -1 ? prevActiveStep : firstIncompleteIndex);
      }

      return updatedSteps;
    });
  }, [farmerInformation?.farmRepresentativeSection]);

  useEffect(() => {
    dispatch(FarmerQuestionnaireActions.setQuestionnaireActiveStep(activeStep));
    setSteps((prevSteps) => {
      const updatedSteps = prevSteps.map((step, index) => {
        return { ...step, isActive: index === activeStep, previousActiveStep: activeStep - 1 };
      });
      return updatedSteps;
    });
  }, [activeStep]);

  if (loading || !farmerInformation) {
    return <Loader />;
  }

  return (
    <Container
      small={width < breakpointSizes.phone}
      medium={width < breakpointSizes.smallDesktop}
      isLastPage={false}
    >
      <StepperWrapper>
        <Stepper switchToAlternateViewFrom={breakpointSizes.desktopBig} steps={steps} />
      </StepperWrapper>

      <QuestionnaireWrapper
        small={width < breakpointSizes.phone}
        medium={width < breakpointSizes.smallDesktop}
      >
        {activeStep === 0 && (
          <RepresentativePage
            onSubmit={handleSubmit}
            isAtBottom={isAtBottom}
            handleStepBack={handleStepBack}
          />
        )}
        {activeStep === 1 && (
          <RepresentativeAddressPage
            onSubmit={handleSubmit}
            isAtBottom={isAtBottom}
            handleStepBack={handleStepBack}
          />
        )}
        {activeStep === 2 && (
          <RepresentativeDocumentPage
            onSubmit={handleSubmit}
            isAtBottom={isAtBottom}
            handleStepBack={handleStepBack}
            handleSuccessCallback={handleSuccessCallback}
          />
        )}
        {activeStep === 3 && (
          <RepresentativeDualCitizenshipPage
            onSubmit={handleSubmit}
            isAtBottom={isAtBottom}
            handleStepBack={handleStepBack}
            handleSuccessCallback={handleSuccessCallback}
          />
        )}
        {activeStep === 4 && (
          <RepresentativesPepPage
            onSubmit={handleSubmit}
            isAtBottom={isAtBottom}
            handleStepBack={handleStepBack}
            handleSuccessCallback={handleSuccessCallback}
          />
        )}
      </QuestionnaireWrapper>
    </Container>
  );
};
