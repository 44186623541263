import React, { useMemo } from 'react';
import { useFarmerFormValidation } from '../../../../../LegalEntityQuestionnaire/validation/useFarmerFormValidation';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import breakpointSizes from '../../../../../LegalEntityQuestionnaire/styled-theme-styles/global-style-variables.style';
import useWindowDimensions from '../../../../../LegalEntityQuestionnaire/Utils/window-size.service';
import { Input, Field, Loader, Dropdown } from '@single-platform/components';
import { FarmersQuestionnaireHeader } from '../../../../farmersQuestionnaire.style';
import {
  FormStyles,
  PageHeader
} from '../../../../../LegalEntityQuestionnaire/screens/renderPage/renderPage.style';
import { ButtonSection } from '../../../../../LegalEntityQuestionnaire/components';
import { ButtonSectionWrapperStyle } from '../../../formStyles';
import { useSelector } from 'react-redux';
import countries from '../../../../../../Auth/components/countriesDropdown/farmersCountryList.json';
import { RadioChipOption } from '../../../../components/radioChipOption/radioChipOption';
import { Checkbox } from '../../../../components';
import { DropdownInput } from '../../../../components/dropdownInput/dropdownInput';
import { identityDocumentTypeOptions, yesNoChipOptions } from '../../../../constants';

const defaultValues = {
  hasDualCitizenship: null,
  type: null,
  documentNumber: null,
  issuingCountry: null,
  acquiredThroughInvestment: false
};

export const RepresentativeDualCitizenshipPage = ({ onSubmit, isAtBottom }) => {
  const { t } = useTranslation();
  const { width } = useWindowDimensions();
  const { loading, farmerInformation } = useSelector((state) => state.FarmersQuestionnaireReducer);

  const validationSchema = useMemo(
    () =>
      Yup.object({
        hasDualCitizenship: Yup.boolean().required(t('validation_required_field')).nullable(),
        type: Yup.string()
          .when('hasDualCitizenship', {
            is: true,
            then: Yup.string().required(t('validation_required_field'))
          })
          .nullable(),
        documentNumber: Yup.string()
          .when('hasDualCitizenship', {
            is: true,
            then: Yup.string().required(t('validation_required_field'))
          })
          .nullable(),
        issuingCountry: Yup.string()
          .when('hasDualCitizenship', {
            is: true,
            then: Yup.string().required(t('validation_required_field'))
          })
          .nullable(),
        acquiredThroughInvestment: Yup.bool()
      }),
    [t]
  );

  const existingValues =
    farmerInformation?.farmRepresentativeSection?.representativeDualCitizenshipPage;
  const initialValues = { ...defaultValues, ...existingValues };

  const { validationHelpers, getValues, setTouchedOnAll, handleSubmit } = useFarmerFormValidation({
    initialValues,
    validationSchema
  });

  const onHandleSubmit = async (e) => {
    e.preventDefault();
    await handleSubmit(
      async (data) => {
        await onSubmit?.(data, 'representativeDualCitizenshipPage');
      },
      () => setTouchedOnAll()
    )();
  };

  if (loading) return <Loader />;

  const values = getValues();

  return (
    <FormStyles small={width < breakpointSizes.phone}>
      <FarmersQuestionnaireHeader>
        <PageHeader>{t('farmers_representative_dual_citizenship_header')}</PageHeader>
      </FarmersQuestionnaireHeader>
      <Field
        Component={RadioChipOption}
        options={yesNoChipOptions}
        value={values.hasDualCitizenship}
        name="hasDualCitizenship"
        validationHelpers={validationHelpers}
        validateOnChange
      />
      {values.hasDualCitizenship && (
        <>
          <Field
            Component={Dropdown}
            validationHelpers={validationHelpers}
            name="type"
            value={values.type}
            placeholder={t('farmers_farmer_dual_citizenship_type')}
            options={identityDocumentTypeOptions}
            validateOnChange
          />
          <Field
            Component={Input}
            name="documentNumber"
            value={values.documentNumber}
            placeholder={t('farmers_farmer_dual_citizenship_documentNumber')}
            validationHelpers={validationHelpers}
            validateOnChange
            required
          />
          <DropdownInput
            validationHelpers={validationHelpers}
            name="issuingCountry"
            value={values.issuingCountry}
            placeholder={t('farmers_farmer_dual_citizenship_issuingCountry')}
            options={countries}
            validateOnChange
          />
          <Field
            Component={Checkbox}
            name="acquiredThroughInvestment"
            checked={values.acquiredThroughInvestment}
            value={values.acquiredThroughInvestment}
            validationHelpers={validationHelpers}
            label={t('farmers_farmer_dual_citizenship_acquiredThroughInvestment')}
            validateOnChange
          />
        </>
      )}
      <ButtonSectionWrapperStyle isAtBottom={isAtBottom}>
        <ButtonSection
          secondButtonClick={onHandleSubmit}
          secondButtonPrimary={true}
          secondButtonText={t('button_save_and_continue')}
        />
      </ButtonSectionWrapperStyle>
    </FormStyles>
  );
};
