import { Modal } from '@single-platform/components';
import { colors } from '@single-platform/components/dist/design/designVariables';
import styled from 'styled-components';

export const CompanyDisplayContainerStyles = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const TitleStyles = styled.div`
  text-align: center;
  font-size: 1.5rem;
  font-weight: 600;
  line-height: 1.75rem;
  margin-bottom: 8px;

  @media screen and (max-width: 430px) {
    padding: 0 1rem;
  }
`;

export const SubtitleStyles = styled.div`
  text-align: center;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.375rem;
  color: ${() => colors.base.black.shades[60]};
  margin-bottom: 10px;
`;

export const CompaniesContainerStyles = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  overflow-y: auto;
  width: 33.75rem;
  align-items: center;
  max-height: 34.5rem;
  overscroll-behavior: contain;

  @media screen and (max-width: 430px) {
    width: 100%;
    padding: 0 1rem;
    max-height: 23.5rem;
  }
`;

export const CompanyRepresentativeContainer = styled.div`
  max-height: auto;
`;

export const CompanyDisplayContainerWrapper = styled.div`
  overflow-y: auto;

  @media screen and (max-width: 1200px) {
    padding: 1.25rem;
  }
  @media screen and (max-width: 800px) {
    padding: 0;
  }
`;

export const ModalStyles = styled(Modal)`
  z-index: 8000;
`;
