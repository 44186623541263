import React, { useMemo } from 'react';
import { useFarmerFormValidation } from '../../../../../LegalEntityQuestionnaire/validation/useFarmerFormValidation';
import * as Yup from 'yup';
import { Trans, useTranslation } from 'react-i18next';
import breakpointSizes from '../../../../../LegalEntityQuestionnaire/styled-theme-styles/global-style-variables.style';
import useWindowDimensions from '../../../../../LegalEntityQuestionnaire/Utils/window-size.service';
import { Field, Input, Loader } from '@single-platform/components';
import { FarmersQuestionnaireHeader, PageSubtitle } from '../../../../farmersQuestionnaire.style';
import {
  FormStyles,
  PageHeader
} from '../../../../../LegalEntityQuestionnaire/screens/renderPage/renderPage.style';
import { ButtonSection } from '../../../../../LegalEntityQuestionnaire/components';
import { useSelector } from 'react-redux';
import { ButtonSectionWrapperStyle, EmphasizedBrandColorText } from '../../../formStyles';
import CheckboxGroup from '../../../../components/checkboxGroup/checkboxGroup';

const defaultValues = {
  fundOrigins: [],
  otherOrigins: null
};

const fundOriginOptions = [
  {
    label: 'choices_fund_origin_type_profit',
    value: 'ProfitOrIncome'
  },
  {
    label: 'choices_fund_origin_type_shareholder_capital',
    value: 'InvestorsMoney'
  },
  {
    label: 'choices_fund_origin_type_real_estate',
    value: 'ProfitAfterSellingAssets'
  },
  {
    label: 'choices_fund_origin_type_investment',
    value: 'Dividends'
  },
  {
    label: 'choices_fund_origin_type_subsidy',
    value: 'Subsidy'
  },
  {
    label: 'choices_fund_origin_type_borrowing',
    value: 'Loan'
  },
  {
    label: 'choices_fund_origin_type_other',
    value: 'Other'
  }
];

export const FarmFundOriginsPage = ({ onSubmit, isAtBottom }) => {
  const { t } = useTranslation();
  const { width } = useWindowDimensions();
  const { loading, farmerInformation } = useSelector((state) => state.FarmersQuestionnaireReducer);
  const isBank = process.env.REACT_APP_TRADEMARK.includes('Bank');

  const validationSchema = useMemo(
    () =>
      Yup.object({
        fundOrigins: Yup.array().of(Yup.string()).min(1, t('validation_must_be_one_of')).required(),
        otherOrigins: Yup.string().when('fundOrigins', {
          is: (fundOrigins) => fundOrigins.includes('Other'),
          then: Yup.string().nullable().required(t('validation_required_field')),
          otherwise: Yup.string().nullable().notRequired()
        })
      }),
    [t]
  );

  const existingValues = farmerInformation?.farmActivitiesSection?.farmFundOriginsPage;
  const initialValues = { ...defaultValues, ...existingValues };

  const { validationHelpers, getValues, setTouchedOnAll, handleSubmit } = useFarmerFormValidation({
    initialValues,
    validationSchema
  });

  const onHandleSubmit = async (e) => {
    e.preventDefault();
    await handleSubmit(
      async (data) => {
        if (!data.fundOrigins.includes('Other')) {
          data.otherOrigins = null;
        }

        await onSubmit?.(data, 'farmFundOriginsPage');
      },
      () => setTouchedOnAll()
    )();
  };

  if (loading) return <Loader />;

  const values = getValues();

  return (
    <FormStyles small={width < breakpointSizes.phone}>
      <FarmersQuestionnaireHeader noSubHeader>
        <PageHeader>
          <Trans
            t={t}
            components={{ coloredText: <EmphasizedBrandColorText isBank={isBank} /> }}
            i18nKey={'farmers_fund_origins_page_header'}
          />
        </PageHeader>
      </FarmersQuestionnaireHeader>

      <PageSubtitle>{t('header_intended_banking_services')}</PageSubtitle>

      <Field
        Component={CheckboxGroup}
        options={fundOriginOptions}
        value={values.fundOrigins}
        name="fundOrigins"
        validateOnChange
        validationHelpers={validationHelpers}
        isInModal
      />

      {values.fundOrigins?.includes('Other') && (
        <Field
          Component={Input}
          name="otherOrigins"
          value={values.otherOrigins}
          placeholder={t('farmers_other_fund_origin')}
          validationHelpers={validationHelpers}
          required
          validateOnChange
        />
      )}

      <ButtonSectionWrapperStyle isAtBottom={isAtBottom}>
        <ButtonSection
          secondButtonClick={onHandleSubmit}
          secondButtonPrimary
          secondButtonText={t('button_save_and_continue')}
        />
      </ButtonSectionWrapperStyle>
    </FormStyles>
  );
};
