import React, { useMemo } from 'react';
import * as Yup from 'yup';
import { RadioChipOption } from '../../../../components';
import { useFarmerFormValidation } from '../../../../../LegalEntityQuestionnaire/validation/useFarmerFormValidation';
import { useTranslation } from 'react-i18next';
import { Field, Input, Loader } from '@single-platform/components';
import { FarmersQuestionnaireHeader } from '../../../../farmersQuestionnaire.style';
import useWindowDimensions from '../../../../../LegalEntityQuestionnaire/Utils/window-size.service';
import breakpointSizes from '../../../../../LegalEntityQuestionnaire/styled-theme-styles/global-style-variables.style';
import {
  FormStyles,
  PageHeader
} from '../../../../../LegalEntityQuestionnaire/screens/renderPage/renderPage.style';
import { ButtonSectionWrapperStyle } from '../../../formStyles';
import { ButtonSection } from '../../../../../LegalEntityQuestionnaire/components';
import { useSelector } from 'react-redux';
import { yesNoChipOptions } from '../../../../constants';

const defaultValues = {
  hasTrademarkName: null,
  trademarkName: null
};

export const FarmTrademarkPage = ({ onSubmit, isAtBottom }) => {
  const { t } = useTranslation();
  const { width } = useWindowDimensions();
  const { loading, farmerInformation } = useSelector((state) => state.FarmersQuestionnaireReducer);

  const validationSchema = useMemo(
    () =>
      Yup.object({
        hasTrademarkName: Yup.boolean().required(t('validation_required_field')).nullable(),
        trademarkName: Yup.string().when('hasTrademarkName', {
          is: true,
          then: Yup.string().required(t('validation_required_field')).nullable(),
          otherwise: Yup.string().notRequired().nullable()
        })
      }),
    [t]
  );

  const existingValues = farmerInformation?.farmActivitiesSection?.farmTrademarkPage;
  const initialValues = { ...defaultValues, ...existingValues };

  const { validationHelpers, getValues, setTouchedOnAll, handleSubmit } = useFarmerFormValidation({
    initialValues,
    validationSchema
  });

  const onHandleSubmit = async (e) => {
    e.preventDefault();
    await handleSubmit(
      async (data) => {
        if (data.hasTrademarkName === false) {
          data.trademarkName = null;
        }
        await onSubmit?.(data, 'farmTrademarkPage');
      },
      () => setTouchedOnAll()
    )();
  };

  if (loading) return <Loader />;

  const values = getValues();

  return (
    <FormStyles small={width < breakpointSizes.phone}>
      <FarmersQuestionnaireHeader>
        <PageHeader>{t('farmers_trademark_page_header')}</PageHeader>
      </FarmersQuestionnaireHeader>
      <Field
        Component={RadioChipOption}
        value={values.hasTrademarkName}
        name="hasTrademarkName"
        options={yesNoChipOptions}
        validationHelpers={validationHelpers}
        validateOnChange
      />
      {values.hasTrademarkName && (
        <Field
          Component={Input}
          name="trademarkName"
          value={values.trademarkName}
          placeholder={t('farmers_trademark_name')}
          validationHelpers={validationHelpers}
          validateOnChange
          required
        />
      )}
      <ButtonSectionWrapperStyle isAtBottom={isAtBottom}>
        <ButtonSection
          secondButtonClick={onHandleSubmit}
          secondButtonPrimary
          secondButtonText={t('button_save_and_continue')}
        />
      </ButtonSectionWrapperStyle>
    </FormStyles>
  );
};
