import { useEffect, useRef } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import isEqual from 'lodash/isEqual';

export const ValidationHelpers = {
  setValue: () => {},
  setTouched: () => {},
  getFieldState: () => {}
};

const useFarmerFormValidation = ({ initialValues, validationSchema }) => {
  const valueSetOptions = { shouldValidate: true, shouldTouch: true, shouldDirty: true };
  const valueSetWithoutValidationOptions = {
    shouldValidate: false,
    shouldTouch: false,
    shouldDirty: false
  };

  const {
    trigger,
    setValue,
    getValues,
    register,
    handleSubmit,
    getFieldState,
    control,
    reset,
    formState: { errors, touchedFields: touched }
  } = useForm({
    defaultValues: initialValues,
    resolver: yupResolver(validationSchema)
  });

  const previousInitialValues = useRef(initialValues);

  useEffect(() => Object.keys(initialValues).forEach((key) => register(key)), [initialValues]);

  useEffect(() => {
    if (!isEqual(previousInitialValues.current, initialValues)) {
      previousInitialValues.current = initialValues;
      reset(initialValues);
    }
  }, [initialValues, reset]);

  const setValueWithValidation = (name, value) => setValue(name, value, valueSetOptions);
  const setValueWithoutValidation = (name, value) =>
    setValue(name, value, valueSetWithoutValidationOptions);

  const setTouched = (name) => setValueWithValidation(name, getValues(name));

  const setTouchedOnAll = () => Object.keys(initialValues).forEach((key) => setTouched(key));

  return {
    trigger,
    getValues,
    getFieldState,
    setTouchedOnAll,
    handleSubmit,
    control,
    errors,
    register,
    touched,
    validationHelpers: {
      setValue: setValueWithValidation,
      setValueWithoutValidation,
      setTouched,
      getFieldState
    }
  };
};

export { useFarmerFormValidation };
