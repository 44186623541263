import React, { useEffect, useState } from 'react';
import { FormListStyles } from '../../../../LegalEntityQuestionnaire/screens/renderRepeatableGroup/renderRepeatableGroup.style';
import { useTranslation } from 'react-i18next';
import { AsideMenuTopBar } from '@single-platform/components';
import {
  AsideDrawerHeaderStyles,
  ContentContainer,
  RepeatableButtonSection,
  TopRemoveButtonStyles
} from '../../../../LegalEntityQuestionnaire/components/repeatableDrawer/repeatableDrawer.style';
import { Button } from '@single-platform/button';
import { Arrow, TrashcanLogo } from '../../../../../../Assets/images/logos';
import person from '../../../../../../Assets/images/logos/person.svg';
import { StyledAside } from '../formList.style';
import { PartnerPage } from '../drawers/partnersDrawer/pages/partnerPage';
import { PartnerDocumentPage } from '../drawers/partnersDrawer/pages/partnerDocumentPage';
import { PartnerDualCitizenshipPage } from '../drawers/partnersDrawer/pages/partnerDualCitizenshipPage';
import { PartnerPepPage } from '../drawers/partnersDrawer/pages/partnerPepPage';

export const FarmPartnerFormList = ({ name, list, setList }) => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [shouldSubmit, setShouldSubmit] = useState(false);
  const [shownListItem, setShownListItem] = useState(null);
  const [shownListItemIndex, setShownListItemIndex] = useState(null);
  const [shownListItemPage, setShownListItemPage] = useState(null);
  const [drawerAction, setDrawerAction] = useState(null);
  const { t } = useTranslation();

  const handleRepeatableCardClick = (index) => {
    setShownListItemIndex(index);
    setShownListItem(list[index]);
    setDrawerAction('Edit');
    setIsDrawerOpen(true);
    setShownListItemPage(0);
  };

  const handleExtraActions = (e, index) => {
    e.preventDefault();
    handleRepeatableCardClick(index);
  };

  const addCardHandler = (e) => {
    e.preventDefault();
    setDrawerAction('Add');
    setIsDrawerOpen(true);
    setShownListItemPage(0);
    setShownListItem({});
  };

  const deleteItem = async () => {
    const updatedList = [...list];
    updatedList.splice(shownListItemIndex, 1);
    setList(updatedList);
    closeDrawer();
  };

  const onDrawerAdd = (data) => {
    const newList = [...list, { ...data, isValid: true }];
    setList(newList);
    closeDrawer();
  };

  const onDrawerEdit = (data) => {
    const newList = [...list];
    newList[shownListItemIndex] = { ...data, isValid: true };
    setList(newList);
    closeDrawer();
  };

  const closeDrawer = () => {
    setShownListItem(null);
    setShownListItemIndex(null);
    setShownListItemPage(null);
    setDrawerAction(null);
    setIsDrawerOpen(false);
  };

  const handleNextDrawerPage = (e) => {
    e.preventDefault();
    setShouldSubmit(true);
  };

  const handlePreviousDrawerPage = () => {
    if (shownListItemPage > 0) {
      setShownListItemPage(shownListItemPage - 1);
    } else {
      closeDrawer();
    }
  };

  const getCardList = () => {
    return list.map((item) => ({
      id: item.id,
      title: item.partnerPage?.name ? `${item.partnerPage.name} ${item.partnerPage.surname}` : '',
      subtitle: '',
      icon: person,
      completed: item.isValid
    }));
  };

  const onPageSubmit = (data, pageKey) => {
    const updatedItem = {
      ...shownListItem,
      [pageKey]: data
    };
    setShownListItem(updatedItem);
    setShouldSubmit(false);

    if (shownListItemPage + 1 < pages.length) {
      setShownListItemPage(shownListItemPage + 1);
    } else if (shownListItemPage + 1 === pages.length) {
      if (drawerAction === 'Add') onDrawerAdd(updatedItem);
      else onDrawerEdit(updatedItem);
    }
  };

  const pages = [
    {
      component: PartnerPage,
      pageKey: 'partnerPage'
    },
    {
      component: PartnerDocumentPage,
      pageKey: 'partnerDocumentPage'
    },
    {
      component: PartnerDualCitizenshipPage,
      pageKey: 'partnerDualCitizenshipPage'
    },
    {
      component: PartnerPepPage,
      pageKey: 'partnerPepPage'
    }
  ];

  let PageComponent = null;
  if (shownListItemPage !== null) {
    PageComponent = pages[shownListItemPage].component;
  }

  return (
    <div>
      <FormListStyles
        data-hj-suppress
        name={name}
        addButtonLabel={t('farmers_farm_add_partner_button')}
        onAddCardClick={(e) => addCardHandler(e)}
        onItemClick={(index) => handleRepeatableCardClick(index)}
        renderExtraActions={(item, index) =>
          item.completed ? (
            <Arrow onClick={() => handleRepeatableCardClick(index)} />
          ) : (
            <Button size="S" onClick={(e) => handleExtraActions(e, index)}>
              {t('repeatable_group.button_review')}
            </Button>
          )
        }
        list={getCardList()}
      />
      <StyledAside isOpen={isDrawerOpen} activeIndex={1}>
        <AsideDrawerHeaderStyles>
          <AsideMenuTopBar handleClose={handlePreviousDrawerPage} inner={shownListItemPage > 0} />
          <div>
            {drawerAction === 'Edit'
              ? shownListItem?.partnerPage?.name
              : t('repeatable_group.add_button')}
          </div>
        </AsideDrawerHeaderStyles>
        <ContentContainer>
          {list.length > 1 && shownListItemPage === 0 && drawerAction === 'Edit' && (
            <TopRemoveButtonStyles alignEnd={'true'} type="submit" onClick={() => deleteItem()}>
              {t('farmers_farm_repeatable_remove_button')}
              <TrashcanLogo />
            </TopRemoveButtonStyles>
          )}

          {PageComponent && (
            <PageComponent
              onSubmit={onPageSubmit}
              shouldSubmit={shouldSubmit}
              initialData={shownListItem?.[pages[shownListItemPage].pageKey]}
              onSubmitError={() => setShouldSubmit(false)}
            />
          )}

          <RepeatableButtonSection>
            <Button
              variant={shownListItemPage + 1 === pages.length ? 'Filled' : 'Stroked'}
              onClick={(e) => handleNextDrawerPage(e)}
            >
              {shownListItemPage + 1 < pages.length
                ? t('button_save_and_continue')
                : drawerAction === 'Edit'
                ? t('button_save')
                : t('farmers_farm_add_partner_button')}
            </Button>
          </RepeatableButtonSection>
        </ContentContainer>
      </StyledAside>
    </div>
  );
};
