import React, { useMemo } from 'react';
import { useFarmerFormValidation } from '../../../../../LegalEntityQuestionnaire/validation/useFarmerFormValidation';
import * as Yup from 'yup';
import { Trans, useTranslation } from 'react-i18next';
import breakpointSizes from '../../../../../LegalEntityQuestionnaire/styled-theme-styles/global-style-variables.style';
import useWindowDimensions from '../../../../../LegalEntityQuestionnaire/Utils/window-size.service';
import { Field, Loader } from '@single-platform/components';
import {
  FarmersQuestionnaireHeader,
  PageSecondaryTextStyles,
  PageSubtitle
} from '../../../../farmersQuestionnaire.style';
import {
  FormStyles,
  PageHeader
} from '../../../../../LegalEntityQuestionnaire/screens/renderPage/renderPage.style';
import { ButtonSection } from '../../../../../LegalEntityQuestionnaire/components';
import { useSelector } from 'react-redux';
import { ButtonSectionWrapperStyle, EmphasizedBrandColorText } from '../../../formStyles';
import { RadioChipOption } from '../../../../components';
import { numberOfPaymentsOptions, paymentAmountOptions } from '../../../../constants';

const defaultValues = {
  outgoingPayments: null,
  numberOfPayments: null
};

export const FarmMonthlyOutgoingPaymentPage = ({ onSubmit, isAtBottom }) => {
  const { t } = useTranslation();
  const { width } = useWindowDimensions();
  const { loading, farmerInformation } = useSelector((state) => state.FarmersQuestionnaireReducer);
  const isBank = process.env.REACT_APP_TRADEMARK.includes('Bank');

  const validationSchema = useMemo(
    () =>
      Yup.object({
        outgoingPayments: Yup.string().required(t('validation_must_be_one_of')).nullable(),
        numberOfPayments: Yup.string().required(t('validation_must_be_one_of')).nullable()
      }),
    [t]
  );

  const existingValues =
    farmerInformation?.farmPaymentsAndTransactionsSection?.farmMonthlyOutgoingPaymentPage;
  const initialValues = { ...defaultValues, ...existingValues };

  const { validationHelpers, getValues, setTouchedOnAll, handleSubmit } = useFarmerFormValidation({
    initialValues,
    validationSchema
  });

  const onHandleSubmit = async (e) => {
    e.preventDefault();
    await handleSubmit(
      async (data) => {
        await onSubmit?.(data, 'farmMonthlyOutgoingPaymentPage');
      },
      () => setTouchedOnAll()
    )();
  };

  if (loading) return <Loader />;

  const values = getValues();

  return (
    <FormStyles small={width < breakpointSizes.phone}>
      <FarmersQuestionnaireHeader noSubHeader>
        <PageHeader>
          <Trans
            t={t}
            components={{ coloredText: <EmphasizedBrandColorText isBank={isBank} /> }}
            i18nKey={'farmers_outgoing_payments_header'}
          />
        </PageHeader>
        <PageSubtitle>{t('farmers_outgoing_payments_subtitle')}</PageSubtitle>
      </FarmersQuestionnaireHeader>

      <Field
        Component={RadioChipOption}
        options={paymentAmountOptions}
        value={values.outgoingPayments}
        chipPadding={0}
        name="outgoingPayments"
        validateOnChange
        validationHelpers={validationHelpers}
        isInModal
      />

      <PageSecondaryTextStyles>{t('farmers_outgoing_payments_amount')}</PageSecondaryTextStyles>

      <Field
        Component={RadioChipOption}
        options={numberOfPaymentsOptions}
        value={values.numberOfPayments}
        name="numberOfPayments"
        validateOnChange
        validationHelpers={validationHelpers}
        isInModal
      />
      <ButtonSectionWrapperStyle isAtBottom={isAtBottom}>
        <ButtonSection
          secondButtonClick={onHandleSubmit}
          secondButtonPrimary
          secondButtonText={t('button_save_and_continue')}
        />
      </ButtonSectionWrapperStyle>
    </FormStyles>
  );
};
