import { useTranslation } from 'react-i18next';
import React, { useState } from 'react';
import {
  DescriptionStyles,
  MainWrapper,
  TitleStyles,
  StyledButton,
  TextStyles,
  ExplanationStyles,
  Wrapper,
  BottomContainer,
  TextWrapper
} from './WhoRepresentingFarm.style';
import { ArrowLeft } from '../../../Assets/images/logos';
import { ChipOption } from '@single-platform/components';
import { useDispatch, useSelector } from 'react-redux';
import { AppConfigActions, KYCActions } from '../../../Redux';
import { storageHandler } from '../../SSOLoginContainer/storageHandler';
import { logError } from '../../../Utilities';
import { languageSelector } from '../../../Redux/Selectors/questionnaireFlowHandle';
import { LoaderWithText } from '../../ApplicationKYC/LegalEntityQuestionnaire/components';
import { steps } from '../Modals/CompanyModal/CompanyDisplayContainer/CompanyDisplayContainer';

export const WhoRepresentingFarm = ({
  handleStepChange,
  farmDetailsData,
  selectedCompanyIdentificationId,
  handleCompanySelectHandler,
  selectedCompany
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [representation, setRepresentation] = useState('');
  const language = useSelector(languageSelector);
  const [loading, setLoading] = useState(false);
  const handleBack = () => {
    handleStepChange(steps.farm_details_form);
  };
  const handleOptionChange = (option) => {
    setRepresentation(option);
  };

  const submitHandler = async () => {
    setLoading(true);
    const referralKey = storageHandler.getLocalStorage('referalKey');
    const farmCreateData = {
      ...farmDetailsData,
      representation,
      referralKey,
      fetchOtherCompanyDataConsent: false
    };
    try {
      const response = await dispatch(
        KYCActions.createFarmApplication(farmCreateData, selectedCompanyIdentificationId)
      );
      await dispatch(
        AppConfigActions.updateApplicationSettings({
          applicationId: response.payload,
          language: language || 'lt-LT'
        })
      );
      handleCompanySelectHandler(selectedCompany);
    } catch (error) {
      logError(error);
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return <LoaderWithText text={t('loader_preparing_questionnaire_text')} />;
  }

  return (
    <MainWrapper>
      <Wrapper>
        <ArrowLeft onClick={handleBack} />
        <TitleStyles>{t('farm_who_representing_title')}</TitleStyles>
        <DescriptionStyles>{t('farm_who_representing_description')}</DescriptionStyles>
        <ChipOption
          name="who-represents"
          value="Self"
          onChange={() => handleOptionChange('Self')}
          checked={representation === 'Self'}
        >
          <TextWrapper>
            <TextStyles>{t('who_filling_farmer_himself')}</TextStyles>
          </TextWrapper>
        </ChipOption>
        <ChipOption
          name="who-represents"
          value="Representative"
          onChange={() => handleOptionChange('Representative')}
          checked={representation === 'Representative'}
        >
          <TextWrapper>
            <TextStyles>{t('who_filling_representative')}</TextStyles>
            <ExplanationStyles>{t('who_filling_explanation')}</ExplanationStyles>
          </TextWrapper>
        </ChipOption>
      </Wrapper>
      <BottomContainer>
        <StyledButton onClick={submitHandler} disabled={!representation}>
          {t('button_save_and_continue')}
        </StyledButton>
      </BottomContainer>
    </MainWrapper>
  );
};
