import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { Input, Field, Loader, Dropdown } from '@single-platform/components';
import { useSelector } from 'react-redux';
import useWindowDimensions from '../../../../../LegalEntityQuestionnaire/Utils/window-size.service';
import { FormStyles } from '../../../../../LegalEntityQuestionnaire/screens/renderPage/renderPage.style';
import breakpointSizes from '../../../../../LegalEntityQuestionnaire/styled-theme-styles/global-style-variables.style';
import { useFarmerFormValidation } from '../../../../../LegalEntityQuestionnaire/validation/useFarmerFormValidation';
import { DropdownInput } from '../../../dropdownInput/dropdownInput';
import countries from '../../../../../../Auth/components/countriesDropdown/farmersCountryList.json';
import activityChoices from '../utils/activityChoices.json';
import {
  DualCitizenshipPage,
  FarmerDocumentPage,
  FarmerPage,
  FarmerPepPage
} from '../../../../questionnaireSections/farmerSection/pages';
import { PartnerPepPage } from './pages/partnerPepPage';
import { PartnerPage } from './pages/partnerPage';
import { PartnerDocumentPage } from './pages/partnerDocumentPage';
import { GapContainer } from './drawer.styles';

const initialValues = {
  name: '',
  country: '',
  mainActivityClass: ''
};

export const PartnersDrawer = ({ onSubmit, shouldValidate, setShouldValidate, shownListItem }) => {
  const { t } = useTranslation();
  const { width } = useWindowDimensions();
  const validationSchema = Yup.object({
    name: Yup.string().required(t('validation_required_field')),
    country: Yup.string().required('validation_required_field'),
    mainActivityClass: Yup.string().required(t('validation_required_field'))
  });
  const { validationHelpers, setTouchedOnAll, handleSubmit } = useFarmerFormValidation({
    initialValues,
    validationSchema
  });
  const { loading } = useSelector((state) => state.FarmersQuestionnaireReducer);
  const onHandleSubmit = async () => {
    await handleSubmit(
      (data) => {
        onSubmit?.({ ...data, isValid: true });
      },
      // on invalid case
      (data) => setTouchedOnAll()
    )();
  };

  useEffect(() => {
    if (shouldValidate) {
      onHandleSubmit();
      setShouldValidate(false);
    }
  }, [shouldValidate]);

  return (
    <FormStyles small={width < breakpointSizes.phone} key={JSON.stringify(shownListItem)}>
      {loading ? (
        <Loader />
      ) : (
        <GapContainer>
          <PartnerPage
            onSubmit={handleSubmit}
            isAtBottom={true}
            handleStepBack={() => {}}
            handleSuccessCallback={() => {}}
          />
          <PartnerDocumentPage
            onSubmit={handleSubmit}
            isAtBottom={true}
            handleStepBack={() => {}}
            handleSuccessCallback={() => {}}
            isInModal={true}
          />
          <DualCitizenshipPage
            onSubmit={handleSubmit}
            isAtBottom={true}
            handleStepBack={() => {}}
            handleSuccessCallback={() => {}}
            isInModal={true}
          />
          <PartnerPepPage
            onSubmit={handleSubmit}
            isAtBottom={true}
            handleStepBack={() => {}}
            handleSuccessCallback={() => {}}
            isInModal={true}
          />
        </GapContainer>
      )}
    </FormStyles>
  );
};
