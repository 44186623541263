import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  AsideDrawerStyles,
  BackDrop,
  LinkButtonStyles,
  LinkPopupContainer
} from './linkPopup.style';
import { LinkPopupAml } from './drawers/linkPopupAml/linkPopupAml';
import { AsideDrawer } from '@single-platform/components';
import { LinkPopupDescription } from './drawers/linkPopupDescription/linkPopupDescription';
import { LinkPopupDataExchange } from './drawers/linkPopupDataExchange/linkPopupDataExchange';
import { LinkPopupFatca } from './drawers/linkPopupFatca/linkPopupFatca';
import { LinkPopupWhoIsPep } from './drawers/linkPopupWhoIsPep/linkPopupWhoIsPep';
import { LinkPopupFarmPartners } from './drawers/linkPopupFarmPartners/linkPopupFarmPartners';

export const LinkPopup = ({ group, isInModal, farmers }) => {
  const [rightSideDrawerOpen, setRightSideDrawerOpen] = useState(false);
  const { t } = useTranslation();

  const handleDrawerClose = () => {
    setRightSideDrawerOpen(false);
  };

  const getLinkPopupContent = (linkPopUpKey) => {
    switch (linkPopUpKey) {
      case 'link_popup_aml':
        return <LinkPopupAml onClose={handleDrawerClose} header={group.text} />;
      case 'link_popup_farm_partners':
        return <LinkPopupFarmPartners onClose={handleDrawerClose} header={group.text} />;
      case 'link_popup_activity':
        return (
          <LinkPopupDescription onClose={handleDrawerClose} header={group.text} farmers={farmers} />
        );
      case 'link_popup_data_exchange':
        return <LinkPopupDataExchange onClose={handleDrawerClose} header={group.text} />;
      case 'link_popup_who_is_pep':
        return <LinkPopupWhoIsPep onClose={handleDrawerClose} header={group.text} />;
      case 'link_popup_fatca':
        return <LinkPopupFatca onClose={handleDrawerClose} header={group.text} />;
      default:
        return;
    }
  };

  useEffect(() => {
    if (rightSideDrawerOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
  }, [rightSideDrawerOpen]);

  return (
    <LinkPopupContainer>
      <LinkButtonStyles onClick={() => setRightSideDrawerOpen(!rightSideDrawerOpen)}>
        {t(group.text)}
      </LinkButtonStyles>

      {rightSideDrawerOpen && !isInModal && <BackDrop onClick={handleDrawerClose} />}

      <AsideDrawerStyles isInModal={isInModal}>
        <AsideDrawer
          isOpen={rightSideDrawerOpen}
          id={'scrollable-content'}
          activeIndex={isInModal && rightSideDrawerOpen ? 1 : isInModal && 0}
        >
          {getLinkPopupContent(group.popupKey)}
        </AsideDrawer>
      </AsideDrawerStyles>
    </LinkPopupContainer>
  );
};
