import {
  APP_STATES,
  farmersQuestionnairePaths,
  history,
  questionnairePaths,
  routerPath
} from '../../../Config';
import { storageHandler } from '../../../Containers/SSOLoginContainer/storageHandler';
import { logError, Logout } from '../../../Utilities';
import { RefreshToken } from '../../../Utilities/refreshToken';
import { requestJSON } from '../../Config';
import { KYCActions } from '../KYC';
import { OnboardingFlowConstants } from './constants';

export const OnboardingFlowHandlerActions = {
  handleOnboardingFlow,
  isLoggedIn,
  setLanguage,
  setTraceId
};

function handleOnboardingFlow(company, applicationState) {
  return async (dispatch) => {
    let kycResponse;
    let currentCompany = company;
    storageHandler.setSessionStorage('company', company);

    isLoggedIn();

    const companyIdentificationId = company?.identificationId ?? company?.kycInfo?.identificationId;
    requestJSON.defaults.headers.common['X-IdentificationId'] = companyIdentificationId;

    try {
      if (!applicationState) {
        const response = await dispatch(KYCActions.getApplication());
        const {
          payload: { applicationState, company }
        } = await response;
        currentCompany = company;
        kycResponse = applicationState;
      } else {
        kycResponse = applicationState;
      }
      await dispatch(RefreshToken());
    } catch (error) {
      logError(error);
    }

    if (!currentCompany) {
      Logout();
    }

    storageHandler.setSessionStorage('questionnaireId', kycResponse?.questionnaireId);
    storageHandler.setSessionStorage('applicationId', kycResponse?.applicationId);

    if (kycResponse) {
      switch (kycResponse.state) {
        case APP_STATES.USER_FILLING_APPLICATION:
          if (currentCompany.questionnaireType === 'Farm')
            return history.push(farmersQuestionnairePaths.farmersQuestionnaire);
          history.push(questionnairePaths.questionnaire);
          break;
        case APP_STATES.WAITING_PRODUCT_AGREEMENT_SIGNATURE:
        case APP_STATES.AGREEMENT_SIGNING_STARTED:
          setDocumentId(kycResponse);
          history.push(questionnairePaths.agreementSigning);
          break;
        case 'Error':
          history.push(routerPath.errorPage);
          break;
        case 'UserUploadingDocuments':
          history.push(questionnairePaths.documentsUpload);
          break;
        default:
          history.push(routerPath.status);
          break;
      }
    } else {
      history.push(routerPath.errorPage);
    }
  };
}

function isLoggedIn() {
  const company = sessionStorage.getItem('company');
  const token = sessionStorage.getItem('token');
  return (dispatch) => {
    if (company && token) {
      dispatch({ type: OnboardingFlowConstants.IS_USER_LOGGED_IN_SUCCESS });
    } else {
      history.push(routerPath.index);
      dispatch({ type: OnboardingFlowConstants.IS_USER_LOGGED_IN_ERROR });
    }
  };
}

function setDocumentId(kycResponse) {
  const documentId = kycResponse.documentId;
  if (documentId) {
    return (dispatch) => {
      dispatch({ type: OnboardingFlowConstants.SET_DOCUMENT_ID_SUCCESS, payload: documentId });
    };
  }
}

function setLanguage(language) {
  return (dispatch) => dispatch({ type: OnboardingFlowConstants.SET_LANGUAGE, payload: language });
}

function setTraceId(traceId) {
  return (dispatch) => dispatch({ type: OnboardingFlowConstants.SET_TRACE_ID, payload: traceId });
}
