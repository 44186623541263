import styled, { keyframes } from 'styled-components';
import React from 'react';

const pulse = keyframes`
  0% { background-color: #f0f0f0; }
  50% { background-color: #e0e0e0; }
  100% { background-color: #f0f0f0; }
`;

const SkeletonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 6.75rem;
  gap: 1rem;
`;

const SkeletonContainer = styled.div`
  background-color: #ffffff;
  padding: 20px;
  min-height: 80px;
  width: 33.75rem;
  border-radius: 24px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
`;

const SkeletonStep = styled.div`
  height: 20px;
  margin: 10px 0;
  border-radius: 24px;
  background-color: #f4f4f5;
  overflow: hidden;
  position: relative;

  &:before {
    content: '';
    display: block;
    height: 100%;
    width: 100%;
    animation: ${pulse} 1.5s infinite ease-in-out;
  }
`;

const SkeletonLabel = styled(SkeletonStep)`
  width: 70%;
  margin-left: 30px;
`;

export const FarmersStepperSkeleton = () => {
  return (
    <SkeletonWrapper>
      <SkeletonContainer>
        {Array.from({ length: 7 }).map((_, index) => (
          <SkeletonStep key={`primary-zero=${index}`}>
            <SkeletonLabel />
          </SkeletonStep>
        ))}
      </SkeletonContainer>

      <SkeletonContainer key={`section-${1}`}>
        {Array.from({ length: 1 }).map(() => (
          <SkeletonStep key={`section-${1}-step-one`}>
            <SkeletonLabel />
          </SkeletonStep>
        ))}
      </SkeletonContainer>
      <SkeletonContainer key={`section-${2}`}>
        {Array.from({ length: 1 }).map(() => (
          <SkeletonStep key={`section-${2}-step-two`}>
            <SkeletonLabel />
          </SkeletonStep>
        ))}
      </SkeletonContainer>
      <SkeletonContainer key={`section-${3}`}>
        {Array.from({ length: 1 }).map(() => (
          <SkeletonStep key={`section-${3}-step-three`}>
            <SkeletonLabel />
          </SkeletonStep>
        ))}
      </SkeletonContainer>
      <SkeletonContainer key={`section-${4}`}>
        {Array.from({ length: 1 }).map(() => (
          <SkeletonStep key={`section-${4}-step-four`}>
            <SkeletonLabel />
          </SkeletonStep>
        ))}
      </SkeletonContainer>
    </SkeletonWrapper>
  );
};
