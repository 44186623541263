import React, { useEffect, useMemo } from 'react';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { Input, Field, Loader } from '@single-platform/components';
import { useSelector } from 'react-redux';
import countries from '../../../../../../../Auth/components/countriesDropdown/farmersCountryList.json';
import useWindowDimensions from '../../../../../../LegalEntityQuestionnaire/Utils/window-size.service';
import { useFarmerFormValidation } from '../../../../../../LegalEntityQuestionnaire/validation/useFarmerFormValidation';
import breakpointSizes from '../../../../../../LegalEntityQuestionnaire/styled-theme-styles/global-style-variables.style';
import { FormStyles } from '../../../../../../LegalEntityQuestionnaire/screens/renderPage/renderPage.style';
import { DropdownInput } from '../../../../dropdownInput/dropdownInput';

const defaultValues = {
  name: null,
  surname: null,
  countryOfResidence: null,
  taxResidencyCountry: null
};

export const PartnerPage = ({ onSubmit, onSubmitError, shouldSubmit, initialData }) => {
  const { t } = useTranslation();
  const { width } = useWindowDimensions();
  const { loading } = useSelector((state) => state.FarmersQuestionnaireReducer);

  const initialValues = useMemo(() => ({ ...defaultValues, ...initialData }), [initialData]);

  const validationSchema = useMemo(
    () =>
      Yup.object({
        name: Yup.string().required(t('validation_required_field')).nullable(),
        surname: Yup.string().required(t('validation_required_field')).nullable(),
        countryOfResidence: Yup.string().required(t('validation_required_field')).nullable(),
        taxResidencyCountry: Yup.string().required(t('validation_required_field')).nullable()
      }),
    [t]
  );

  const { validationHelpers, getValues, setTouchedOnAll, handleSubmit } = useFarmerFormValidation({
    initialValues,
    validationSchema
  });

  useEffect(() => {
    if (shouldSubmit) {
      handleSubmit(
        async (data) => {
          await onSubmit?.(data, 'partnerPage');
        },
        () => {
          setTouchedOnAll();
          onSubmitError();
        }
      )();
    }
  }, [shouldSubmit, handleSubmit, onSubmit, onSubmitError, setTouchedOnAll]);

  if (loading) return <Loader />;

  const values = getValues();

  return (
    <FormStyles small={width < breakpointSizes.phone} onSubmit={(e) => e.preventDefault()}>
      <Field
        Component={Input}
        name="name"
        value={values.name}
        placeholder={t('farmers_farmer_company_name')}
        validationHelpers={validationHelpers}
        required
        validateOnChange
      />
      <Field
        Component={Input}
        name="surname"
        value={values.surname}
        placeholder={t('farmers_farmer_surname')}
        validationHelpers={validationHelpers}
        required
        validateOnChange
      />
      <DropdownInput
        validationHelpers={validationHelpers}
        name="countryOfResidence"
        value={values.countryOfResidence}
        filterCountries={false}
        placeholder={t('farmers_partner_countryOfResidence')}
        options={countries}
      />
      <DropdownInput
        validationHelpers={validationHelpers}
        name="taxResidencyCountry"
        value={values.taxResidencyCountry}
        filterCountries={false}
        placeholder={t('farmers_partner_taxResidencyCountry')}
        options={countries}
      />
    </FormStyles>
  );
};
