import { FarmerQuestionnaireServices } from './services';
import { FarmerQuestionnaireConstants } from './constants';

export const FarmerQuestionnaireActions = {
  getFarmerInformation,
  setFarmerQuestionnaireSession,
  setStepBackQuestionnaireHandler,
  setQuestionnaireActiveStep,
  resetQuestinnaireData,
  completedModalVisible,
  updateFarmerInformation
};

function getFarmerInformation(questionnaireId) {
  return (dispatch) => {
    dispatch({ type: FarmerQuestionnaireConstants.GET_FARMER_INFORMATION_REQUEST });
    return FarmerQuestionnaireServices.getFarmerInformation(questionnaireId).then(
      (data) =>
        dispatch({
          type: FarmerQuestionnaireConstants.GET_FARMER_INFORMATION_SUCCESS,
          payload: data
        }),
      (error) => {
        dispatch({ type: FarmerQuestionnaireConstants.GET_FARMER_INFORMATION_ERROR, error });
        throw error;
      }
    );
  };
}

function updateFarmerInformation(questionnaireId, data) {
  return (dispatch) => {
    dispatch({ type: FarmerQuestionnaireConstants.UPDATE_FARMER_INFORMATION_REQUEST });
    return FarmerQuestionnaireServices.updateFarmerInformation(questionnaireId, data).then(
      (data) =>
        dispatch({
          type: FarmerQuestionnaireConstants.UPDATE_FARMER_INFORMATION_SUCCESS,
          payload: data
        }),
      (error) => {
        dispatch({ type: FarmerQuestionnaireConstants.UPDATE_FARMER_INFORMATION_ERROR, error });
        throw error;
      }
    );
  };
}

function setFarmerQuestionnaireSession(bool) {
  return (dispatch) => {
    dispatch({
      type: FarmerQuestionnaireConstants.SET_FARMER_QUESTIONNAIRE_SESSION,
      payload: bool
    });
  };
}

function setStepBackQuestionnaireHandler(functionHandler) {
  return (dispatch) => {
    dispatch({
      type: FarmerQuestionnaireConstants.SET_STEP_BACK_QUESTIONNAIRE_HANDLER,
      payload: functionHandler
    });
  };
}

function setQuestionnaireActiveStep(step) {
  return (dispatch) => {
    dispatch({
      type: FarmerQuestionnaireConstants.SET_QUESTIONNAIRE_ACTIVE_STEP,
      payload: step
    });
  };
}

function resetQuestinnaireData() {
  return (dispatch) => {
    dispatch({ type: FarmerQuestionnaireConstants.RESET });
  };
}

function completedModalVisible(bool) {
  return (dispatch) => {
    dispatch({
      type: FarmerQuestionnaireConstants.SET_IS_COMPLETED_MODAL_VISIBLE,
      payload: bool
    });
  };
}
