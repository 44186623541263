import React, { useMemo } from 'react';
import { useFarmerFormValidation } from '../../../../../LegalEntityQuestionnaire/validation/useFarmerFormValidation';
import * as Yup from 'yup';
import { Trans, useTranslation } from 'react-i18next';
import breakpointSizes from '../../../../../LegalEntityQuestionnaire/styled-theme-styles/global-style-variables.style';
import useWindowDimensions from '../../../../../LegalEntityQuestionnaire/Utils/window-size.service';
import { Field, Input, Loader, TextArea } from '@single-platform/components';
import {
  FarmersQuestionnaireHeader,
  PageSecondaryTextStyles,
  PageSubtitle
} from '../../../../farmersQuestionnaire.style';
import {
  FormStyles,
  PageHeader
} from '../../../../../LegalEntityQuestionnaire/screens/renderPage/renderPage.style';
import { ButtonSection } from '../../../../../LegalEntityQuestionnaire/components';
import { useSelector } from 'react-redux';
import { ButtonSectionWrapperStyle, EmphasizedBrandColorText } from '../../../formStyles';
import { RadioChipOption } from '../../../../components';
import { yesNoChipOptions } from '../../../../constants';

const defaultValues = {
  doesFarmUseCash: null,
  proportionOfPaymentsInCash: null,
  maximumSingleCashAmount: null,
  howCashIsUsed: null
};

const cashAmountOptions = [
  { label: 'choices_proportion_of_payments_cash_less_than_10_percent', value: 'LessThan10Percent' },
  {
    label: 'choices_proportion_of_payments_cash_from_11_to_20_percent',
    value: 'From11PercentTo20Percent'
  },
  {
    label: 'choices_proportion_of_payments_cash_from_21_to_30_percent',
    value: 'From21PercentTo30Percent'
  },
  {
    label: 'choices_proportion_of_payments_cash_more_than_31_percent',
    value: 'MoreThan31Percent'
  }
];

export const FarmCashInformationPage = ({ onSubmit, isAtBottom }) => {
  const { t } = useTranslation();
  const { width } = useWindowDimensions();
  const { loading, farmerInformation } = useSelector((state) => state.FarmersQuestionnaireReducer);
  const isBank = process.env.REACT_APP_TRADEMARK.includes('Bank');

  const validationSchema = useMemo(
    () =>
      Yup.object({
        doesFarmUseCash: Yup.bool().required(t('validation_must_be_one_of')).nullable(),
        proportionOfPaymentsInCash: Yup.string().when('doesFarmUseCash', {
          is: true,
          then: Yup.string().required(t('validation_must_be_one_of')).nullable(),
          otherwise: Yup.string().notRequired().nullable()
        }),
        maximumSingleCashAmount: Yup.string().when('doesFarmUseCash', {
          is: true,
          then: Yup.string()
            .required(t('validation_required_field'))
            .nullable()
            .test('isPositive', 'validation_must_be_positiive', (value) => parseInt(value) > 0),
          otherwise: Yup.string().notRequired().nullable()
        }),
        howCashIsUsed: Yup.string().when('doesFarmUseCash', {
          is: true,
          then: Yup.string().required(t('validation_required_field')).nullable(),
          otherwise: Yup.string().notRequired().nullable()
        })
      }),
    [t]
  );

  const existingValues =
    farmerInformation?.farmPaymentsAndTransactionsSection?.farmCashInformationPage;
  const initialValues = { ...defaultValues, ...existingValues };

  const { validationHelpers, getValues, setTouchedOnAll, handleSubmit } = useFarmerFormValidation({
    initialValues,
    validationSchema
  });

  const onHandleSubmit = async (e) => {
    e.preventDefault();
    await handleSubmit(
      async (data) => {
        if (data.doesFarmUseCash === false) {
          await onSubmit?.({ doesFarmUseCash: false }, 'farmCashInformationPage');
        } else {
          await onSubmit?.(data, 'farmCashInformationPage');
        }
      },
      () => setTouchedOnAll()
    )();
  };

  if (loading) return <Loader />;

  const values = getValues();

  return (
    <FormStyles small={width < breakpointSizes.phone}>
      <FarmersQuestionnaireHeader noSubHeader>
        <PageHeader>
          <Trans
            t={t}
            components={{ coloredText: <EmphasizedBrandColorText isBank={isBank} /> }}
            i18nKey={'farmers_cash_header'}
          />
        </PageHeader>
        <PageSubtitle>{t('farmers_cash_subtitle')}</PageSubtitle>
      </FarmersQuestionnaireHeader>

      <Field
        Component={RadioChipOption}
        options={yesNoChipOptions}
        value={values.doesFarmUseCash}
        name="doesFarmUseCash"
        validateOnChange
        validationHelpers={validationHelpers}
        isInModal
      />

      {values.doesFarmUseCash && (
        <>
          <PageSecondaryTextStyles>{t('farmers_cash_percentage')}</PageSecondaryTextStyles>

          <Field
            Component={RadioChipOption}
            options={cashAmountOptions}
            value={values.proportionOfPaymentsInCash}
            name="proportionOfPaymentsInCash"
            validateOnChange
            validationHelpers={validationHelpers}
            isInModal
          />

          <PageSecondaryTextStyles>{t('farmers_cash_max_amount')}</PageSecondaryTextStyles>

          <Field
            Component={Input}
            type="number"
            name="maximumSingleCashAmount"
            value={values.maximumSingleCashAmount}
            placeholder={t('farmers_max_amount_placeholder')}
            validationHelpers={validationHelpers}
            required
            validateOnChange
          />

          <div>
            <PageSecondaryTextStyles>{t('farmers_how_cash_is_used')}</PageSecondaryTextStyles>
            <PageSubtitle>{t('farmers_how_cash_is_used_description')}</PageSubtitle>
          </div>

          <Field
            Component={TextArea}
            name="howCashIsUsed"
            value={values.howCashIsUsed}
            placeholder={t('question_enter_description')}
            validationHelpers={validationHelpers}
            required
            validateOnChange
            autoHeight={true}
            maxHeight={288}
            validateOnBlur
            maxLength={500}
            counter={true}
          />
        </>
      )}

      <ButtonSectionWrapperStyle isAtBottom={isAtBottom}>
        <ButtonSection
          secondButtonClick={onHandleSubmit}
          secondButtonPrimary
          secondButtonText={t('button_save_and_continue')}
        />
      </ButtonSectionWrapperStyle>
    </FormStyles>
  );
};
