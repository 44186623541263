import { storageHandler } from '../Containers/SSOLoginContainer/storageHandler';
import { AuthActions } from '../Redux';

export const RefreshToken = () => {
  const smeGoSession = storageHandler.getSessionStorage('smeGoSession');
  if (smeGoSession) {
    return AuthActions.refreshTokenWithoutSession();
  }
  return AuthActions.refreshToken();
};
