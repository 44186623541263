import React, { useMemo } from 'react';
import { useFarmerFormValidation } from '../../../../../LegalEntityQuestionnaire/validation/useFarmerFormValidation';
import * as Yup from 'yup';
import { Trans, useTranslation } from 'react-i18next';
import breakpointSizes from '../../../../../LegalEntityQuestionnaire/styled-theme-styles/global-style-variables.style';
import useWindowDimensions from '../../../../../LegalEntityQuestionnaire/Utils/window-size.service';
import { Field, Loader } from '@single-platform/components';
import { FarmersQuestionnaireHeader, PageSubtitle } from '../../../../farmersQuestionnaire.style';
import {
  FormStyles,
  PageHeader
} from '../../../../../LegalEntityQuestionnaire/screens/renderPage/renderPage.style';
import { ButtonSection } from '../../../../../LegalEntityQuestionnaire/components';
import { useSelector } from 'react-redux';
import { ButtonSectionWrapperStyle, EmphasizedBrandColorText } from '../../../formStyles';
import CheckboxGroup from '../../../../components/checkboxGroup/checkboxGroup';
import { history } from '../../../../../../../Config';

const defaultValues = {
  bankPurposes: []
};

const bankAccountPurposeOptions = [
  {
    label: 'choices_intended_banking_services_daily_banking',
    value: 'DailyBanking'
  },
  {
    label: 'choices_intended_banking_services_deposits',
    value: 'Deposits'
  },
  {
    label: 'choices_intended_banking_services_international_payments',
    value: 'InternationalPayments'
  },
  {
    label: 'choices_intended_banking_services_currency_exchange',
    value: 'CurrencyExchange'
  },
  {
    label: 'choices_intended_banking_services_loan',
    value: 'Loan'
  }
];

export const FarmBankAccountPurposePage = ({ onSubmit, isAtBottom }) => {
  const { t } = useTranslation();
  const { width } = useWindowDimensions();
  const { loading, farmerInformation } = useSelector((state) => state.FarmersQuestionnaireReducer);
  const isBank = process.env.REACT_APP_TRADEMARK.includes('Bank');

  const validationSchema = useMemo(
    () =>
      Yup.object({
        bankPurposes: Yup.array().of(Yup.string()).min(1, t('validation_must_be_one_of')).required()
      }),
    [t]
  );

  const existingValues = farmerInformation?.farmPaymentsAndTransactionsSection?.farmBankPurposePage;
  const initialValues = { ...defaultValues, ...existingValues };

  const { validationHelpers, getValues, setTouchedOnAll, handleSubmit } = useFarmerFormValidation({
    initialValues,
    validationSchema
  });

  const onHandleSubmit = async (e) => {
    e.preventDefault();
    await handleSubmit(
      async (data) => {
        await onSubmit?.(data, 'farmBankPurposePage');
        history.push('/FarmersQuestionnaire?success=farmPaymentsAndTransactionsSection');
      },
      () => setTouchedOnAll()
    )();
  };

  if (loading) return <Loader />;

  const values = getValues();

  return (
    <FormStyles small={width < breakpointSizes.phone}>
      <FarmersQuestionnaireHeader noSubHeader>
        <PageHeader>
          <Trans
            t={t}
            components={{ coloredText: <EmphasizedBrandColorText isBank={isBank} /> }}
            i18nKey={'farmers_bank_account_purposes_header'}
          />
        </PageHeader>
      </FarmersQuestionnaireHeader>

      <PageSubtitle>{t('header_intended_banking_services')}</PageSubtitle>

      <Field
        Component={CheckboxGroup}
        options={bankAccountPurposeOptions}
        value={values.bankPurposes}
        name="bankPurposes"
        validateOnChange
        validationHelpers={validationHelpers}
        isInModal
      />

      <ButtonSectionWrapperStyle isAtBottom={isAtBottom}>
        <ButtonSection
          secondButtonClick={onHandleSubmit}
          secondButtonPrimary
          secondButtonText={t('button_save_and_continue')}
        />
      </ButtonSectionWrapperStyle>
    </FormStyles>
  );
};
