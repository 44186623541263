export const apiTranslate = process.env.REACT_APP_API_TRANSLATE;
export const api = process.env.REACT_APP_API;
export const questionnaireApi = process.env.REACT_APP_QUESTIONNAIRE_API;
export const authorizationApi = process.env.REACT_APP_AUTH_API;

export const TranslationContexts = {
  classificators: 'BBS.Classificators',
  KYCOnBoarding: 'BBS.KYCOnBoarding'
};

export const aPIPaths = {
  //Translations
  get_Translations: '/Translations/',
  get_Translations_CreateTranslation: '/Translations/GetCreateTranslation/',

  //Rekvizitai
  getCompanyNames: `{inputValue}`,

  //Questionnaire
  add_repeatable_entry:
    '/Questionnaire/{questionnaireId}/RepeatableGroup/{repeatableGroupId}/AddEntry',
  remove_repeatable_entry:
    '/Questionnaire/{questionnaireId}/RepeatableGroup/{repeatableGroupId}/RemoveEntry/{groupId}',
  save_questionnaire_answers: '/Questionnaire/{questionnaireId}/Answers',
  update_repeating_info_checkbox: '/Questionnaire/{questionnaireId}/RepeatingCheckbox',
  get_questionnaire: '/Questionnaire/{questionnaireId}',
  validate_questionnaire_page: '/questionnaire/{questionnaireId}/pages/{pageTitle}/validate',
  flow_selection: '/Kyc/SetOnboardingFlow/',

  //FarmersQuestionnaire
  createFarmApplication: '/Application/Farm',
  getFarmerInformation: '/Questionnaire/Farm/{questionnaireId}',
  updateFarmerInformation: '/Questionnaire/Farm/{questionnaireId}',

  //KYC
  post_sendKycApplication: '/Kyc/KycApplication/',
  post_getListKYC: '/Kyc/AllKyc/',
  post_modifyKycApplication: '/Kyc/ModifyKyc/',
  post_approveKycApplication: '/Kyc/Approve/',
  delete_deleteKycApplication: '/Kyc/DeleteKyc/',
  getApplication: '/kyc/Application',
  createLegalEntityApplication: 'Application/LegalEntity',
  uploadPowerOfAttorney: '/Kyc/UploadPowerOfAttorney/',
  uploadOrganizationalChart: '/Kyc/uploadOrganizationalChart/',
  uploadArticlesOfAssociation: '/Kyc/UploadArticlesOfAssociation/',
  uploadBeneficiaryDocuments: '/Kyc/UploadBeneficiaryDocuments/',
  uploadWolfsbergQuestionnaire: '/Kyc/UploadWolfsbergQuestionnaire/',
  submitApplication: '/Kyc/SubmitApplication/{applicationId}',
  startApplicationSigning: '/Kyc/StartApplicationSigning/',
  startApplicationSignedUpload: '/Kyc/StartApplicationSignedUpload',
  uploadSignedApplication: '/Kyc/UploadSignedApplication/{applicationDocumentId}',
  checkSigningSca: '/Kyc/CheckSigningSca/',
  cancelSigning: '/Kyc/CancelSigning/',
  cancelUploadDocument: 'Kyc/CancelUploadDocument',
  uploadSignedAgreement: 'Kyc/UploadSignedAgreement',
  resetAll: '/Kyc​/StartOver/',
  continueApplication: '/Kyc/Continue/',
  getCompanyClassification:
    '/Kyc/GetCompanyClassification/{companyType}/{institutionalSectorCode}/{naceCode}',
  checkState: '/Kyc/ApplicationState/',
  startAgreementSigning: '/Kyc/StartAgreementSigning/',
  uploadAdditionalDocuments: '/Kyc/UploadAdditionalDocuments/',
  startOndatoBeneficiaryIdentification: '/Kyc/StartOndatoBeneficiaryIdentification/',
  renderPdfFromHtml: '/Kyc/RenderPdfFromHtml',
  uploadKycPdf: '/Kyc/UploadKycPdf',
  validateData: 'Kyc/ValidateData',
  institutionalCodeMap: 'Kyc/InstitutionalCodeMap/{legalForm}/{nace}',
  returnToUserFillingApplication: 'Kyc/ReturnToUserFillingApplication/{applicationId}',

  //OnBoarding
  post_GenerateMarkIdPayload: '/Onboarding/GenerateMarkIdPayload/',
  post_OndatoStartIdentification: '/OnBoarding/OndatoStartIdentification/',

  //Tools
  get_companyNames: '/Tools/GetCompanyNames/',

  //KYC preperation
  getCompanies: '/KycPreparation/GetCompanies/{companyName}',
  dataExchangeCheck: '/kycPreparation/otherCompanyPersonalDataProcessing/{companyCode}/{country}',

  //Periodic
  getRepresentativeInformation: '/PeriodicReview/{applicationId}/RepresentativeChange/Status',
  getExternalPersonInformation: '/Company/{groupId}/ExternalPersons/{externalPersonId}/Status',
  startExternalPersonVerification:
    '/Company/{groupId}/ExternalPersons/{externalPersonId}/Verification/Start?language={language}',
  startRepresentativeChangeVerification:
    '/PeriodicReview/{applicationId}/RepresentativeChange/Verification/Start?language={language}',

  //Authorization
  startLogin: '/Authorization/StartLogin/',
  GetAuthorizedCompanies: '/Authorization/GetAuthorizedCompanies/',
  selectIdentification: '/Authorization/SelectIdentification/',
  getIdentificationUrl: '/Authorization/GetIdentificationUrl/',
  smartIdInit: '/api/Authorization/StartSession/SmartId',
  mobileIdInit: '/api/Authorization/StartSession/MobileId',
  biometricsInit: '/api/Authorization/StartSession/Biometrics',
  authorizationStatus: '/api/Authorization/Verification/Status',
  otpAuthorization: '/api/Authorization/Verification/Otp',
  refreshToken: '/api/Authorization/Verification/Refresh',
  refreshTokenWithoutSession:
    '/api/SinglePlatform/Authorization/RefreshWithoutSession?refreshToken={refreshToken}',
  eideasyInit: '/api/Authorization/StartSession/EIdEasy',
  eideasyStatus: '/api/Authorization/Verification/EIdEasy/Redirect',
  addEmptyIdentification: '/api/Test/TestCompany',

  //Applications
  getManualAmlRiskList: '/Applications/GetManualAmlRisksList',
  calculateAllClientsRiskCraScreen: '/Applications/CalculateAllClientsRiskCraScreen',
  setManualAmlRiskList: '/Applications/{applicationId}/SetManualRisk',
  allSanctionReviewed: '/Applications/{applicationId}/AllSanctionsReviewed/', // statusas i -> SetManualRisk
  getDocuments: '/Applications/{applicationId}/Documents/',
  getUploadedDocumentsList: '/Application/{applicationId}/Documents',
  uploadApplicationDocument: '/Application/{applicationId}/DocumentRequests/{requestId}/Files',
  deleteApplicationDocumentFile:
    '/Application/{applicationId}/DocumentRequests/{requestId}/File/{fileId}',
  rejectApplication: '/Applications/{applicationId}/RejectApplication/',
  rejectDueSanctionList: '/Applications/{applicationId}/RejectDueSanctionList/',
  requestAdditionalDocuments: '/Applications/{applicationId}/RequestAdditionalDocuments/',
  approveRisk: '/Applications/{applicationId}/ApproveRisks/',
  addRisk: '/Applications/{applicationId}/AddRisk/',
  approveBeneficiaries: '/Applications/{applicationId}/ApproveBeneficiaries/',
  renewKyc: '/Admin/Application/{applicationId}/RenewKyc',
  signApplication: '/Document/SignApplication?applicationId={applicationId}&isConsent=true',
  signStatus: '/Application/{applicationId}/DocumentRequests/{documentId}/Signing/Status',
  ///TODO Form save
  getApplicationDraft: '/Kyc/GetApplicationDraft/',
  postSaveApplicationDraft: '/Kyc/SaveApplicationDraft/',
  //TODO upload documents
  uploadDocuments: '/Applications/{applicationId}/UploadAdditionalDocuments/{documentType}/',
  getApplicationDocumentRequests: '/Application/{applicationId}/DocumentRequests/Client',
  getApplicationDocumentRequestsToSign: '/Application/{applicationId}/DocumentRequests/Signing',
  confirmApplicationDocumentsUpload: '/Kyc/SubmitApplicationDocumentRequests/{applicationId}',
  setReviewKycStatus: '/Applications/{applicationId}/ReviewedKyc',
  setMissingDocuments: '/Applications/{applicationId}/ReviewedApplicationDocuments',
  confirmUploadedAgreements: '/Applications/{applicationId}/ConfirmingUploadedAgreement',
  rejectUploadedAgreement: '/Applications/{applicationId}/RejectUploadedAgreement',
  getClientRisksList: '/Applications/{applicationId}/GetClientRisksList',
  updateRiskHit: '/Applications/{applicationId}/UpdateRiskHit',

  // config
  countryRiskList: '/Config/CountryRiskList',
  updateCountryRisk: '/Config/UpdateCountryRisk',
  riskList: '/Config/RiskList',
  updateRiskItem: '/Config/UpdateRiskItem',
  naceBlockers: '/Config/NaceBlockers',
  updateNaceBlockersItem: '/Config/UpdateNaceBlockerItem',
  updateApplicationSettings: '/ApplicationSettings'
};

export const apiIdentity = 'https://test.identity.bbservice.lt/api/';
