import React, { useEffect, useMemo } from 'react';
import { useFarmerFormValidation } from '../../../../../../LegalEntityQuestionnaire/validation/useFarmerFormValidation';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { Input, Field, Loader, Dropdown } from '@single-platform/components';
import { useSelector } from 'react-redux';
import countries from '../../../../../../../Auth/components/countriesDropdown/farmersCountryList.json';
import useWindowDimensions from '../../../../../../LegalEntityQuestionnaire/Utils/window-size.service';
import { FormStyles } from '../../../../../../LegalEntityQuestionnaire/screens/renderPage/renderPage.style';
import { RadioChipOption } from '../../../../radioChipOption/radioChipOption';
import { Checkbox } from '../../../../../components';
import { DropdownInput } from '../../../../dropdownInput/dropdownInput';
import { PageSecondaryTextStyles } from '../../../../../farmersQuestionnaire.style';
import { breakpointSizes } from '@single-platform/components/dist/design/designVariables';
import { identityDocumentTypeOptions, yesNoChipOptions } from '../../../../../constants';

const defaultValues = {
  hasDualCitizenship: null,
  type: null,
  documentNumber: null,
  issuingCountry: null,
  acquiredThroughInvestment: null
};

export const PartnerDualCitizenshipPage = ({
  onSubmit,
  onSubmitError,
  shouldSubmit,
  initialData
}) => {
  const { t } = useTranslation();
  const { width } = useWindowDimensions();
  const { loading } = useSelector((state) => state.FarmersQuestionnaireReducer);

  const validationSchema = useMemo(
    () =>
      Yup.object({
        hasDualCitizenship: Yup.boolean().nullable().required(t('validation_must_be_one_of')),
        type: Yup.string()
          .nullable()
          .when('hasDualCitizenship', {
            is: true,
            then: Yup.string().required(t('validation_required_field')).nullable()
          }),
        documentNumber: Yup.string()
          .nullable()
          .when('hasDualCitizenship', {
            is: true,
            then: Yup.string().required(t('validation_required_field')).nullable()
          }),
        issuingCountry: Yup.string()
          .nullable()
          .when('hasDualCitizenship', {
            is: true,
            then: Yup.string().required(t('validation_required_field')).nullable()
          }),
        acquiredThroughInvestment: Yup.boolean().nullable().optional()
      }),
    [t]
  );

  const initialValues = useMemo(() => ({ ...defaultValues, ...initialData }), [initialData]);

  const { validationHelpers, getValues, setTouchedOnAll, handleSubmit } = useFarmerFormValidation({
    initialValues,
    validationSchema
  });

  useEffect(() => {
    if (shouldSubmit) {
      handleSubmit(
        async (data) => {
          await onSubmit?.(data, 'partnerDualCitizenshipPage');
        },
        () => {
          setTouchedOnAll();
          onSubmitError();
        }
      )();
    }
  }, [shouldSubmit, handleSubmit, onSubmit, onSubmitError, setTouchedOnAll]);

  if (loading) return <Loader />;

  const values = getValues();

  return (
    <FormStyles small={width < breakpointSizes.phone}>
      <PageSecondaryTextStyles>{t('farmers_partner_has_dual_citizenship')}</PageSecondaryTextStyles>
      <Field
        Component={RadioChipOption}
        options={yesNoChipOptions}
        value={values.hasDualCitizenship}
        name="hasDualCitizenship"
        validationHelpers={validationHelpers}
        isInModal
        validateOnChange
      />
      {values.hasDualCitizenship && (
        <>
          <Field
            Component={Dropdown}
            name="type"
            value={values.type}
            placeholder={t('farmers_farmer_dual_citizenship_type')}
            options={identityDocumentTypeOptions}
            validationHelpers={validationHelpers}
            validateOnChange
          />
          <Field
            Component={Input}
            name="documentNumber"
            value={values.documentNumber}
            placeholder={t('farmers_farmer_dual_citizenship_documentNumber')}
            validationHelpers={validationHelpers}
            required
            validateOnChange
          />
          <DropdownInput
            validationHelpers={validationHelpers}
            name="issuingCountry"
            value={values.issuingCountry}
            placeholder={t('farmers_farmer_dual_citizenship_issuingCountry')}
            options={countries}
            validateOnChange
          />
          <Field
            Component={Checkbox}
            name="acquiredThroughInvestment"
            checked={values.acquiredThroughInvestment}
            value={values.acquiredThroughInvestment}
            validationHelpers={validationHelpers}
            label={t('farmers_farmer_dual_citizenship_acquiredThroughInvestment')}
            validateOnChange
          />
        </>
      )}
    </FormStyles>
  );
};
