export const routerPath = {
  index: '/',
  login: '/login/',
  smeKyc: '/documentsUpload',
  errorPage: '/error',
  redirectEIdEasy: '/login/redirect',
  status: '/status',
  smego: '/smego',
  representativeChange: '/RepresentativeChange',
  companyIdentification: `/Company`,
  ondatoError: '/ondatoError',
  registration: '/registration'
};

export const farmersQuestionnairePaths = {
  farmersQuestionnaire: '/FarmersQuestionnaire',
  farmersQuestionnaireFarmSection: '/FarmSection',
  farmersQuestionnaireFarmerSection: '/FarmerSection',
  farmersQuestionnaireRepresentativeSection: '/FarmRepresentativeSection',
  farmersQuestionnaireClientsAndSupliersSection: '/FarmClientsAndSuppliersSection',
  farmersQuestionnairePaymentsAndTransactionsSection: '/FarmPaymentsAndTransactionsSection',
  farmersQuestionnaireFarmActivitiesSection: '/FarmActivitiesSection',
  farmersQuestionnaireFarmPartnersSection: '/FarmPartnersSection'
};

export const linkPath = {
  index: { url: '/' },
  login: { url: '/login/' },
  smeKyc: { url: '/newKycSME/' },
  callback: { url: '/callback.html' }
};

export const questionnairePaths = {
  questionnaire: '/questionnaire',
  agreementSigning: '/agreementSigning',
  documentsUpload: '/documentUpload'
};
