export const FarmerQuestionnaireConstants = {
  GET_FARMER_INFORMATION_REQUEST: 'GET_FARMER_INFORMATION_REQUEST',
  GET_FARMER_INFORMATION_SUCCESS: 'GET_FARMER_INFORMATION_SUCCESS',
  GET_FARMER_INFORMATION_ERROR: 'GET_FARMER_INFORMATION_ERROR',

  SET_FARMER_QUESTIONNAIRE_SESSION: 'SET_FARMER_QUESTIONNAIRE_SESSION',
  SET_STEP_BACK_QUESTIONNAIRE_HANDLER: 'SET_STEP_BACK_QUESTIONNAIRE_HANDLER',
  SET_QUESTIONNAIRE_ACTIVE_STEP: 'SET_QUESTIONNAIRE_ACTIVE_STEP',
  SET_SUCCESS_HANDLER: 'SET_SUCCESS_HANDLER',
  RESET: 'RESET',

  SET_IS_COMPLETED_MODAL_VISIBLE: 'SET_IS_COMPLETED_MODAL_VISIBLE',

  UPDATE_FARMER_INFORMATION_REQUEST: 'UPDATE_FARMER_INFORMATION_REQUEST',
  UPDATE_FARMER_INFORMATION_SUCCESS: 'UPDATE_FARMER_INFORMATION_SUCCESS',
  UPDATE_FARMER_INFORMATION_ERROR: 'UPDATE_FARMER_INFORMATION_ERROR'
};
