import styled from 'styled-components';
import { PageHeader } from '../../../../../LegalEntityQuestionnaire/screens/renderPage/renderPage.style';

export const PageHeaderDrawer = styled(PageHeader)`
  font-size: 1rem;
`;

export const GapContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
`;
