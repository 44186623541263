import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Select } from 'antd';
import i18next from 'i18next';

import { LanguageActions } from '@redux';

import flagLT from './Flags/lt.png';
import flagGB from './Flags/gb.png';
import { OnboardingFlowHandlerActions } from '../../Redux/Services/OnboardingFlowHandler/actions';
import { farmersQuestionnairePaths, history, questionnairePaths, routerPath } from '../../Config';
import { useTranslation } from 'react-i18next';

const { Option } = Select;
const mobile = window.innerWidth < 427;

const languageSelect = [
  {
    value: 'lt-LT',
    label: (
      <>
        <img src={flagLT} alt="lt-LT" /> {mobile ? null : 'Lietuvių'}{' '}
      </>
    )
  },
  {
    value: 'en-GB',
    label: (
      <>
        <img src={flagGB} alt="en-GB" /> {mobile ? null : 'English'}{' '}
      </>
    )
  }
];

const languageOptions = {
  locales: {
    en: 'en-GB',
    lt: 'lt-LT'
  },
  applicationUrls: [
    questionnairePaths.agreementSigning,
    questionnairePaths.questionnaire,
    routerPath.redirectEIdEasy,
    routerPath.login,
    routerPath.errorPage,
    routerPath.smego,
    routerPath.representativeChange,
    routerPath.companyIdentification,
    questionnairePaths.documentsUpload,
    routerPath.ondatoError,
    farmersQuestionnairePaths.farmersQuestionnaireFarmSection,
    farmersQuestionnairePaths.farmersQuestionnaire,
    farmersQuestionnairePaths.farmersQuestionnaireFarmerSection,
    farmersQuestionnairePaths.farmersQuestionnaireRepresentativeSection,
    farmersQuestionnairePaths.farmersQuestionnairePaymentsAndTransactionsSection,
    farmersQuestionnairePaths.farmersQuestionnaireFarmPartnersSection
  ]
};
export const LanguageSelect = () => {
  const dispatch = useDispatch();
  const [currentLanguage, setCurrentLanguage] = useState(
    localStorage.getItem('language') || 'lt-LT'
  );
  const { i18n } = useTranslation();

  const languageHandler = (language) => {
    i18next.changeLanguage(language);
    setCurrentLanguage(language);
    dispatch(OnboardingFlowHandlerActions.setLanguage(language));
    dispatch(LanguageActions.setLanguage(language));
  };

  const setUrl = (pathname) => {
    const url = new URL(window.location.href);
    const referalKey = url?.searchParams.getAll('ref')[0];
    const localReferalKey = localStorage.getItem('referalKey');
    const representativeChange = url.pathname.includes('RepresentativeChange');
    const companyIdentification =
      url.pathname.includes('Company') && url.pathname.includes('ExternalPersons');
    (referalKey || !localReferalKey) &&
      localStorage.setItem('referalKey', referalKey ? referalKey : '');

    if (
      !languageOptions.applicationUrls.includes(pathname.pathname) &&
      !representativeChange &&
      !companyIdentification
    ) {
      url.pathname = '';
      const urlWithoutPathName = url.toString();
      history.replace({}, '', urlWithoutPathName);
    }
  };

  useEffect(() => {
    const cookiebotScript = document.getElementById('Cookiebot');
    if (cookiebotScript) {
      if (cookiebotScript.getAttribute('data-culture') !== i18n.language.split('-')[0]) {
        cookiebotScript.setAttribute('data-culture', i18n.language.split('-')[0]);
        if (window.Cookiebot.bannerIsShown) {
          //force translations renew for banner
          window.Cookiebot.renew();
        }
      }
    }
  }, [i18n.language]);

  const setLanguage = () => {
    const url = new URL(window.location);
    const languageParam = url.searchParams.get('language') ?? localStorage.getItem('language');
    let language;

    if (languageParam) {
      language = languageParam.length === 2 ? languageParam : languageParam.split('-')[0];
    }

    switch (language) {
      case 'lt':
        languageHandler(languageOptions.locales.lt);
        setUrl(url);
        break;
      case 'en':
        languageHandler(languageOptions.locales.en);
        setUrl(url);
        break;
      default:
        languageHandler(currentLanguage);
        setUrl(url);
        break;
    }
  };

  useEffect(() => {
    setLanguage();
  }, []);

  return (
    <Select style={{ maxWidth: '8rem' }} value={currentLanguage} onChange={languageHandler}>
      {languageSelect.map((item, index) => (
        <Option value={item.value} key={index}>
          {item.label}
        </Option>
      ))}
    </Select>
  );
};
