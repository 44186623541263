import styled from 'styled-components';

export const ButtonSectionStyle = styled.div`
  height: auto;
  display: flex;
  gap: 1rem;
  margin-top: 1rem;
  @media only screen and (max-width: 470px) {
    gap: 0.5rem;
    button {
      width: 100%;
    }
  }

  @media only screen and (min-width: 471px) {
    justify-content: center;
    height: auto;
    flex-direction: row;
    margin-top: 1rem;
  }
`;
