import React, { useMemo } from 'react';
import { useFarmerFormValidation } from '../../../../../LegalEntityQuestionnaire/validation/useFarmerFormValidation';
import * as Yup from 'yup';
import { Trans, useTranslation } from 'react-i18next';
import breakpointSizes from '../../../../../LegalEntityQuestionnaire/styled-theme-styles/global-style-variables.style';
import useWindowDimensions from '../../../../../LegalEntityQuestionnaire/Utils/window-size.service';
import { Field, Input, Loader, Toast } from '@single-platform/components';
import { FarmersQuestionnaireHeader } from '../../../../farmersQuestionnaire.style';
import {
  FormStyles,
  PageHeader
} from '../../../../../LegalEntityQuestionnaire/screens/renderPage/renderPage.style';
import { ButtonSection } from '../../../../../LegalEntityQuestionnaire/components';
import { useSelector } from 'react-redux';
import { ButtonSectionWrapperStyle, EmphasizedBrandColorText } from '../../../formStyles';

const defaultValues = {
  annualTurnover: null,
  balance: null,
  numberOfEmployees: null
};

export const FarmFinancialPage = ({ onSubmit, isAtBottom }) => {
  const { t } = useTranslation();
  const { width } = useWindowDimensions();
  const { loading, farmerInformation } = useSelector((state) => state.FarmersQuestionnaireReducer);

  const validationSchema = useMemo(
    () =>
      Yup.object({
        annualTurnover: Yup.number()
          .transform((value) => (isNaN(value) ? undefined : value))
          .nullable()
          .required(t('validation_required_field')),
        balance: Yup.number()
          .transform((value) => (isNaN(value) ? undefined : value))
          .min(
            0,
            (value) => `${t('validation_number_field_too_small', { minValue: value.min - 1 })}`
          )
          .nullable()
          .required(t('validation_required_field')),
        numberOfEmployees: Yup.number()
          .transform((value) => (isNaN(value) ? undefined : value))
          .min(
            0,
            (value) => `${t('validation_number_field_too_small', { minValue: value.min - 1 })}`
          )
          .nullable()
          .required(t('validation_required_field'))
      }),
    [t]
  );

  const existingValues = farmerInformation?.farmActivitiesSection?.farmFinancialPage;
  const initialValues = { ...defaultValues, ...existingValues };

  const { validationHelpers, getValues, setTouchedOnAll, handleSubmit } = useFarmerFormValidation({
    initialValues,
    validationSchema
  });

  const isBank = process.env.REACT_APP_TRADEMARK.includes('Bank');

  const onHandleSubmit = async (e) => {
    e.preventDefault();
    await handleSubmit(
      async (data) => await onSubmit?.(data, 'farmFinancialPage'),
      () => setTouchedOnAll()
    )();
  };

  if (loading) return <Loader />;

  const values = getValues();

  return (
    <FormStyles small={width < breakpointSizes.phone}>
      <FarmersQuestionnaireHeader noSubHeader>
        <PageHeader>
          <Trans
            t={t}
            components={{ coloredText: <EmphasizedBrandColorText isBank={isBank} /> }}
            i18nKey={'farmers_financial_page_header'}
          />
        </PageHeader>
      </FarmersQuestionnaireHeader>

      <Field
        Component={Input}
        type="number"
        name="annualTurnover"
        value={values.annualTurnover}
        placeholder={t('farmers_annual_turnover')}
        validationHelpers={validationHelpers}
        required
        validateOnChange
      />
      <Field
        Component={Input}
        type="number"
        name="balance"
        value={values.balance}
        placeholder={t('farmers_balance')}
        validationHelpers={validationHelpers}
        required
        validateOnChange
      />
      <Field
        Component={Input}
        type="number"
        name="numberOfEmployees"
        value={values.numberOfEmployees}
        placeholder={t('farmers_number_of_employees')}
        validationHelpers={validationHelpers}
        required
        validateOnChange
      />

      <Toast
        isVisible
        variant="info"
        message={t('farmers_financial_page_disclaimer')}
        messageWithIcon
      />

      <ButtonSectionWrapperStyle isAtBottom={isAtBottom}>
        <ButtonSection
          secondButtonClick={onHandleSubmit}
          secondButtonPrimary
          secondButtonText={t('button_save_and_continue')}
        />
      </ButtonSectionWrapperStyle>
    </FormStyles>
  );
};
