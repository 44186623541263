import React, { useMemo } from 'react';
import { useFarmerFormValidation } from '../../../../../LegalEntityQuestionnaire/validation/useFarmerFormValidation';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import breakpointSizes from '../../../../../LegalEntityQuestionnaire/styled-theme-styles/global-style-variables.style';
import useWindowDimensions from '../../../../../LegalEntityQuestionnaire/Utils/window-size.service';
import { Field, Loader, Toast } from '@single-platform/components';
import { FarmersQuestionnaireHeader, PageSubtitle } from '../../../../farmersQuestionnaire.style';
import {
  FormStyles,
  PageHeader
} from '../../../../../LegalEntityQuestionnaire/screens/renderPage/renderPage.style';
import { ButtonSection } from '../../../../../LegalEntityQuestionnaire/components';
import { useSelector } from 'react-redux';
import { ButtonSectionWrapperStyle } from '../../../formStyles';
import { RadioChipOption } from '../../../../components';
import { LinkPopup } from '../../../../../LegalEntityQuestionnaire/components/linkPopup/linkPopup';
import { FarmPartnerFormList } from '../../../../components/formList/farmPartnerFormList/farmPartnerFormList';
import { history } from '../../../../../../../Config';
import { yesNoChipOptions } from '../../../../constants';

const defaultValues = {
  hasPartnership: null,
  farmPartners: []
};

const linkPopupGroup = {
  text: 'link_popup_farm_partners_description_link',
  popupKey: 'link_popup_farm_partners'
};

export const FarmPartnerPage = ({ onSubmit, isAtBottom }) => {
  const { t } = useTranslation();
  const { width } = useWindowDimensions();
  const { loading, farmerInformation } = useSelector((state) => state.FarmersQuestionnaireReducer);

  const validationSchema = useMemo(
    () =>
      Yup.object({
        hasPartnership: Yup.boolean().required(t('validation_required_field')).nullable(),
        farmPartners: Yup.array().when('hasPartnership', {
          is: true,
          then: Yup.array().min(1, t('farmers_partners_less_than_one_error'))
        })
      }),
    [t]
  );

  const initialValues = {
    ...defaultValues,
    ...farmerInformation?.farmPartnersSection?.farmPartnerPage
  };

  const {
    validationHelpers,
    errors,
    getValues,
    setTouchedOnAll,
    handleSubmit
  } = useFarmerFormValidation({
    initialValues,
    validationSchema
  });

  const onHandleSubmit = async (e) => {
    e.preventDefault();

    await handleSubmit(
      async (data) => {
        const formattedData = data.hasPartnership === false ? { ...data, farmPartners: [] } : data;
        await onSubmit(formattedData, 'farmPartnerPage');
        history.push('/FarmersQuestionnaire?success=farmPartnersSection');
      },
      () => setTouchedOnAll()
    )();
  };

  const values = getValues();

  if (loading) return <Loader />;

  return (
    <FormStyles small={width < breakpointSizes.phone}>
      <FarmersQuestionnaireHeader>
        <PageHeader>{t('farmers_farm_partners_header')}</PageHeader>
        <PageSubtitle>{t('farmers_farm_partners_subheader')}</PageSubtitle>
      </FarmersQuestionnaireHeader>
      <LinkPopup group={linkPopupGroup} farmers />
      {!!errors.farmPartners && (
        <Toast
          isVisible={true}
          animate={true}
          variant="error"
          header={t(errors.farmPartners.message)}
        />
      )}
      <Field
        Component={RadioChipOption}
        options={yesNoChipOptions}
        name="hasPartnership"
        value={values.hasPartnership}
        validationHelpers={validationHelpers}
        validateOnChange
      />
      {values.hasPartnership && (
        <FarmPartnerFormList
          name="farmPartners"
          list={values.farmPartners}
          drawerType="partners"
          setList={(updatedList) => validationHelpers.setValue('farmPartners', updatedList)}
        />
      )}
      <ButtonSectionWrapperStyle isAtBottom={isAtBottom}>
        <ButtonSection
          secondButtonClick={onHandleSubmit}
          secondButtonPrimary
          secondButtonText={t('button_save_and_continue')}
        />
      </ButtonSectionWrapperStyle>
    </FormStyles>
  );
};
