import React, { useEffect, useState } from 'react';
import { LogosContainer, StepItem } from './stepsList.style';
import { ArrowLogo, CompletedLogo } from '../../assets/images';
import { useTranslation } from 'react-i18next';
import { farmersQuestionnairePaths, history } from '../../../../../Config';

const farmerQuestionnaireSteps = {
  farmSection: farmersQuestionnairePaths.farmersQuestionnaireFarmSection,
  farmerSection: farmersQuestionnairePaths.farmersQuestionnaireFarmerSection,
  farmRepresentativeSection: farmersQuestionnairePaths.farmersQuestionnaireRepresentativeSection,
  farmPartnersSection: farmersQuestionnairePaths.farmersQuestionnaireFarmPartnersSection,
  farmers_sign_into_bank: 'farmers_sign_into_bank',
  farmClientsAndSuppliersSection:
    farmersQuestionnairePaths.farmersQuestionnaireClientsAndSupliersSection,
  farmPaymentsAndTransactionsSection:
    farmersQuestionnairePaths.farmersQuestionnairePaymentsAndTransactionsSection,
  farmActivitiesSection: farmersQuestionnairePaths.farmersQuestionnaireFarmActivitiesSection
};

export const farmersStepBackHandler = () => {
  history.goBack();
};

export const StepsList = ({ steps }) => {
  const { t } = useTranslation();

  const handleStepClick = (step) => {
    history.push(farmerQuestionnaireSteps[step]);
  };

  return (
    <div>
      {steps.map(
        (step) =>
          step?.label && (
            <StepItem key={step.label} onClick={() => handleStepClick(step.label)}>
              <span>{t(step.label)}</span>

              <LogosContainer>
                {step.completed && <CompletedLogo />}

                <ArrowLogo />
              </LogosContainer>
            </StepItem>
          )
      )}
    </div>
  );
};
