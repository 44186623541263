import React, { useEffect, useState } from 'react';
import { shouldRenderConditionalGroup } from '../../conditionalGroupsService/conditionalGroups.service';
import { RenderGroup } from '../../screens/renderGroup/renderGroup';
import { RenderRepeatableGroup } from '../../screens/renderRepeatableGroup/renderRepeatableGroup';
import { useDispatch, useSelector } from 'react-redux';
import {
  QuestionnaireState,
  formKeyInEdit
} from '../../../../../Redux/Selectors/questionnaireFlowHandle';
import { CheckboxContentWrapper, CheckboxStyles, CheckboxWrapper } from './checkbox.style';
import { InformationMessage } from '../informationMessage/informationMessage';
import { useTranslation } from 'react-i18next';
import {
  QuestionSubtitle,
  QuestionTitle,
  QuestionTitleContainer
} from '../../screens/renderGroup/renderGroup.style';
import { HintFieldContainer } from '../hintModal/hintModal.styles';
import HintModal from '../hintModal/hintModal';
import { QuestionnaireActions } from '../../../../../Redux';

const CheckBox = ({
  name,
  question,
  label,
  id,
  onChange,
  conditions,
  validationHelpers,
  onInputChange,
  onQuestionChange,
  value,
  errors,
  required,
  initialQuestionValues,
  isInModal,
  onRepeatableGroupChange
}) => {
  const [selectedValue, setSelectedValue] = useState(value ? value : false);
  const selectFormKeyInEdit = useSelector(formKeyInEdit);
  const dispatch = useDispatch();
  const questionnaireState = useSelector(QuestionnaireState);
  const { t } = useTranslation();

  const onChangeHandler = (value) => {
    setSelectedValue(value);
    onChange(value);
    if (question.type === 'RepeatingInformationCheckbox') {
      dispatch(
        QuestionnaireActions.updateRepeatingInformationCheckbox(
          questionnaireState.questionnaireId,
          { questionKey: name, answer: value }
        )
      );
    }
    question.type === 'RepeatingInformationCheckbox' && onInputChange(name, value);
  };

  const renderGroups = (group) => {
    if (group?.summary)
      return (
        <RenderRepeatableGroup
          onInputChange={onInputChange}
          key={group.id}
          onQuestionChange={onQuestionChange}
          initialQuestionValues={initialQuestionValues}
          group={group}
          errors={errors}
          allGroups={[group]}
          validationHelpers={validationHelpers}
          hasNoDeleteButton={true}
          isInModal={true}
          onRepeatableGroupChange={onRepeatableGroupChange}
        />
      );
    switch (group.groupType) {
      case 'RepeatableGroup':
        return (
          <RenderRepeatableGroup
            initialQuestionValues={initialQuestionValues}
            onInputChange={onInputChange}
            onQuestionChange={onQuestionChange}
            key={group.id}
            errors={errors}
            group={group}
            validationHelpers={validationHelpers}
            isInModal={true}
            onRepeatableGroupChange={onRepeatableGroupChange}
          />
        );
      case 'InformationMessage':
        return <InformationMessage key={group.id} group={group} />;
      default:
        return (
          <RenderGroup
            initialQuestionValues={initialQuestionValues}
            onInputChange={onInputChange}
            onQuestionChange={onQuestionChange}
            key={group.id}
            errors={errors}
            group={group}
            validationHelpers={validationHelpers}
            isInModal={isInModal}
            onRepeatableGroupChange={onRepeatableGroupChange}
          />
        );
    }
  };

  useEffect(() => {
    onQuestionChange(question, 'add');

    return () => {
      onQuestionChange(question, 'delete');
    };
  }, [selectedValue, selectFormKeyInEdit, conditions]);

  return (
    <>
      {(question?.header || question?.subheader) && (
        <QuestionTitleContainer>
          {question?.header && (
            <QuestionTitle hasSubtitle={question.subheader}>{t(question.header)}</QuestionTitle>
          )}
          {question?.subheader && <QuestionSubtitle>{t(question.subheader)}</QuestionSubtitle>}
        </QuestionTitleContainer>
      )}
      <HintFieldContainer>
        <CheckboxContentWrapper>
          <CheckboxWrapper
            Component={CheckboxStyles}
            name={name}
            key={question.id}
            label={label}
            id={id}
            repeatingInformationCheckbox={question.type === 'RepeatingInformationCheckbox'}
            onChange={onChangeHandler}
            required={required}
            value={selectedValue}
            checked={selectedValue}
            validationHelpers={validationHelpers}
            disabled={question.disabled}
          />
          <HintModal isCheckbox={true} hint={question?.hint ? question.hint : ''} />
        </CheckboxContentWrapper>
      </HintFieldContainer>
      {conditions.map(
        (group) =>
          shouldRenderConditionalGroup(selectedValue, group.condition) &&
          group.groups.map((group) => renderGroups(group))
      )}
    </>
  );
};

export default CheckBox;
