import React from 'react';

import { useTranslation } from 'react-i18next';
import {
  ButtonStyles,
  Container,
  RepresentativeContent,
  RepresentativeLogo,
  RepresentativeTitle,
  StatusChipSuccess,
  StepperCard,
  StepperContainer
} from './farmersStepper.style';
import {
  FarmersFillQuestionnaire,
  FarmersSignBankAgreement,
  FarmersSignIntoBank,
  FarmersUploadDocuments,
  FarmersWaitDocumentsApproval
} from '../../assets/images';
import { StepsList } from '../stepsList/stepsList';
import { history, questionnairePaths } from '../../../../../Config';
import { storageHandler } from '../../../../SSOLoginContainer/storageHandler';

const FarmerStepperIcons = {
  FarmersFillQuestionnaire: <FarmersFillQuestionnaire />,
  FarmersUploadDocuments: <FarmersUploadDocuments />,
  FarmersWaitDocumentsApproval: <FarmersWaitDocumentsApproval />,
  FarmersSignBankAgreement: <FarmersSignBankAgreement />,
  FarmersSignIntoBank: <FarmersSignIntoBank />
};

export const FarmersStepper = ({ steps, questionnaireSubmitHandler }) => {
  const { t } = useTranslation();

  const handleButtonClick = async (title) => {
    if (title === 'farmers_confirm_questionnaire') questionnaireSubmitHandler();
    if (title === 'farmers_upload_documents_button') {
      storageHandler.setLocalStorage('isDocumentUpload', true);
      history.push(questionnairePaths.documentsUpload);
    }
  };

  return (
    <Container>
      <StepperContainer>
        {steps.map((step) => (
          <StepperCard isActive={step.isActive} key={`${step.title}-${step.buttonTitle}`}>
            <RepresentativeContent>
              <RepresentativeLogo isActive={step.isActive}>
                {FarmerStepperIcons[step.icon]}
              </RepresentativeLogo>
              <RepresentativeTitle isActive={step.isActive}>{t(step.title)}</RepresentativeTitle>
              {step.completed && <StatusChipSuccess>{t('status_success')}</StatusChipSuccess>}
            </RepresentativeContent>
            {step.stepsList && step.isActive && <StepsList steps={step.stepsList} />}
            {step.isCompleteButtonActive && (
              <ButtonStyles onClick={() => handleButtonClick(step.buttonTitle)}>
                {t(step.buttonTitle)}
              </ButtonStyles>
            )}
          </StepperCard>
        ))}
      </StepperContainer>
    </Container>
  );
};
