import React, { useMemo } from 'react';
import { useFarmerFormValidation } from '../../../../../LegalEntityQuestionnaire/validation/useFarmerFormValidation';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import breakpointSizes from '../../../../../LegalEntityQuestionnaire/styled-theme-styles/global-style-variables.style';
import useWindowDimensions from '../../../../../LegalEntityQuestionnaire/Utils/window-size.service';
import { Input, Field, Loader, PhoneField } from '@single-platform/components';
import { FarmersQuestionnaireHeader } from '../../../../farmersQuestionnaire.style';
import {
  FormStyles,
  PageHeader
} from '../../../../../LegalEntityQuestionnaire/screens/renderPage/renderPage.style';
import { ButtonSection } from '../../../../../LegalEntityQuestionnaire/components';
import { useSelector } from 'react-redux';
import countries from '../../../../../../Auth/components/countriesDropdown/farmersCountryList.json';
import { ButtonSectionWrapperStyle } from '../../../formStyles';
import { DropdownInput } from '../../../../components/dropdownInput/dropdownInput';

const defaultValues = {
  name: null,
  surname: null,
  countryOfResidence: null,
  taxNumber: null,
  email: null,
  phoneNumber: null
};

export const RepresentativePage = ({ onSubmit, isAtBottom }) => {
  const { t } = useTranslation();
  const { width } = useWindowDimensions();
  const { loading, farmerInformation } = useSelector((state) => state.FarmersQuestionnaireReducer);

  const validationSchema = useMemo(
    () =>
      Yup.object({
        name: Yup.string().required(t('validation_required_field')).nullable(),
        surname: Yup.string().required(t('validation_required_field')).nullable(),
        countryOfResidence: Yup.string().required(t('validation_required_field')).nullable(),
        taxNumber: Yup.string().required(t('validation_required_field')).nullable(),
        email: Yup.string()
          .email(t('validation_email_is_not_valid'))
          .required(t('validation_required_field'))
          .nullable(),
        phoneNumber: Yup.string().required(t('validation_required_field')).nullable()
      }),
    [t]
  );

  const existingValues = farmerInformation?.farmRepresentativeSection?.representativePage;
  const initialValues = { ...defaultValues, ...existingValues };

  const { validationHelpers, getValues, setTouchedOnAll, handleSubmit } = useFarmerFormValidation({
    initialValues,
    validationSchema
  });

  const onHandleSubmit = async (e) => {
    e.preventDefault();
    await handleSubmit(
      async (data) => {
        await onSubmit?.(data, 'representativePage');
      },
      () => setTouchedOnAll()
    )();
  };

  if (loading) return <Loader />;

  const values = getValues();

  return (
    <FormStyles small={width < breakpointSizes.phone}>
      <FarmersQuestionnaireHeader>
        <PageHeader>{t('farmers_representative_info_header')}</PageHeader>
      </FarmersQuestionnaireHeader>
      <Field
        Component={Input}
        name="name"
        value={values.name}
        placeholder={t('farmers_farmer_name')}
        validationHelpers={validationHelpers}
        required
        validateOnChange
      />
      <Field
        Component={Input}
        name="surname"
        value={values.surname}
        placeholder={t('farmers_farmer_surname')}
        validationHelpers={validationHelpers}
        required
        validateOnChange
      />
      <DropdownInput
        validationHelpers={validationHelpers}
        name="countryOfResidence"
        value={values.countryOfResidence}
        filterCountries={false}
        placeholder={t('farmers_farmer_countryOfResidence')}
        options={countries}
      />
      <Field
        Component={Input}
        type="number"
        name="taxNumber"
        value={values.taxNumber}
        placeholder={t('farmers_farmer_taxNumber')}
        validationHelpers={validationHelpers}
        required
        validateOnChange
      />
      <Field
        Component={Input}
        name="email"
        value={values.email}
        placeholder={t('farmers_farmer_email')}
        validationHelpers={validationHelpers}
        required
        validateOnChange
      />
      <Field
        Component={PhoneField}
        name="phoneNumber"
        value={values.phoneNumber}
        placeholder={t('farmers_farmer_phoneNumber')}
        validationHelpers={validationHelpers}
        required
        validateOnChange
      />
      <ButtonSectionWrapperStyle isAtBottom={isAtBottom}>
        <ButtonSection
          secondButtonClick={onHandleSubmit}
          secondButtonPrimary={true}
          secondButtonText={t('button_save_and_continue')}
        />
      </ButtonSectionWrapperStyle>
    </FormStyles>
  );
};
