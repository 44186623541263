import styled from 'styled-components';
import { ArrowLogo } from '../../Containers/ApplicationKYC/FarmersQuestionnaire/assets/images';

export const BackButtonStyle = styled.div`
  button {
    background-color: #e6e6e6;
    color: black;
    border: none;
    width: 40px;
    padding: 0;

    &:hover {
      background-color: #d9d9d9;
      color: black;
      border: none;
    }
  }
`;

export const Container = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex: 1;
  gap: 1rem;
`;

export const ArrowLogoStyle = styled(ArrowLogo)`
  transform: rotate(180deg);
`;

export const FarmerSessionContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 1;
  gap: 1rem;
`;

export const LogoContainer = styled.div`
  display: flex;
  gap: 1rem;
  justify-content: center;
  align-items: center;
`;
