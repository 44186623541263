import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FarmerQuestionnaireActions } from '../../../Redux/Services/FarmersQuestionnaire/actions';
import { RefreshToken } from '../../../Utilities/refreshToken';
import {
  FarmersQuestionnaireContainer,
  PageHeaderContainer,
  PageSubtitle,
  SuccessModalButtonContainer,
  SuccessModalContainer,
  SuccessModalDescription
} from './farmersQuestionnaire.style';
import { PageHeader } from '../LegalEntityQuestionnaire/screens/renderPage/renderPage.style';
import { FarmersStepper } from './components/farmersStepper/farmersStepper';
import { Trans, useTranslation } from 'react-i18next';
import { storageHandler } from '../../SSOLoginContainer/storageHandler';
import { KYCActions, OnboardingFlowHandlerActions, selectKycApplication } from '../../../Redux';
import { Button } from '@single-platform/button';
import { history } from '../../../Config';
import { Modal } from '@single-platform/components';
import { SuccessLogo } from './assets/images';
import { FarmersStepperSkeleton } from './farmersSkeleton';

const stepsOrder = [
  'farmRepresentativeSection',
  'farmerSection',
  'farmSection',
  'farmPartnersSection',
  'farmClientsAndSuppliersSection',
  'farmActivitiesSection',
  'farmPaymentsAndTransactionsSection'
];

const InitialFarmersSteps = [
  {
    title: 'farmers_fill_questionnaire',
    completed: false,
    icon: 'FarmersFillQuestionnaire',
    buttonTitle: 'farmers_confirm_questionnaire',
    buttonLink: '',
    stepsList: [],
    isCompleteButtonActive: false,
    isActive: true
  },
  {
    title: 'farmers_upload_documents',
    completed: false,
    icon: 'FarmersUploadDocuments',
    buttonTitle: 'farmers_upload_documents_button',
    buttonLink: '',
    isCompleteButtonActive: false,
    isActive: false
  },
  {
    title: 'farmers_wait_documents_approval',
    completed: false,
    icon: 'FarmersWaitDocumentsApproval',
    buttonTitle: 'representative_business_profile_verify_identity_button',
    buttonLink: '',
    isCompleteButtonActive: false,
    isActive: false
  },
  {
    title: 'farmers_sign_bank_agreement',
    completed: false,
    icon: 'FarmersSignBankAgreement',
    buttonTitle: 'representative_business_profile_update_profile_button',
    buttonLink: 'redirect_login',
    isCompleteButtonActive: false,
    isActive: false
  },
  {
    title: 'farmers_sign_into_bank',
    completed: false,
    icon: 'FarmersSignIntoBank',
    buttonTitle: 'representative_business_profile_update_profile_button',
    buttonLink: 'redirect_login',
    isCompleteButtonActive: false,
    isActive: false
  }
];

export const farmSubmitHandler = async (
  questionnaireData,
  data,
  dispatch,
  questionnaireId,
  section
) => {
  questionnaireData[section] = data;

  await dispatch(
    FarmerQuestionnaireActions.updateFarmerInformation(questionnaireId, questionnaireData)
  );
};

export const FarmersQuestionnaire = () => {
  const dispatch = useDispatch();
  const [result, setResult] = useState(null);
  const [successModalData, setSuccessModalData] = useState({});
  const [farmerSteps, setFarmerSteps] = useState(InitialFarmersSteps);
  const [successModalOpen, setSuccessModalOpen] = useState(false);
  const [completedPage, setCompletedPage] = useState('');
  const [stepListReady, setStepListReady] = useState(false);
  const [farmerStepsReady, setFarmerStepsReady] = useState(false);
  const [uniqueSteps, setUniqueSteps] = useState([]);
  const { loading, isCompletedModalVisible } = useSelector(
    (state) => state.FarmersQuestionnaireReducer
  );
  const application = useSelector(selectKycApplication);
  const { isApplicationLoading } = useSelector((state) => state.KYCReducers);
  const { t } = useTranslation();
  const questionnaireId = storageHandler.getSessionStorage('questionnaireId');
  const applicationId = storageHandler.getSessionStorage('applicationId');

  const getData = async () => {
    const result = await dispatch(FarmerQuestionnaireActions.getFarmerInformation(questionnaireId));
    setResult(result.payload);
  };

  useEffect(() => {
    const refreshToken = async () => {
      await dispatch(RefreshToken());
    };
    refreshToken();
  }, []);

  const updateSteps = (data) => {
    if (!data) {
      setFarmerSteps(InitialFarmersSteps);
      return;
    }

    setFarmerSteps((currentSteps) => {
      const uniqueSteps = createUniqueSteps(data);
      const reorderedUniqueSteps = stepsOrder
        .map((label) => uniqueSteps.find((step) => step.label === label))
        .filter((step) => step);
      setUniqueSteps(reorderedUniqueSteps);
      const updatedSteps = updateCurrentSteps(
        currentSteps,
        reorderedUniqueSteps,
        application?.applicationState
      );

      setStepListReady(true);
      return updatedSteps;
    });
    setFarmerStepsReady(true);
  };

  const createUniqueSteps = (data) => {
    return Object.keys(data).map((sectionKey) => {
      const sectionData = data[sectionKey];
      return {
        label: sectionKey,
        completed: isSectionValid(sectionData)
      };
    });
  };

  const isSectionValid = (sectionData) => {
    const properties = Object.keys(sectionData);
    if (properties.length === 0) return false;

    return sectionData?.isValid;
  };

  const updateCurrentSteps = (currentSteps, uniqueSteps, applicationState) => {
    return currentSteps.map((step, index) => {
      if (index === 0) {
        const allCompleted = uniqueSteps.every((step) => step.completed);
        return {
          ...step,
          stepsList: uniqueSteps,
          completed: allCompleted,
          isCompleteButtonActive:
            allCompleted && applicationState?.state !== 'UserUploadingDocuments'
        };
      }

      if (applicationState?.state === 'UserUploadingDocuments' && index === 1) {
        return {
          ...step,
          isActive: true,
          isCompleteButtonActive: true
        };
      }
      return step;
    });
  };

  const handleSuccessCallback = (succeededPage) => {
    setSuccessModalData(successModalDataByPage[succeededPage]);
    if (successModalDataByPage[succeededPage]) setSuccessModalOpen(true);
  };

  const questionnaireSubmitHandler = async () => {
    await dispatch(KYCActions.submitApplication(applicationId));
    await getData();
    handleModalClose();
  };

  const getNextStepLabel = () => {
    const completedIndex = uniqueSteps.findIndex((step) => step.label === completedPage);
    return uniqueSteps[completedIndex + 1]?.label ?? '';
  };

  const isNextStep = () => {
    const currentIndex = uniqueSteps.findIndex((step) => step.label === completedPage);
    return currentIndex < uniqueSteps.length - 1;
  };

  const nextStepLabel = getNextStepLabel();

  const successModalDataByPage = {
    questionnaireSuccessful: {
      title: t('farmers_questionnaire_success_modal_text'),
      button_text: t('farmers_questionnaire_success_modal_button'),
      isVisible: isCompletedModalVisible,
      submit: async () => {
        await questionnaireSubmitHandler();
      }
    },
    completeNextSteps: {
      title: `${t(completedPage)} ${t('farmers_modal_info_provided')}`,
      subtitle: `${t('farmers_modal_next_section')}: ${t(nextStepLabel)}`,
      button_text: `${t('farmers_modal_next_section')}: ${t(nextStepLabel)}`,
      isVisible: true,
      submit: async () => {
        history.push(nextStepLabel);
      }
    },
    completePreviousSteps: {
      title: <Trans t={t} i18nKey="farmers_questionnaire_complete_previous_steps_modal_text" />,
      button_text: t('farmers_questionnaire_complete_previous_steps_modal_button'),
      isVisible: true,
      submit: async () => {
        handleModalClose();
      }
    }
  };

  const handleModalSubmit = (modalData) => {
    if (modalData.submit) modalData.submit();
  };

  const handleModalClose = async (modalData) => {
    setSuccessModalOpen(false);
    if (modalData?.title === 'farmers_questionnaire_success_modal_text') {
      await dispatch(FarmerQuestionnaireActions.completedModalVisible(false));
    }
  };

  useEffect(() => {
    if (!result && questionnaireId) getData();
    if (result) {
      updateSteps(result);
    }
  }, [result, questionnaireId]);

  useEffect(() => {
    const company = storageHandler.getSessionStorage('company');
    if (company) dispatch(OnboardingFlowHandlerActions.handleOnboardingFlow(company));
    dispatch(FarmerQuestionnaireActions.resetQuestinnaireData());
    if (isCompletedModalVisible) dispatch(FarmerQuestionnaireActions.completedModalVisible(true));
  }, []);

  //TODO: refactor: history most likely doesnt trigger this useEffect, need to use state instead
  useEffect(() => {
    if (history.location.search.includes('success')) {
      const completedPage = new URLSearchParams(history.location.search).get('success');
      setCompletedPage(completedPage);
    }
  }, [history.location.search]);

  useEffect(() => {
    if (completedPage && !farmerSteps[0].completed) {
      if (isNextStep()) {
        handleSuccessCallback('completeNextSteps');
      } else {
        handleSuccessCallback('completePreviousSteps');
      }
    } else if (farmerSteps[0].completed && farmerSteps[0].isCompleteButtonActive) {
      handleSuccessCallback('questionnaireSuccessful');
    }
  }, [completedPage, uniqueSteps]);

  if (loading || isApplicationLoading || !stepListReady || !farmerStepsReady) {
    return <FarmersStepperSkeleton />;
  }

  return (
    <FarmersQuestionnaireContainer>
      {!loading && !isApplicationLoading && (
        <>
          <PageHeaderContainer>
            <PageHeader>
              <Trans
                t={t}
                i18nKey="farmers_questionnaire_header"
                values={{
                  username: `${application?.representative?.firstName} ${application?.representative?.lastName}`
                }}
              />
            </PageHeader>
            <PageSubtitle>{t('farmers_questionnaire_subheader')}</PageSubtitle>
          </PageHeaderContainer>
          <FarmersStepper
            steps={farmerSteps}
            questionnaireSubmitHandler={questionnaireSubmitHandler}
          />
          {successModalOpen && successModalData.isVisible && (
            <Modal
              hideSecondaryAction={true}
              maskClosable={false}
              onCancel={() => handleModalClose(successModalData)}
              onClose={() => handleModalClose(successModalData)}
              maxWidth={'25rem'}
              isOpen={true}
              footer={
                <SuccessModalButtonContainer>
                  <Button variant="Primary" onClick={() => handleModalSubmit(successModalData)}>
                    {successModalData.button_text}
                  </Button>
                  <Button
                    variant="Stroked"
                    color="Black"
                    onClick={() => handleModalClose(successModalData)}
                  >
                    {t('farmers_success_model_close_button')}
                  </Button>
                </SuccessModalButtonContainer>
              }
            >
              <SuccessModalContainer>
                <SuccessLogo />
                <SuccessModalDescription>
                  {successModalData.title}
                  <br />
                  {successModalData.subtitle}
                </SuccessModalDescription>
              </SuccessModalContainer>
            </Modal>
          )}
        </>
      )}
    </FarmersQuestionnaireContainer>
  );
};
